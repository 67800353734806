import React, { useState, useEffect, useRef, useCallback } from "react";
import { CCard, CCardBody } from "@coreui/react";
import { LoginForm, ModalDigitizer } from "../../molecules";
import { Toast, Input, Imagen, Spinner } from "../../atoms";
import { useHistory } from "react-router-dom";
import { loginUser, passwordResetEmail } from "../../../commons/services/users";
import imgCheck from "../../../assets/check-image.svg";

const CardLogin = () => {
  let toastControl = useRef(null);
  const history = useHistory();
  const [state, setState] = useState({
    email: "",
    password: "",
    showSpinner: false,
    showPass: "password",
    iconShowPass: "eye",
    showModal: false,
    sendResetSuccessfull: false,
  });
  const [toast, setToast] = useState({
    show: false,
    title: "",
    textContent: "",
    className: "",
  });

  const onChangeText = (event, field) => {
    const { target } = event;
    setState((prevState) => ({ ...prevState, [field]: target.value }));
  };

  const onHandleShowPass = (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      iconShowPass: state.iconShowPass === "eye" ? "eyeSlash" : "eye",
      showPass: state.showPass === "password" ? "text" : "password",
    }));
  };

  const actionLogin = async (event) => {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, showSpinner: true }));
    try {
      if (state.email && state.password) {
        const response = await loginUser(state.email, state.password);
        const { success, message } = response;
        if (success) {
          sessionStorage.setItem("isLogged", success);
          sessionStorage.setItem("messageWelcome", success);
          setState((prevState) => ({ ...prevState, showSpinner: false }));
          history.go(0);
        } else {
          setState((prevState) => ({ ...prevState, showSpinner: false }));
          showToastMessage("Acceso denegado", "bg-danger text-white", message);
        }
      } else {
        setState((prevState) => ({ ...prevState, showSpinner: false }));
        showToastMessage("¡Opps...!", "bg-warning text-dark", "Correo y contraseña son requeridos.");
      }
    } catch (error) {}
  };

  const showToastMessage = (title, className, message) => {
    setToast((prevState) => ({
      ...prevState,
      show: true,
      title,
      className,
      textContent: message,
    }));
  };

  const willUnMount = () => {
    clearTimeout(toastControl.current);
  };

  const toggleModalForgottenPass = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: !state.showModal,
      sendResetSuccessfull: false,
      showSpinner: false,
    }));
  };

  const ModalContent = useCallback(({ email, showSpinner, sendResetSuccessfull, textContent }) => {
    return (
      <div>
        {showSpinner ? (
          <Spinner />
        ) : (
          <div>
            {sendResetSuccessfull ? (
              <div className="text-center">
                <Imagen src={imgCheck} width={200} />
                <p className="h5 font-regular text-center text-dark mt-3">{textContent}</p>
              </div>
            ) : (
              <div>
                <p className="h5 font-regular">
                  Para restablecer tu contraseña brindanos tu correo electrónico, en breves minutos recibirás un enlace al cual deberás
                  ingresar y digitar tu nueva contraseña.
                </p>
                <p className="mt-4">Correo electrónico</p>
                <Input
                  icon="userFemale"
                  type="text"
                  placeholder="Digite su correo"
                  onChange={(e) => onChangeText(e, "email")}
                  value={email}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }, []);

  const onHandleSetEmail = async () => {
    try {
      if (state.email) {
        setState((prevState) => ({
          ...prevState,
          showSpinner: !state.showSpinner,
        }));
        const res = await passwordResetEmail(state.email);
        if (res.success) {
          setToast(
            (prevState) => ({ ...prevState, textContent: res.message }),
            setState((prevState) => ({
              ...prevState,
              sendResetSuccessfull: !state.sendResetSuccessfull,
            }))
          );
        } else {
          showToastMessage("Error", "bg-danger text-white", res.message);
        }
        setState((prevState) => ({
          ...prevState,
          showSpinner: false,
        }));
      } else {
        showToastMessage("Correo vacio", "bg-danger text-white", "Digite su correo electrónico por favor.");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (toast.show) {
      toastControl.current = setTimeout(() => {
        setToast((prevState) => ({ ...prevState, show: false }));
      }, 6000);
    }

    if (sessionStorage.getItem("signOutSuccessfully")) {
      sessionStorage.removeItem("signOutSuccessfully");
    }

    return willUnMount;
  }, [toast.show, toastControl]);

  return (
    <CCard className="px-3 bg-light d-flex flex-row align-items-center" style={{ height: "100%" }}>
      <CCardBody>
        <LoginForm
          state={state}
          toast={toast}
          onChangeText={onChangeText}
          actionLogin={actionLogin}
          onHandleShowPass={onHandleShowPass}
          toggleModalForgottenPass={toggleModalForgottenPass}
        />
      </CCardBody>
      <Toast
        show={sessionStorage.getItem("signOutSuccessfully") ? true : false}
        title="Adios"
        textContent="Han cerrado sesión exitosamente"
        className="bg-success text-white"
      />
      <ModalDigitizer
        show={state.showModal}
        toggleModal={toggleModalForgottenPass}
        sizeModal=""
        classModalHeader="h3"
        title="Restablecer de contraseña"
        textBtnRight="Enviar petición"
        disableButtonConfirm={state.sendResetSuccessfull}
        content={
          <ModalContent
            email={state.email}
            sendResetSuccessfull={state.sendResetSuccessfull}
            textContent={toast.textContent}
            showSpinner={state.showSpinner}
          />
        }
        onConfirm={onHandleSetEmail}
      />
    </CCard>
  );
};

export default CardLogin;
