import React from "react";
import PropTypes from "prop-types";
import { getColor, deepObjectsMerge } from "@coreui/utils";
import { CChartLine } from "@coreui/react-chartjs";
import { useSelector } from "react-redux";

const ChartLineSimple = (props) => {
  const theme = useSelector((state) => state.theme);
  const { borderColor, backgroundColor, pointHoverBackgroundColor, dataPoints, label, pointed, min, ...attributes } = props;

  const pointHoverColor = (() => {
    if (pointHoverBackgroundColor) {
      return pointHoverBackgroundColor;
    } else if (backgroundColor !== "transparent") {
      return backgroundColor;
    }
    return borderColor;
  })();

  const defaultDatasets = (() => {
    return [
      {
        data: dataPoints,
        borderColor: getColor(borderColor),
        backgroundColor: getColor(backgroundColor),
        pointBackgroundColor: getColor(pointHoverColor),
        pointHoverBackgroundColor: getColor(pointHoverColor),
        label,
      },
    ];
  })();

  const pointedOptions = (() => {
    return {
      scales: {
        xAxes: [
          {
            offset: true,
            gridLines: {
              color: "transparent",
              zeroLineColor: "transparent",
            },
            ticks: {
              fontSize: 14,
              fontColor: "transparent",
            },
          },
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              display: false,
              fontSize: 14,
              min: min,
              max: Math.max(...dataPoints) + Math.ceil(Math.max(...dataPoints) * 0.2),
            },
            gridLines: {
              color: "transparent",
            },
          },
        ],
      },
      elements: {
        line: {
          borderWidth: 1,
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
        },
      },
    };
  })();

  const straightOptions = (() => {
    return {
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false,
              color: theme === "light" ? "lightgray" : "white",
            },
            ticks: {
              fontSize: 14,
              fontColor: theme === "light" ? "black" : "white",
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: theme === "light" ? "rgb(235,237,239)" : "rgb(71,74,85)",
            },
            ticks: {
              fontSize: 14,
              fontColor: theme === "light" ? "black" : "white",
              min: min,
              max: Math.max(...dataPoints) + Math.ceil(Math.max(...dataPoints) * 0.2),
            },
          },
        ],
      },
      elements: {
        line: {
          borderWidth: 2,
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
        },
      },
    };
  })();

  const defaultOptions = (() => {
    const options = pointed ? pointedOptions : straightOptions;
    return Object.assign({}, options, {
      maintainAspectRatio: false,
      legend: {
        display: !pointed,
        position: "bottom",
        labels: {
          fontColor: theme === "light" ? "black" : "white",
          fontSize: 16,
        },
      },
      animation: {
        duration: 1,
        onProgress: (animation) => {
          const chartInstance = animation.chart;
          const datasets = animation.chart.data.datasets;
          const ctx = animation.chart.ctx;

          ctx.textAlign = "center";
          ctx.fillStyle = theme === "light" && !pointed ? "rgba(0, 0, 0, 1)" : "rgba(255, 255, 255, 1)";
          ctx.textBaseline = "bottom";

          datasets.forEach((dataset, i) => {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach((bar, index) => {
              var data = dataset.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y - 3);
            });
          });
        },
      },
    });
  })();

  const computedDatasets = (() => {
    return deepObjectsMerge(defaultDatasets, attributes.datasets || {});
  })();

  const computedOptions = (() => {
    return deepObjectsMerge(defaultOptions, attributes.options || {});
  })();

  return <CChartLine {...attributes} datasets={computedDatasets} options={computedOptions} />;
};

ChartLineSimple.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  //
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  pointHoverBackgroundColor: PropTypes.string,
  dataPoints: PropTypes.array,
  label: PropTypes.string,
  pointed: PropTypes.bool,
};

ChartLineSimple.defaultProps = {
  borderColor: "rgba(169,29,29,.55)",
  backgroundColor: "transparent",
  dataPoints: [10, 22, 45, 63],
  label: "Sales",
  labels: ["Opcion 1", "Opcion 2", "Opcion 3", "Opcion 4"],
  min: 0,
};

export default ChartLineSimple;
