import React from "react";
import {
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import PropTypes from "prop-types";

const Dropdown = ({
  placeholder,
  onClick,
  data,
  color,
  placement,
  className,
  disabled,
}) => {
  return (
    <div>
      <CDropdown className={className}>
        <CDropdownToggle
          caret={false}
          color={color}
          disabled={disabled}
          className="w-100"
        >
          {placeholder}
        </CDropdownToggle>
        <CDropdownMenu placement={placement} className="dropdown-scroll">
          {data.map((item, index) => (
            <CDropdownItem
              key={index}
              onClick={onClick}
              value={item.value}
              id={item.id}
              color="light"
            >
              {item.label}
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
    </div>
  );
};

Dropdown.defaultProps = {
  placeholder: "Opciones",
  onClick: () => {},
  data: [
    { value: 1, label: "Editar" },
    { value: 2, label: "Eliminar" },
  ],
  color: "secondary",
  placement: "bottom",
  className: "text-dark",
  disabled: false,
};

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  data: PropTypes.array,
  color: PropTypes.string,
  placement: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Dropdown;
