import React, { useEffect, useState } from "react";
import { ModalDigitizer } from "..";
import { Spinner, Toast } from "../../atoms";
import { useDispatch } from "react-redux";
import { setRefreshToken } from "../../../actionsCreators/Global";
import { resetToken } from "../../../config/config";

const ModalResetToken = ({ show, spinner, setModal, toggleModal }) => {
  const dispatch = useDispatch();
  const [toast, setToast] = useState({
    show: false,
    title: "",
    message: "",
    className: "",
  });

  const onHandleResetToken = async () => {
    setModal((prevState) => ({ ...prevState, spinner: true }));
    try {
      const newToken = await resetToken();
      if (newToken) {
        dispatch(setRefreshToken(newToken));
        setModal(
          (prevState) => ({ ...prevState, token: false, spinner: false }),
          setToast({ show: true, title: "Token renovado", message: "Token de acceso renovado exitosamente.", className: "text-white bg-success" }),
          hiddenToast(),
        );
      } else {
        setToast({ show: true, title: "Error", message: "Ha ocurrido un problema al renuadar el token.", className: "text-white bg-danger" });
      }
    } catch (error) {
      setToast({ show: true, title: "Error", message: `CODE: ${error.code} -> ${error.message}`, className: "text-white bg-danger" });
    }
  };

  const hiddenToast = () => {
    setTimeout(() => {
      setToast((prevState) => ({ ...prevState, show: false, title: "", message: "", className: "" }));
    }, 10500);
  };

  useEffect(() => {}, []);

  return (
    <>
      <ModalDigitizer
        show={show}
        title="Resetear token"
        toggleModal={toggleModal}
        content={
          <div>
            {spinner ? (
              <Spinner width={120} />
            ) : (
              <p className="font-regular h5">El token de acceso ha expirado, ¿Desea renovar el token 1 hora más?</p>
            )}
          </div>
        }
        classModalHeader="h3"
        textBtnLeft="Cerrar"
        textBtnRight="Renovar token"
        onConfirm={onHandleResetToken}
      />
      <Toast show={toast.show} title={toast.title} textContent={toast.message} className={toast.className} autohide={9500} />
    </>
  );
};

export default ModalResetToken;
