import React from "react";
import { CLabel } from "@coreui/react";

const Label = ({ text, isRequeried, className }) => {
  return (
    <CLabel htmlFor="label" className={className}>
      {text}
      {isRequeried && <small className="h4 text-danger">*</small>}
    </CLabel>
  );
};

export default Label;
