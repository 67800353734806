import React from "react";
import { CSidebarBrand } from "@coreui/react";
import { Imagen } from "../../atoms";
import { imagenBrand } from "../../../commons/consts/images";

const SideBarBrand = () => {
  return (
    <>
      <CSidebarBrand className="d-md-down-none bg-purple py-4" to="/">
        <Imagen
          src={imagenBrand}
          className="c-sidebar-brand-full"
          width={140}
        />
        <Imagen
          src={imagenBrand}
          className="c-sidebar-brand-minimized"
          width={50}
        />
      </CSidebarBrand>
    </>
  );
};

export default SideBarBrand;
