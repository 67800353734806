import React from "react";
import { CContainer, CRow, CCol } from "@coreui/react";
import { DateTimePicker, ListGroup, CheckBoxesMultiple } from "..";
import { Label, Input, Dropdown, TextArea, Imagen, CheckBoxes, RadioButton, Icon, Spinner } from "../../atoms";
import { useSelector } from "react-redux";
import { validInputReExp } from "../../../commons/helpers/validations";
import { nameOrLastnameReg, ageReg } from "../../../commons/consts/regularexp";

const EmotionalTab = ({ paraments, controllers, onChangeInputs, deleteItem }) => {
  const stateGlobal = useSelector((state) => ({
    violenceTypes: state.violenceTypes,
    attentionMode: state.attentionMode,
    reasonQuery: state.reasonQuery,
    therapeuticTechniques: state.therapeuticTechniques,
    violenceAreas: state.violenceAreas,
    affectationLevel: state.affectationLevel,
    displayName: state.currentUser.displayName,
    photoUser: state.currentUser.photoURL,
    dateCurrent: state.dateCurrent,
    virtualOptions: state.virtualOptions,
  }));
  const {
    date,
    hour,
    names,
    lastnames,
    observations,
    age,
    exposition,
    generalAsssessment,
    nextQuoteDate,
    nextQuoteHourStart,
    nextQuoteHourEnd,
    violencesTypes,
    violencesApproaches,
    therapeuticTechniques,
    otherTherapeuticTechniques,
    reasonQuery,
    affectationLevel,
    violenceAreas,
    developmentSession,
    agreements,
    othersViolenceArea,
    virtualOptionsEmotional,
    attentionModeEmotional,
    photoUser,
    attentionBy,
  } = paraments;
  const { spinner } = controllers;
  return (
    <CContainer fluid>
      {spinner ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <>
          <CRow className="justify-content-end">
            <p className="h5">
              La información obligatoria se visualiza con un <Label className="font-bold text-danger ml-1" isRequeried />
              <Icon name="handPointLeft" className="ml-1" size="1x" />
            </p>
          </CRow>
          <CRow>
            <CCol xs="12" lg="6">
              <Label text="1. Fecha y hora" isRequeried className="mt-3 h5" />
              <DateTimePicker
                disableTime2
                valueDate={date}
                valueTime={hour}
                onChangeDate={(e) => onChangeInputs(e, "date", "input")}
                onChangeTime={(e) => onChangeInputs(e, "hour", "input")}
                maxDate={stateGlobal.dateCurrent}
                disabled
              />
              <Label text="2. Nombres" isRequeried className="mt-3 h5" />
              <Input
                disabled
                noAutoComplete="true"
                type="text"
                value={names}
                classNameInput={validInputReExp(names, nameOrLastnameReg)}
                onChange={(e) => onChangeInputs(e, "names", "input")}
              />
              <Label text="3. Apellidos" isRequeried className="mt-3 h5" />
              <Input
                disabled
                type="text"
                noAutoComplete="true"
                value={lastnames}
                classNameInput={validInputReExp(lastnames, nameOrLastnameReg)}
                onChange={(e) => onChangeInputs(e, "lastnames", "input")}
              />
              <Label text="4. Edad" isRequeried className="mt-3 h5" />
              <Input
                type="number"
                disabled
                classNameInput={`${validInputReExp(age, ageReg)} w-50`}
                value={age}
                max={120}
                min={0}
                onChange={(e) => onChangeInputs(e, "age", "number")}
              />
              <Label isRequeried text="5. Motivo de la consulta" className="mt-3 h5" />
              <TextArea value={reasonQuery} maxLength={3000} onChange={(e) => onChangeInputs(e, "reasonQuery", "input")} />
              <Label isRequeried text="6. Exposición del caso" className="mt-3 h5" />
              <TextArea value={exposition} maxLength={20000} onChange={(e) => onChangeInputs(e, "exposition", "input")} />
              <Label isRequeried text="7. Desarrollo de la sesión" className="mt-3 h5" />
              <TextArea value={developmentSession} maxLength={3000} onChange={(e) => onChangeInputs(e, "developmentSession", "input")} />
              <Label isRequeried text="8. Acuerdos finales sobre el proceso de atención" className="mt-3 h5" />
              <TextArea value={agreements} maxLength={3000} onChange={(e) => onChangeInputs(e, "agreements", "input")} />
              {/* <Button text="Creación de consentimiento informado" color="info" className="w-100" /> */}
              <Label text="9. Próxima cita" className="mt-3 h5" isRequeried />
              <DateTimePicker
                valueDate={nextQuoteDate}
                valueTime={nextQuoteHourStart}
                valueTime2={nextQuoteHourEnd}
                onChangeDate={(e) => onChangeInputs(e, "nextQuoteDate", "input")}
                onChangeTime={(e) => onChangeInputs(e, "nextQuoteHourStart", "input")}
                onChangeTime2={(e) => onChangeInputs(e, "nextQuoteHourEnd", "input")}
                minDate={stateGlobal.dateCurrent}
              />
              <CheckBoxesMultiple
                title="10. Tipos de violencias existentes en el caso"
                subTitle="Violencias localizadas:"
                data={stateGlobal.violenceTypes}
                controller={violencesTypes}
                onChange={onChangeInputs}
                field="violencesTypes"
                classNameTitle="h5"
              />
            </CCol>
            <CCol>
              <CheckBoxesMultiple
                title="11. Tipos de violencias abordadas"
                subTitle="Violencias abordadas:"
                data={stateGlobal.violenceTypes}
                controller={violencesApproaches}
                onChange={onChangeInputs}
                field="violencesApproaches"
                classNameTitle="h5"
              />
              <Label text="12. Áreas de afectación por violencias" className="mt-3 h5" isRequeried />
              <CheckBoxes
                name="areaViolences"
                list={stateGlobal.violenceAreas}
                checked={violenceAreas}
                onChange={(e) => onChangeInputs(e, "violenceAreas", "check-box")}
              />
              <Input
                noAutoComplete="true"
                placeholder="Otras"
                value={othersViolenceArea}
                type="text"
                classNameInput="w-75"
                onChange={(e) => onChangeInputs(e, "othersViolenceArea", "input")}
              />
              <Label text="13. Nivel de afectación por violencias" className="mt-3 h5" isRequeried />
              <RadioButton
                name="level_afectation"
                classNameGroup="ml-4"
                options={stateGlobal.affectationLevel}
                onChange={(e) => onChangeInputs(e, "affectationLevel", "radio-button")}
                optionSelected={affectationLevel}
              />
              <Label text="14.	Impresión general del caso" className="mt-3 h5" isRequeried />
              <TextArea maxLength={3000} value={generalAsssessment} onChange={(e) => onChangeInputs(e, "generalAsssessment", "input")} />
              <ListGroup
                isRequeried
                title="15. Técnicas terapéuticas utilizadas"
                list={stateGlobal.therapeuticTechniques}
                placeholder={therapeuticTechniques.selected}
                itemsSelected={therapeuticTechniques.items}
                onClick={(e) => onChangeInputs(e, "therapeuticTechniques", "list-group")}
                itemDelete={(e) => deleteItem(e, "therapeuticTechniques")}
              />
              <TextArea
                height={80}
                placeholder="Otras técnicas terapéuticas...."
                value={otherTherapeuticTechniques}
                maxLength={1500}
                onChange={(e) => onChangeInputs(e, "otherTherapeuticTechniques", "input")}
              />
              <Label text="16. Modalidad" className="mt-3 h5" isRequeried />
              <Dropdown
                className="w-75 mb-3"
                data={stateGlobal.attentionMode}
                color={attentionModeEmotional.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "attentionModeEmotional", "dropdown")}
                placeholder={attentionModeEmotional.label}
              />
              {attentionModeEmotional.value === "R925ema5ejFwt1y4HICn" && (
                <div className="bg-light rounded p-3 my-3 text-dark">
                  <Label isRequeried text="16.1 - Elegir el o los tipos de modalidades virtuales" />
                  <CheckBoxes
                    name="virtualOptionsEmotional"
                    list={stateGlobal.virtualOptions}
                    checked={virtualOptionsEmotional}
                    onChange={(e) => onChangeInputs(e, "virtualOptionsEmotional", "check-box")}
                  />
                </div>
              )}

              <Label isRequeried text="18. Observaciones" className="mt-3 h5" />
              <TextArea value={observations} maxLength={3000} onChange={(e) => onChangeInputs(e, "observations", "input")} />
              <Label text="Atendida por:" className="h5 mt-3" />
              <div className="d-flex flex-row align-items-center">
                <Imagen src={photoUser || stateGlobal.photoUser} width={80} height={80} className="rounded-circle" />
                <p className="h5 font-italic ml-2">{attentionBy || stateGlobal.displayName.username}</p>
              </div>
            </CCol>
          </CRow>
        </>
      )}
    </CContainer>
  );
};

export default EmotionalTab;
