import React, { useCallback, useState, useRef, useEffect } from "react";
import { ComponentMap, DateTimePicker, ExportData, ModalResetToken } from "../../molecules";
import { ChartsLayout, ChartsEmotionalLayout, TableChartsLayout, ChartsLegalLayout } from "..";
import { Button, Toast } from "../../atoms";
import DivTransition from "../../../commons/animations/DivTransition";
import { AnimatePresence, motion } from "framer-motion";
import { CCard, CRow, CCol, CCardBody, CCardHeader, CTabs, CNav, CNavItem, CNavLink, CTabPane, CContainer } from "@coreui/react";
import { elementsTabReports } from "../../../commons/static/tabs";
import { addFontWeightReports } from "../../../commons/helpers/addStyles";
import { variantsRP1 } from "../../../commons/animations/variants";
import { verifyTokenExpired } from "../../../commons/helpers/validations";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// SERVICES
import { getChartsData } from "../../../commons/services/charts";
import { setDataReports } from "../../../actionsCreators/Global";

const DigitizerReports = () => {
  let timeOut = useRef(null);
  const stateGlobal = useSelector((state) => ({
    theme: state.theme,
    dateCurrent: state.dateCurrent,
    idToken: state.currentUser.idToken,
    dataReports: state.dataReports,
  }));
  const dispatch = useDispatch();
  const { theme, dateCurrent, idToken, dataReports } = stateGlobal;

  const [filters, setFilters] = useState({
    tabCurrent: elementsTabReports[0].name,
    startDate: "",
    endDate: "",
  });
  const [state, setState] = useState({
    success: false,
    spinner: false,
    data: {
      atenttionEmotional: {
        emotionalModules: { labels: [], dataPoints: [-1, -1, -1, -1], total: 0 },
        violenceAreas: { labels: [], dataPoints: [], total: 0 },
      },
      atenttionLegal: {
        crimes: { labels: [], dataPoints: [], total: 0 },
        familyRights: { labels: [], dataPoints: [], total: 0 },
        legalModules: { labels: [], dataPoints: [-1, -1, -1, -1], total: 0 },
        support: { labels: [], dataPoints: [], total: 0 },
        violenceTypes: { labels: [], dataPoints: [], total: 0 },
      },
      demographicData: {},
      dataMaps: {
        femicidesForStates: { charts: [], total: 0, maximo: 0 },
      },
      femicides: {
        causePossible: {},
        weaponTypes: {},
        rangeAgesFemicides: { labels: [], dataPoints: [] },
      },
      rangeAges: {
        labels: [],
        dataPoints: [],
      },
    },
  });
  const [toast, setToast] = useState({
    show: false,
    message: "",
    className: "",
    title: "",
  });

  const [modal, setModal] = useState({
    show: false,
    title: "",
    token: false,
    spinner: false,
    textBtnConfirm: "Guardar",
  });

  const onActiveTabChange = async (element) => {
    addFontWeightReports(element, elementsTabReports);
    setFilters((prevState) => ({ ...prevState, tabCurrent: element }));
  };

  const onChangedInput = useCallback(
    (event, arg, field) => {
      const { target } = event;
      switch (arg) {
        case "input-date":
          setFilters((prevState) => ({ ...prevState, [field]: target.value }));
          break;
        default:
          break;
      }
    },
    [filters]
  );

  const clearInputs = useCallback(() => {
    dispatch(setDataReports(null));
    setFilters(
      (prevState) => ({ ...prevState, startDate: "", endDate: "" }),
      setState((prevState) => ({
        ...prevState,
        success: false,
        data: {
          atenttionEmotional: {
            emotionalModules: { labels: [], dataPoints: [-1, -1, -1, -1], total: 0 },
            violenceAreas: { labels: [], dataPoints: [], total: 0 },
          },
          atenttionLegal: {
            crimes: { labels: [], dataPoints: [], total: 0 },
            familyRights: { labels: [], dataPoints: [], total: 0 },
            legalModules: { labels: [], dataPoints: [-1, -1, -1, -1], total: 0 },
            support: { labels: [], dataPoints: [], total: 0 },
            violenceTypes: { labels: [], dataPoints: [], total: 0 },
          },
          demographicData: {},
          femicides: { causePossible: {}, weaponTypes: {}, rangeAgesFemicides: { labels: [], dataPoints: [] } },
          dataMaps: { femicidesForStates: { charts: [], total: 0, maximo: 0 } },
          rangeAges: { labels: [], dataPoints: [] },
        },
      }))
    );
  }, []);

  const onClickRequest = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));
    try {
      const { startDate, endDate } = filters;
      const { success, message, data, code, title } = await getChartsData({ idToken, startDate, endDate });
      if (success) {
        dispatch(setDataReports({ ...data, startDate, endDate }));
        setState(
          (prevState) => ({ ...prevState, data, success }),
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title,
            className: "text-white bg-success",
            message,
          }))
        );
      } else {
        if (verifyTokenExpired(code)) {
          setModal(
            (prevState) => ({ ...prevState, show: false, token: true }),
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              className: "text-white bg-danger",
              message,
            }))
          );
        } else {
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title,
            className: "text-white bg-danger",
            message,
          }));
        }
      }
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title: "Error en la respuesta de las graficas.",
        message: error.message,
        className: "text-white bg-danger",
      }));
    } finally {
      setState(
        (prevState) => ({
          ...prevState,
          spinner: false,
        }),
        hiddenToast()
      );
    }
  }, [filters, idToken, dataReports]);

  const hiddenToast = () => {
    timeOut = setTimeout(() => {
      setToast((prevState) => ({ ...prevState, show: false }));
    }, 10500);
  };

  const toggleModalToken = () => {
    setModal((prevState) => ({ ...prevState, token: !modal.token }));
  };

  const willUnMount = () => {
    clearTimeout(timeOut);
  };

  useEffect(() => {
    if (dataReports) {
      setState((prevState) => ({ ...prevState, success: true, data: dataReports }));
      setFilters((prevState) => ({ ...prevState, startDate: dataReports.startDate, endDate: dataReports.endDate }));
    }
    return willUnMount;
  }, []);

  return (
    <CContainer fluid>
      <StyledCard>
        <CCardHeader className="h3 border-0 bg-gradient-blue text-white">Filtrar por fechas</CCardHeader>
        <CCardBody>
          <p>Filtre las fechas en las cuales desea obtener información de los datos almacenados.</p>
          <CRow className="px-3">
            <CCol xs="12" sm="6" md="4" className="mb-3">
              <DateTimePicker
                label="Desde:"
                disableTime={true}
                disableTime2={true}
                onChangeDate={(e) => onChangedInput(e, "input-date", "startDate")}
                maxDate={dateCurrent}
                valueDate={filters.startDate}
              />
            </CCol>
            <CCol xs="12" sm="6" md="4" className="mb-3">
              <DateTimePicker
                label="Hasta:"
                disableTime={true}
                disableTime2={true}
                onChangeDate={(e) => onChangedInput(e, "input-date", "endDate")}
                maxDate={dateCurrent}
                valueDate={filters.endDate}
              />
            </CCol>
          </CRow>
          <CRow className="text-right">
            <CCol>
              <Button text="Buscar" color="info" icon="search" className="h5" onClick={onClickRequest} />
              <Button text="Limpiar" color="secondary" onClick={clearInputs} icon="brushAlt" className="ml-2 h5" />
              {state.success && (
                <ExportData className="ml-2 h5" data={state.data} startDate={filters.startDate} endDate={filters.endDate} />
              )}
            </CCol>
          </CRow>
        </CCardBody>
      </StyledCard>
      {/* --------------------- Tabs ---------------------------- */}
      <StyledCard>
        <CTabs activeTab={elementsTabReports[0].name} onActiveTabChange={onActiveTabChange}>
          <StyledCardHeader className="border-0">
            <CNav variant="tabs" fill>
              {elementsTabReports.map((item, index) => (
                <CNavItem key={index}>
                  <CNavLink
                    data-tab={item.name}
                    disabled={item.name === filters.tabCurrent ? true : false}
                    id={item.id}
                    className={`mx-1 my-2 rounded ${
                      theme === "light"
                        ? "text-dark bg-gray"
                        : filters.tabCurrent === item.name
                        ? "text-dark bg-white"
                        : "text-white bg-dark"
                    }`}
                    style={{ fontWeight: index === 0 ? "bold" : "normal" }}
                  >
                    {item.name}
                  </CNavLink>
                </CNavItem>
              ))}
            </CNav>
          </StyledCardHeader>
          <CCardBody>
            <motion.div>
              <CTabPane data-tab={elementsTabReports[0].name}>
                <AnimatePresence>
                  <DivTransition keyframer={filters.tabCurrent} variants={variantsRP1} initial="hidden" animate="visible" exit="exit">
                    <ChartsLayout data={state.data.demographicData} tabName={filters.tabCurrent} spinner={state.spinner} />
                  </DivTransition>
                </AnimatePresence>
              </CTabPane>
              <CTabPane data-tab={elementsTabReports[1].name}>
                <AnimatePresence>
                  <DivTransition keyframer={filters.tabCurrent} variants={variantsRP1} initial="hidden" animate="visible" exit="exit">
                    <ChartsLegalLayout data={state.data.atenttionLegal} tabName={filters.tabCurrent} spinner={state.spinner} />
                  </DivTransition>
                </AnimatePresence>
              </CTabPane>
              <CTabPane data-tab={elementsTabReports[2].name}>
                <AnimatePresence>
                  <DivTransition keyframer={filters.tabCurrent} variants={variantsRP1} initial="hidden" animate="visible" exit="exit">
                    <ChartsEmotionalLayout data={state.data.atenttionEmotional} tabName={filters.tabCurrent} spinner={state.spinner} />
                  </DivTransition>
                </AnimatePresence>
              </CTabPane>
              <CTabPane data-tab={elementsTabReports[3].name}>
                <AnimatePresence>
                  <DivTransition keyframer={filters.tabCurrent} variants={variantsRP1} initial="hidden" animate="visible" exit="exit">
                    <ComponentMap
                      data={state.data.dataMaps.femicidesForStates}
                      dataFemicides={state.data.femicides}
                      tabName={filters.tabCurrent}
                      spinner={state.spinner}
                    />
                  </DivTransition>
                </AnimatePresence>
              </CTabPane>
              <CTabPane data-tab={elementsTabReports[4].name}>
                <AnimatePresence>
                  <DivTransition keyframer={filters.tabCurrent} variants={variantsRP1} initial="hidden" animate="visible" exit="exit">
                    <TableChartsLayout data={state.data} tabName={filters.tabCurrent} spinner={state.spinner} />
                  </DivTransition>
                </AnimatePresence>
              </CTabPane>
            </motion.div>
          </CCardBody>
        </CTabs>
      </StyledCard>
      <ModalResetToken show={modal.token} spinner={modal.spinner} setModal={setModal} toggleModal={toggleModalToken} />
      <Toast show={toast.show} title={toast.title} textContent={toast.message} className={toast.className} autohide={9500} />
    </CContainer>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

const StyledCardHeader = styled(CCardHeader)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default DigitizerReports;
