import React from "react";
import { CImg } from "@coreui/react";
import loadingImage from "../../../assets/loading.svg";

const Spinner = ({ width = 200, height = 200 }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <CImg src={loadingImage} width={width} height={height} />
    </div>
  );
};

export default Spinner;
