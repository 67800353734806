import React from "react";
import { CLabel, CRow, CCol } from "@coreui/react";
import { Input } from "../../atoms";

const DateTimePicker = ({
  label,
  disableTime,
  disableTime2,
  valueDate,
  valueTime,
  valueTime2,
  onChangeDate,
  onChangeTime,
  onChangeTime2,
  disabled,
  maxDate,
  minDate,
  className = "align-items-center",
  classNameDate,
}) => {
  return (
    <CRow className={className}>
      <CLabel>{label}</CLabel>
      <CCol>
        <Input
          type="date"
          value={valueDate}
          onChange={onChangeDate}
          disabled={disabled}
          max={maxDate}
          min={minDate}
          className={classNameDate}
        />
        <div className="d-flex flex-row">
          {!disableTime && (
            <Input type="time" classNameInput="mt-2 w-50 mr-2" value={valueTime} onChange={onChangeTime} disabled={disableTime} />
          )}
          {!disableTime2 && (
            <Input type="time" classNameInput="mt-2 w-50 ml-2" value={valueTime2} onChange={onChangeTime2} disabled={disableTime2} />
          )}
        </div>
        {onChangeTime2 && <small className="text-success font-semi-bold">Horario de atención: 7am - 5pm</small>}
      </CCol>
    </CRow>
  );
};

export default DateTimePicker;
