import React from "react";
import {
  CCreateElement,
  CSidebarNav,
  CSidebarNavTitle,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";

const SideBarNav = ({ navigation }) => {
  return (
    <CSidebarNav>
      <CCreateElement
        items={navigation}
        components={{
          CSidebarNavDropdown,
          CSidebarNavItem,
          CSidebarNavTitle,
        }}
      />
    </CSidebarNav>
  );
};

export default SideBarNav;
