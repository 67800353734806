import React from "react";
import { useSelector } from "react-redux";

const TableCharts = ({ data, title }) => {
  const theme = useSelector((state) => state.theme);
  return (
    <div className="w-100 py-4 px-1">
      <h4
        className={`mb-3 text-center py-3 font-regular rounded-lg-top ${
          theme === "dark" ? "bg-dark text-white" : "bg-secondary text-dark"
        }`}
      >
        {title}
      </h4>
      <div style={{ marginTop: -14 }}>
        <table className={`table table-striped table-hover ${theme === "dark" ? "text-white" : "text-dark"}`}>
          <thead>
            <tr>
              <th scope="col" className="rounded-lg-bottom-left">
                #
              </th>
              <th scope="col">Descripción</th>
              <th scope="col" className="text-right rounded-lg-bottom-right">
                Frecuencia
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Bucle */}
            {data.labels.map((item, index) => (
              <tr key={item}>
                <td className="rounded-lg-left">{index + 1}</td>
                <td>{item}</td>
                <td className="text-right rounded-lg-right">{data.dataPoints[index]}</td>
              </tr>
            ))}
            <tr className={`${theme === "dark" ? "bg-dark text-white" : "bg-secondary text-dark"}`}>
              <td className="rounded-lg-left">#</td>
              <td className="font-weight-bold">Total</td>
              <td className="text-right font-weight-bold rounded-lg-right">{data.total}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableCharts;
