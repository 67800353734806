import React from "react";
import PropTypes from "prop-types";

const CardInfo = ({ obj }) => {
  return (
    <>
      {Object.keys(obj).map((key, index) => (
        <div key={index} className="mb-4">
          <p className="m-1 h5">
            <small className="h5 font-bold">{key}</small>: {obj[key]}
          </p>
        </div>
      ))}
    </>
  );
};

CardInfo.propTypes = {
  obj: PropTypes.object,
};

CardInfo.defaultProps = {
  obj: { test: "This is a test." },
};

export default CardInfo;
