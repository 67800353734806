import React, { useState, useEffect, useCallback, useRef } from "react";
import { CRow, CCard, CContainer } from "@coreui/react";
import { Spinner, Toast } from "../../atoms";
import { WidgetsCount, ModalResetToken } from "../../molecules";
import { useDispatch, useSelector } from "react-redux";
import { getFemicidesDataDash, getRecordsDataDash, getRecordsAreaDataDash } from "../../../commons/services/charts";
import { verifyTokenExpired } from "../../../commons/helpers/validations";
import { setFemicidesDashboard, setAreasAttentionDashboard, setStatusCaseDashboard } from "../../../actionsCreators/Global";
import styled from "styled-components";

const StyledCCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

const DigitizerDashboard = () => {
  let timeOut = useRef(null);
  const dispatch = useDispatch();
  const stateGlobal = useSelector((state) => ({
    dateCurrent: state.dateCurrent,
    currentUser: state.currentUser,
    dataFemicidesDashboard: state.dataFemicidesDashboard,
    dataAreasAttentionDashboard: state.dataAreasAttentionDashboard,
    dataStatusCasesDashboard: state.dataStatusCasesDashboard,
  }));
  const { dateCurrent, currentUser, dataFemicidesDashboard, dataAreasAttentionDashboard, dataStatusCasesDashboard } = stateGlobal;

  const [state, setState] = useState({
    spinnerFemicides: true,
    spinnerAreas: true,
    spinnerCases: true,
    dataFemicides: { labels: [], dataPoints: [], total: 0 },
    dataAreasAttention: { labels: [], dataPoints: [], total: 0 },
    dataStatusCases: { labels: [], dataPoints: [], total: 0 },
  });

  const [toast, setToast] = useState({
    show: false,
    title: "",
    className: "",
    textContent: "",
  });

  const [modal, setModal] = useState({
    show: false,
    token: false,
    spinner: false,
  });

  const getDataFemicides = useCallback(
    async (yearQuerySelected) => {
      try {
        const yearQuery = !yearQuerySelected ? dateCurrent.substring(0, 4) : yearQuerySelected;
        if (!dataFemicidesDashboard || yearQuerySelected) {
          const { success, title, data, message, code } = await getFemicidesDataDash({
            yearQuery,
            idToken: currentUser.idToken,
          });
          if (success) {
            dispatch(setFemicidesDashboard({ ...data, yearSelected: yearQuery }));
            setState((prevState) => ({ ...prevState, dataFemicides: { ...data, yearSelected: yearQuery } }));
          } else {
            if (verifyTokenExpired(code)) {
              setModal((prevState) => ({ ...prevState, show: false, token: true }));
            } else {
              setToast((prevState) => ({
                ...prevState,
                show: true,
                title: title,
                className: "text-white bg-danger",
                textContent: message,
              }));
            }
          }
        } else {
          setState((prevState) => ({ ...prevState, dataFemicides: dataFemicidesDashboard }));
        }
      } catch (error) {
        setToast((prevState) => ({
          ...prevState,
          show: true,
          title: "ERROR",
          className: "text-white bg-danger",
          textContent: error.message,
        }));
      } finally {
        hiddenToast();
        setState((prevState) => ({ ...prevState, spinnerFemicides: false }));
      }
    },
    [currentUser.idToken, dateCurrent]
  );

  const getDataAreasAttention = useCallback(
    async (yearQuerySelected) => {
      try {
        const yearQuery = !yearQuerySelected ? dateCurrent.substring(0, 4) : yearQuerySelected;
        if (!dataAreasAttentionDashboard || yearQuerySelected) {
          const { success, title, data, message, code } = await getRecordsAreaDataDash({
            yearQuery,
            idToken: currentUser.idToken,
          });
          if (success) {
            dispatch(setAreasAttentionDashboard({ ...data, yearSelected: yearQuery }));
            setState((prevState) => ({ ...prevState, dataAreasAttention: { ...data, yearSelected: yearQuery } }));
          } else {
            if (verifyTokenExpired(code)) {
              setModal((prevState) => ({ ...prevState, show: false, token: true }));
            } else {
              setToast((prevState) => ({
                ...prevState,
                show: true,
                title: title,
                className: "text-white bg-danger",
                textContent: message,
              }));
            }
          }
        } else {
          setState((prevState) => ({ ...prevState, dataAreasAttention: dataAreasAttentionDashboard }));
        }
      } catch (error) {
        setToast((prevState) => ({
          ...prevState,
          show: true,
          title: "ERROR",
          className: "text-white bg-danger",
          textContent: error.message,
        }));
      } finally {
        hiddenToast();
        setState((prevState) => ({ ...prevState, spinnerAreas: false }));
      }
    },
    [currentUser.idToken, dateCurrent]
  );
  const getDataStatusCases = useCallback(
    async (yearQuerySelected) => {
      try {
        const yearQuery = !yearQuerySelected ? dateCurrent.substring(0, 4) : yearQuerySelected;
        if (!dataStatusCasesDashboard || yearQuerySelected) {
          const { success, title, data, message, code } = await getRecordsDataDash({
            yearQuery,
            idToken: currentUser.idToken,
          });
          if (success) {
            dispatch(setStatusCaseDashboard({ ...data, yearSelected: yearQuery }));
            setState((prevState) => ({ ...prevState, dataStatusCases: { ...data, yearSelected: yearQuery } }));
          } else {
            if (verifyTokenExpired(code)) {
              setModal((prevState) => ({ ...prevState, show: false, token: true }));
            } else {
              setToast((prevState) => ({
                ...prevState,
                show: true,
                title: title,
                className: "text-white bg-danger",
                textContent: message,
              }));
            }
          }
        } else {
          setState((prevState) => ({ ...prevState, dataStatusCases: dataStatusCasesDashboard }));
        }
      } catch (error) {
        setToast((prevState) => ({
          ...prevState,
          show: true,
          title: "ERROR",
          className: "text-white bg-danger",
          textContent: error.message,
        }));
      } finally {
        hiddenToast();
        setState((prevState) => ({ ...prevState, spinnerCases: false }));
      }
    },
    [currentUser.idToken, dateCurrent]
  );

  const onChangeYearSelected = async (event, arg) => {
    const { target } = event;
    switch (arg) {
      case "Femicidios":
        setState((prevState) => ({ ...prevState, spinnerFemicides: true }));
        await getDataFemicides(target.textContent);
        break;
      case "Expedientes":
        setState((prevState) => ({ ...prevState, spinnerAreas: true }));
        await getDataAreasAttention(target.textContent);
        break;
      default:
        setState((prevState) => ({ ...prevState, spinnerCases: true }));
        await getDataStatusCases(target.textContent);
        break;
    }
  };

  const hiddenToast = () => {
    timeOut = setTimeout(() => {
      setToast((prevState) => ({ ...prevState, show: false }));
    }, 6500);
  };

  const toggleModalToken = () => {
    setModal((prevState) => ({ ...prevState, token: !modal.token }));
  };

  const willUnMount = useCallback(() => {
    clearTimeout(timeOut);
  }, []);

  useEffect(() => {
    if (dateCurrent) {
      sessionStorage.removeItem("messageWelcome");
      getDataFemicides();
      getDataAreasAttention();
      getDataStatusCases();
    }

    return willUnMount;
  }, [dateCurrent]);

  return (
    <CContainer fluid>
      <StyledCCard className="p-4">
        {stateGlobal.loading ? (
          <div>
            <Spinner width={250} height="auto" />
            <p className="h4 font-regular text-center">Espere un momento por favor...</p>
          </div>
        ) : (
          <>
            <h3 className="text-center mb-4">Estadísticas</h3>
            <CRow className="justify-content-center">
              <WidgetsCount state={state} onClickDropdownItem={onChangeYearSelected} />
            </CRow>
            {/* <CRow className="mt-4">
            <CCol xs="12" md="6">
              <h3 className="mb-4">Resumen gráfico</h3>
              <ChartLineSimple style={{ height: 262 }} borderColor="#5e039b" />
            </CCol>
            <CCol xs="12" md="6">
              <h3 className="mb-4">Resumen gráfico</h3>
              <ChartBarSimple style={{ height: 262 }} backgroundColor="#bb2865" />
            </CCol>
          </CRow> */}
          </>
        )}
      </StyledCCard>
      <Toast show={toast.show} title={toast.title} className={toast.className} textContent={toast.textContent} autohide={6000} />
      <ModalResetToken show={modal.token} spinner={modal.spinner} setModal={setModal} toggleModal={toggleModalToken} />
    </CContainer>
  );
};

export default DigitizerDashboard;
