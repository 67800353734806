import React from "react";
import { CSwitch } from "@coreui/react";

const Switch = ({ className, checked, onChange }) => {
  return (
    <div className={`d-flex justify-content-between ${className}`}>
      <CSwitch
        checked={checked}
        onChange={onChange}
        color="success"
        value="success"
        variant="3d"
      />
    </div>
  );
};

export default Switch;
