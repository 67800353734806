import React from "react";
import { CContainer, CCol, CRow } from "@coreui/react";
import { useSelector } from "react-redux";
import { Input, Button, Dropdown, TextArea, Imagen, Label, WebCam, Spinner, Icon } from "../../atoms";
import { DateTimePicker, Popover, ContainerSignature } from "../../molecules";
import photoProfile from "../../../assets/photo-profile.jpg";
// Consts
import { nameOrLastnameReg, numberIdentityReg, phoneNumberHomeReg, dateReg, emailReg, phoneNumberReg } from "../../../commons/consts/regularexp";
import { decodeFormatBase64 } from "../../../commons/consts/images";
// Helpers
import { validInputReExp } from "../../../commons/helpers/validations";
import styled from "styled-components";

const UserFemaleForm = ({
  state,
  webCam,
  signatureState,
  spinner,
  charges,
  roles,
  capturePhoto,
  onChangedInputs,
  onHandleShowWebCam,
  onHandleShowSignature,
  onHandleClearSignature,
  onHandleSaveSignature,
  onEndSignature,
}) => {
  const dateCurrent = useSelector((state) => state.dateCurrent);
  const {
    id,
    firstname,
    middlename,
    lastname,
    middlelastname,
    phone_house,
    phone_cell1,
    phone_cell2,
    address,
    birthday,
    digital_footprint,
    email,
    id_charge,
    id_rol,
    identity,
    username,
    password,
    photoURL,
  } = state;
  return (
    <StyledCard className="py-4 px-5">
      {spinner ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <>
          <CRow className="mb-2">
            <h5>Ingrese los datos para agregar una nueva usuaria al sistema.</h5>
          </CRow>
          {signatureState.show && (
            <ContainerSignature
              signatureState={signatureState}
              onClickBtnBack={onHandleShowSignature}
              onClickBtnClear={onHandleClearSignature}
              onClickBtnSave={onHandleSaveSignature}
              onEnd={onEndSignature}
            />
          )}
          <CRow className="rounded pt-4">
            <CCol xs="12" lg={!webCam.show ? "4" : "12"} className="text-center">
              <div type="button" onClick={onHandleShowWebCam}>
                {webCam.show ? (
                  <WebCam webCamRef={webCam.ref} />
                ) : (
                  !signatureState.show && <Imagen src={photoURL ? photoURL : photoProfile} width="100%" className="rounded-lg" />
                )}
              </div>
              {!signatureState.show && (
                <p className={`${webCam.show && "h5"} font-bold mb-4 mt-2`}>
                  Haga click sobre la imagen para{" "}
                  {webCam.show ? <small className="h5 text-danger">ocultar</small> : <small className="h6 text-danger">mostrar</small>} la cámara web.
                </p>
              )}
              {webCam.show && <Button text="Tomar foto" className="mb-3 h3 bg-gradient-danger text-light" onClick={capturePhoto} />}

              {!webCam.show && !signatureState.show && (
                <div>
                  <Dropdown
                    placeholder={id_charge.label}
                    className="mb-3"
                    data={charges}
                    onClick={(e) => onChangedInputs(e, "id_charge", "dropdown")}
                    color={id_charge.value !== -1 ? "purple" : "secondary"}
                  />
                  <Dropdown
                    placeholder={id_rol.label}
                    className="mb-3"
                    data={roles}
                    onClick={(e) => onChangedInputs(e, "id_rol", "dropdown")}
                    color={id_rol.value !== -1 ? "purple" : "secondary"}
                  />
                  {digital_footprint ? (
                    <button onClick={onHandleShowSignature} className="position-relative w-100 rounded-lg mb-3 py-2">
                      <Imagen src={decodeFormatBase64 + digital_footprint} width={100} height={80} />
                      <div className="position-absolute bg-success rounded-circle p-1" style={{ top: 5, right: 5 }}>
                        <Icon name="check" className="text-white" size="lg" />
                      </div>
                    </button>
                  ) : (
                    <Button
                      icon="penNib"
                      size="2x"
                      text="Agregar firma digital"
                      color="secondary"
                      className="mb-3 w-100"
                      onClick={onHandleShowSignature}
                    />
                  )}
                </div>
              )}
            </CCol>
            {!webCam.show && !signatureState.show && (
              <CCol lg="8" xs="12">
                <CRow className="pb-4">
                  <CCol xs="12" sm="6">
                    <Label text="Primer nombre" isRequeried />
                    <Input
                      type="text"
                      value={firstname}
                      onChange={(e) => onChangedInputs(e, "firstname", "input")}
                      classNameInput={validInputReExp(firstname, nameOrLastnameReg)}
                    />
                  </CCol>
                  <CCol xs="12" sm="6">
                    <Label text="Segundo nombre" isRequeried />
                    <Input
                      type="text"
                      value={middlename}
                      onChange={(e) => onChangedInputs(e, "middlename", "input")}
                      classNameInput={validInputReExp(middlename, nameOrLastnameReg)}
                    />
                  </CCol>
                  <CCol xs="12" sm="6" className="mt-3">
                    <Label text="Primer apellido" isRequeried />
                    <Input
                      type="text"
                      value={lastname}
                      onChange={(e) => onChangedInputs(e, "lastname", "input")}
                      classNameInput={validInputReExp(lastname, nameOrLastnameReg)}
                    />
                  </CCol>
                  <CCol xs="12" sm="6" className="mt-3">
                    <Label text="Segundo apellido" />
                    <Input
                      type="text"
                      value={middlelastname}
                      onChange={(e) => onChangedInputs(e, "middlelastname", "input")}
                      classNameInput={validInputReExp(middlelastname, nameOrLastnameReg)}
                    />
                  </CCol>
                </CRow>
                <CRow className="pb-4">
                  <CCol xs="12" sm="6">
                    <Label text="Identidad" isRequeried />
                    <Input
                      placeholder="Sin guiones"
                      value={identity}
                      onChange={(e) => onChangedInputs(e, "identity", "input")}
                      classNameInput={validInputReExp(identity, numberIdentityReg)}
                      maxLength={13}
                    />
                  </CCol>
                  <CCol xs="12" sm="6">
                    <Label text="Nacimiento" isRequeried />
                    <DateTimePicker
                      disableTime
                      disableTime2
                      valueDate={birthday < dateCurrent ? birthday : dateCurrent}
                      onChangeDate={(e) => onChangedInputs(e, "birthday", "input")}
                      classNameDate={validInputReExp(birthday, dateReg)}
                      maxDate={dateCurrent}
                    />
                  </CCol>
                  <CCol xs="12" sm="6" className="mt-3">
                    <Label text="Correo" isRequeried />
                    <Input
                      type="mail"
                      value={email}
                      onChange={(e) => onChangedInputs(e, "email", "input")}
                      classNameInput={validInputReExp(email, emailReg)}
                    />
                  </CCol>
                  <CCol xs="12" sm="6" className="mt-3">
                    <Label text="Teléfono casa" />
                    <Input
                      type="text"
                      value={phone_house}
                      onChange={(e) => onChangedInputs(e, "phone_house", "input")}
                      maxLength={8}
                      classNameInput={validInputReExp(phone_house, phoneNumberHomeReg)}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xs="12" sm="6">
                    <Label text="Celular 1" isRequeried />
                    <Input
                      type="text"
                      value={phone_cell1}
                      onChange={(e) => onChangedInputs(e, "phone_cell1", "input")}
                      maxLength={8}
                      classNameInput={validInputReExp(phone_cell1, phoneNumberReg)}
                    />
                  </CCol>
                  <CCol xs="12" sm="6">
                    <Label text="Celular 2" />
                    <Input
                      type="text"
                      value={phone_cell2}
                      onChange={(e) => onChangedInputs(e, "phone_cell2", "input")}
                      maxLength={8}
                      classNameInput={validInputReExp(phone_cell2, phoneNumberReg)}
                    />
                  </CCol>
                  <CCol xs="12" sm="6" className="mt-3">
                    <Label text="Usuaria" isRequeried />
                    <Input type="text" value={username} onChange={(e) => onChangedInputs(e, "username", "input")} />
                  </CCol>
                  <CCol xs="12" sm="6" className="mt-3">
                    {!id && (
                      <Popover
                        children={
                          <div>
                            <Label text="Contraseña" isRequeried />
                            <Input type="password" value={password} onChange={(e) => onChangedInputs(e, "password", "input")} />
                          </div>
                        }
                        content="Mayor o igual a 6 carácteres."
                      />
                    )}
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className="mt-3">
                    <TextArea
                      placeholder="Dirección exacta.....(obligatorio)"
                      maxLength={800}
                      className="w-100"
                      height={70}
                      value={address}
                      onChange={(e) => onChangedInputs(e, "address", "input")}
                    />
                  </CCol>
                </CRow>
              </CCol>
            )}
          </CRow>
        </>
      )}
    </StyledCard>
  );
};

const StyledCard = styled(CContainer)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default UserFemaleForm;
