import React from "react";
import { CListGroup, CListGroupItem, CRow, CCol } from "@coreui/react";
import { Dropdown, Label, Button, TextArea } from "../../atoms";
import { useSelector } from "react-redux";

const ListGroup = ({
  list = [],
  itemsSelected = [],
  title,
  valueTextArea,
  custom,
  color = "primary",
  onClick,
  placeholder,
  className,
  itemDelete,
  onChangeTextArea,
  isRequeried,
}) => {
  const theme = useSelector((state) => state.theme);
  return (
    <div className={`${className} rounded p-3 my-3 ${theme === "dark" ? "text-white bg-info" : "text-dark bg-secondary"}`}>
      <Label text={title} className="h5" isRequeried={isRequeried} />
      <CRow className="justify-content-end">
        {custom ? (
          <>
            <CCol md="8">
              <TextArea
                value={valueTextArea}
                placeholder="Redacte..."
                className={`${theme === "dark" ? "text-white" : "text-dark"}`}
                maxLength={200}
                height={35}
                onChange={onChangeTextArea}
              />
            </CCol>
            <CCol md="4">
              <Button
                text="Agregar"
                color="success"
                className="w-100"
                onClick={() => onClick({ label: valueTextArea, value: list.length })}
                disabled={valueTextArea ? false : true}
              />
            </CCol>
          </>
        ) : (
          <Dropdown
            data={list}
            onClick={onClick}
            placeholder={placeholder}
            color={placeholder !== "Seleccione" ? "purple" : "secondary"}
            className="mr-3"
          />
        )}
      </CRow>
      <CListGroup accent className="mt-3">
        {itemsSelected.map(({ label, value }) => (
          <CListGroupItem key={value} href="#" color={color} className="mt-1 rounded py-1" value={value} onClick={itemDelete}>
            {label}
          </CListGroupItem>
        ))}
      </CListGroup>
    </div>
  );
};

export default ListGroup;
