import React from "react";
import { Dropdown, Label, Badge, CheckBoxes, Spinner } from "../../atoms";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const CheckBoxesMultiple = ({ data, controller, onChange, title, subTitle, field, classNameTitle }) => {
  const theme = useSelector((state) => state.theme);
  return (
    <div className={`p-2 my-3 ${theme === "dark" ? "text-white bg-info" : "text-dark bg-secondary"}`}>
      <Label text={title} isRequeried className={classNameTitle} />
      <div>
        <Dropdown
          data={data}
          placeholder={controller.selected}
          color={controller.selected !== "Seleccione" ? "purple" : "secondary"}
          onClick={(e) => onChange(e, field, "check-box-multiple")}
          className="mb-3"
        />
        {controller.loading ? (
          <Spinner width={100} height={100} />
        ) : (
          <CheckBoxes
            list={controller.items}
            name={field}
            checked={controller.itemsSelected}
            onChange={(e) => onChange(e, field, "check-boxes")}
          />
        )}
      </div>
      <div className="mt-3">
        <Label text={subTitle} className={`font-italic ${theme === "dark" ? "text-gray" : "text-purple"}`} />
        <div className={`p-1 rounded border ${theme === "dark" ? "bg-dark" : "bg-light"}`}>
          {controller.itemsSelected.map((item, index) => (
            <Badge
              key={field + controller.selected + index}
              field={field}
              label={item.label}
              value={item.value}
              dependency={item.dependency}
              className="m-1 p-2 text-white"
              onClick={(e) => onChange(e, field, "badge")}
              iconName="cancel"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

CheckBoxesMultiple.propTypes = {
  controller: PropTypes.object,
  onChange: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  type: PropTypes.number,
  data: PropTypes.array,
};

CheckBoxesMultiple.defaultProps = {
  title: "Título",
  subTitle: "Sub título",
  data: [
    { value: "asd", label: "Fisica" },
    { value: "dsa", label: "Patrimonial" },
  ],
  controller: {
    selected: "Seleccione",
    items: [
      { value: "1", label: "Golpes" },
      { value: "2", label: "Rasguños" },
    ],
    itemsSelected: [{ value: "asd", label: "Fisica" }],
    loading: false,
  },
  onChange: () => {},
  type: 1,
};

export default CheckBoxesMultiple;
