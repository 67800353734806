import React from "react";
import { CToast, CToaster, CToastHeader, CToastBody } from "@coreui/react";
import styled from "styled-components";

const Toast = ({ show, title, textContent, className, autohide = 4000 }) => {
  return (
    <CToaster position="top-right">
      <StyledCard show={show} autohide={autohide} fade={true}>
        <CToastHeader closeButton={false} className={className}>
          {title}
        </CToastHeader>
        <CToastBody>{textContent}</CToastBody>
      </StyledCard>
    </CToaster>
  );
};

const StyledCard = styled(CToast)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default Toast;
