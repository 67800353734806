import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { MenuItemSlider, Button, ChervonRight, ChervonLeft } from "../../atoms";
import { CCol, CRow } from "@coreui/react";
import imgFormAdd from "../../../assets/form-add.svg";

const ContainerSlider = ({ data = [], title, className, toggleModal, toggleModalItem }) => {
  return (
    <CRow className={`align-items-center ${className}`}>
      <CCol>
        <h4 className="font-bold">{title}</h4>
        <ScrollMenu LeftArrow={<ChervonLeft />} RightArrow={<ChervonRight />}>
          {data.map(({ description, attentionDate, colorCard, start }, index) => (
            <MenuItemSlider
              title={title}
              index={index}
              key={description + index}
              itemId={description + index}
              header={"Cita " + (index + 1)}
              text={description}
              dateAttention={attentionDate}
              dateQuote={start}
              color={colorCard}
              toggleModal={toggleModalItem}
            />
          ))}
        </ScrollMenu>
      </CCol>
      <div className="m-3">
        <Button image={imgFormAdd} width={50} color="secondary" classNameIcon="text-light" onClick={toggleModal} />
      </div>
    </CRow>
  );
};

export default ContainerSlider;
