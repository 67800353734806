import React, { useState, useRef, useCallback, useEffect } from "react";
import { CContainer, CCard, CCardBody, CCardTitle, CCol, CRow, CCardSubtitle } from "@coreui/react";
import { Button, Toast } from "../../atoms";
import { ModalRegister, TableRegister, UserFemaleForm, ModalResetToken } from "../../molecules";
import { useSelector } from "react-redux";
// Helpers
import { getCodeImagenBase64 } from "../../../commons/helpers/utils";
import { validationsUserForm, verifyTokenExpired } from "../../../commons/helpers/validations";
// Static
import { fieldsUsers } from "../../../commons/static/tables";
import { resolutionWebCamProfile } from "../../../commons/consts/images";
// Services
import { registerNewUser, getAllUsers, updateUser, disabledUser } from "../../../commons/services/users";
import { getStorageRefPath, upLoadImageBase64 } from "../../../config/config";
import styled from "styled-components";

let toggleModalEdit = false;

const AdminUsers = () => {
  let timeOut = useRef(null);
  const userCurrent = useSelector((state) => state.currentUser);
  const roles = useSelector((state) => state.roles);
  const charges = useSelector((state) => state.charges);
  const dateCurrent = useSelector((state) => state.dateCurrent);
  const filterCases = useSelector((state) => state.filterCases);
  const [spinner, setSpinner] = useState(false);

  const [modal, setModal] = useState({
    show: false,
    textButtonOnConfrm: "Guardar",
  });
  // States
  const [users, setUsers] = useState({
    data: [],
    dataFilter: [],
    spinner: true,
    spinnerEnabled: false,
    itemIndex: -1,
    filters: { ...filterCases, tableFilterValue: null },
  });

  const [state, setState] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    middlelastname: "",
    phone_house: "",
    phone_cell1: "",
    phone_cell2: "",
    address: "",
    birthday: "",
    digital_footprint: "",
    email: "",
    id_charge: { value: -1, label: "Cargo" },
    id_rol: { value: -1, label: "Rol" },
    identity: "",
    username: "",
    password: "",
    photoURL: "",
    enabled: true,
  });

  const [toast, setToast] = useState({
    show: false,
    textContent: "",
    title: "Campos incorrectos",
    className: "bg-danger text-white",
  });

  const [webCam, setWebCam] = useState({
    show: false,
    ref: useRef(null),
  });

  const [signature, setSignature] = useState({
    ref: useRef(null),
    show: false,
    isEmpty: !state.digital_footprint ? true : false,
  });

  const toggleModal = (user, index) => {
    if (index !== undefined) {
      toggleModalEdit = true;
      setModal((prevState) => ({
        ...prevState,
        show: !modal.show,
        textButtonOnConfrm: "Actualizar",
      }));
      setState((prevState) => ({
        ...prevState,
        ...user,
      }));
    } else if (toggleModalEdit) {
      toggleModalEdit = false;
      setModal((prevState) => ({
        ...prevState,
        show: !modal.show,
        textButtonOnConfrm: "Guardar",
      }));
      clearStates();
    } else {
      setModal((prevState) => ({
        ...prevState,
        show: !modal.show,
      }));
    }
  };

  const toggleModalToken = () => {
    setModal((prevState) => ({ ...prevState, token: !modal.token }));
  };

  const onChangedEnabledDisabled = async (user, index) => {
    const { id, enabled, username } = user;
    let dataUsersTemp = users.data;
    setUsers((prevState) => ({
      ...prevState,
      itemIndex: index,
      spinnerEnabled: true,
    }));
    try {
      const { success, message, title, code } = await disabledUser({
        idToken: userCurrent.idToken,
        id,
        enabled,
        username,
      });
      if (success) {
        dataUsersTemp.splice(index, 1, { ...user, enabled: !enabled });
        setUsers((prevState) => ({
          ...prevState,
          data: dataUsersTemp,
          dataFilter: dataUsersTemp,
          itemIndex: -1,
          spinnerEnabled: false,
        }));
      } else if (verifyTokenExpired(code)) {
        setModal((prevState) => ({ ...prevState, show: false, token: true }));
      }
      setToast(
        (prevState) => ({
          ...prevState,
          show: true,
          title,
          textContent: message,
          className: success ? "bg-success text-white" : "bg-danger text-white",
        }),
        setUsers((prevState) => ({ ...prevState, spinnerEnabled: false }))
      );
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: !toast.show,
        title: "Error",
        textContent: error.message,
        className: "bg-danger text-white",
      }));
    } finally {
      hiddenToast();
    }
  };

  const onChangedInputs = (event, field, arg) => {
    const { target } = event;
    switch (arg) {
      case "input":
        setState((prevState) => ({ ...prevState, [field]: target.value }));
        break;
      case "dropdown":
        setState((prevState) => ({
          ...prevState,
          [field]: { value: target.getAttribute("value"), label: target.text },
        }));
        break;
      default:
        break;
    }
  };

  const verifFieldsUserForm = () => {
    const options = {
      dateCurrent,
    };
    const verifyFields = validationsUserForm(state, modal.textButtonOnConfrm, options);
    if (!verifyFields.valid) {
      setToast(
        (prevState) => ({
          ...prevState,
          show: true,
          title: "Campos incorrectos",
          textContent: verifyFields.message,
          className: "bg-danger text-white",
        }),
        timeOut.current
      );
      hiddenToast();
    } else {
      setSpinner(true);
      switch (modal.textButtonOnConfrm) {
        case "Actualizar":
          // Funcion para actualizar Usuaria
          onHandleUpdateUser();
          break;
        default:
          createNewUser();
          break;
      }
    }
  };

  const hiddenToast = () => {
    // TimeOut for Toast
    timeOut = setTimeout(() => setToast((prevState) => ({ ...prevState, show: false })), 6500);
  };

  const createNewUser = async () => {
    try {
      const dataRequest = { ...state, idToken: userCurrent.idToken };
      dataRequest.photoURL = getCodeImagenBase64(state.photoURL);

      const storageRefPath = getStorageRefPath(`users_system/${state.identity}/${state.username}.png`);
      const urlPhoto = await upLoadImageBase64(storageRefPath, dataRequest.photoURL);

      const { success, message, title, code } = await registerNewUser({
        ...dataRequest,
        photoURL: urlPhoto,
      });
      if (success) {
        toggleModal();
        clearStates();
        setUsers((prevState) => ({ ...prevState, spinner: true }));
        onHandleGetAllUsers();
      } else if (verifyTokenExpired(code)) {
        setModal((prevState) => ({ ...prevState, show: false, token: true }));
      }
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title,
        className: success ? "text-white bg-success" : "text-white bg-danger",
        textContent: message,
      }));
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: !toast.show,
        title: "Error",
        textContent: error.message,
        className: "bg-danger text-white",
      }));
    } finally {
      setSpinner(false);
      hiddenToast();
    }
  };

  const onHandleUpdateUser = async () => {
    let urlPhoto = state.photoURL;
    const dataRequest = {
      ...state,
      idToken: userCurrent.idToken,
    };

    try {
      if (state.photoURL.substring(0, 5) !== "https") {
        dataRequest.photoURL = getCodeImagenBase64(state.photoURL);
        const storageRefPath = getStorageRefPath(`users_system/${state.identity}/${state.username}.png`);
        urlPhoto = await upLoadImageBase64(storageRefPath, dataRequest.photoURL);
      }
      const { success, title, message, code } = await updateUser({
        ...dataRequest,
        photoURL: urlPhoto,
      });
      if (success) {
        toggleModal();
        setUsers((prevState) => ({ ...prevState, spinner: true }));
        onHandleGetAllUsers();
      } else if (verifyTokenExpired(code)) {
        setModal((prevState) => ({ ...prevState, show: false, token: true }));
      }
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title,
        className: success ? "text-white bg-success" : "text-white bg-danger",
        textContent: message,
      }));
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: !toast.show,
        title: "Error",
        textContent: error.message,
        className: "bg-danger text-white",
      }));
    } finally {
      setSpinner(false);
      hiddenToast();
    }
  };

  const onHandleShowWebCam = () => {
    setWebCam((prevState) => ({ ...prevState, show: !webCam.show }));
  };

  const capturePhoto = useCallback(() => {
    const imageSrc = webCam.ref.current.getScreenshot(resolutionWebCamProfile);
    setState(
      (prevState) => ({ ...prevState, photoURL: imageSrc }),
      setWebCam((prevState) => ({ ...prevState, show: false }))
    );
  }, [webCam.ref]);

  // Signature Functions
  const onHandleShowSignature = () => {
    setSignature((prevState) => ({ ...prevState, show: !signature.show }));
  };

  const onHandleClearSignature = () => {
    if (!signature.ref.isEmpty() || state.digital_footprint) {
      signature.ref.clear();
      setSignature((prevState) => ({
        ...prevState,
        isEmpty: true,
      }));
      setState((prevState) => ({ ...prevState, digital_footprint: null }));
    }
  };

  const onHandleSaveSignature = () => {
    const { isEmpty, getTrimmedCanvas } = signature.ref;
    if (isEmpty) {
      const reducerImageBase64 = getTrimmedCanvas().toDataURL();
      const decodeImageBase64 = reducerImageBase64.split(",", 2);
      setState((prevState) => ({
        ...prevState,
        digital_footprint: decodeImageBase64[1],
      }));
      setSignature((prevState) => ({ ...prevState, show: false }));
    }
  };

  const onEndSignature = () => {
    setSignature((prevState) => ({
      ...prevState,
      isEmpty: false,
    }));
  };

  const willUnMount = useCallback(() => {
    clearTimeout(timeOut);
    clearInterval(signature.ref);
  }, [signature.ref]);

  const clearStates = () => {
    delete state.id;
    setState((prevState) => ({
      ...prevState,
      firstname: "",
      middlename: "",
      lastname: "",
      middlelastname: "",
      phone_house: "",
      phone_cell1: "",
      phone_cell2: "",
      address: "",
      birthday: "",
      digital_footprint: "",
      email: "",
      id_charge: { value: -1, label: "Cargo" },
      id_rol: { value: -1, label: "Rol" },
      identity: "",
      username: "",
      password: "",
      photoURL: "",
      enabled: true,
    }));
  };

  const onHandleGetAllUsers = useCallback(async () => {
    const { idToken, displayName } = userCurrent;
    try {
      const { success, users, code, message, title } = await getAllUsers({
        idToken,
        id_rol: displayName.id_rol.value,
      });
      if (success) {
        setUsers((prevState) => ({
          ...prevState,
          data: users,
          dataFilter: users,
        }));
      } else {
        if (verifyTokenExpired(code)) {
          setModal((prevState) => ({ ...prevState, show: false, token: true }));
        } else {
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title,
            className: "text-white bg-danger",
            textContent: message,
          }));
        }
      }
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: !toast.show,
        title: "Error: " + error.code,
        textContent: error.message,
        className: "text-white bg-danger",
      }));
    } finally {
      setUsers((prevState) => ({ ...prevState, spinner: false }));
      hiddenToast();
    }
  }, [userCurrent, toast.show]);

  useEffect(() => {
    if (userCurrent.idToken) {
      onHandleGetAllUsers();
    }
    return willUnMount;
  }, [onHandleGetAllUsers, willUnMount, userCurrent.idToken]);

  return (
    <CContainer fluid>
      <StyledCard>
        <CRow className="pt-3 px-3 justify-content-between align-items-center">
          <CCol>
            <CCardTitle className="h2">Usuarias</CCardTitle>
          </CCol>
          <CCol className="text-right">
            <Button text="CREAR NUEVA USUARIA" color="success" onClick={toggleModal} />
          </CCol>
        </CRow>
        <CRow className="px-3 pt-3">
          <CCol>
            <CCardSubtitle className="h5">
              Gestione a las usuarias del sistema SAISV con acciones de creación, edición, habilitación/deshabilitación y busquedas.
            </CCardSubtitle>
          </CCol>
        </CRow>
        <CCardBody>
          <TableRegister
            switchEnable
            elements={users}
            fields={fieldsUsers}
            toggleModal={toggleModal}
            onChangedEnabledDisabled={onChangedEnabledDisabled}
          />
        </CCardBody>
      </StyledCard>
      <ModalRegister
        show={modal.show}
        title="USUARIAS"
        toggleModal={toggleModal}
        textButtonOnConfirm={modal.textButtonOnConfrm}
        content={
          <UserFemaleForm
            state={state}
            webCam={webCam}
            signatureState={signature}
            spinner={spinner}
            roles={roles}
            charges={charges}
            capturePhoto={capturePhoto}
            onChangedInputs={onChangedInputs}
            onHandleShowWebCam={onHandleShowWebCam}
            onHandleShowSignature={onHandleShowSignature}
            onHandleClearSignature={onHandleClearSignature}
            onHandleSaveSignature={onHandleSaveSignature}
            onEndSignature={onEndSignature}
          />
        }
        sizeModal="lg"
        classModalHeader="h3"
        onConfirm={verifFieldsUserForm}
        disabledBtnConfirm={toast.show}
      />
      <Toast show={toast.show} title={toast.title} className={toast.className} textContent={toast.textContent} autohide={6000} />
      <ModalResetToken show={modal.token} spinner={modal.spinner} setModal={setModal} toggleModal={toggleModalToken} />
    </CContainer>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default AdminUsers;
