const monthArray = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
const monthCompleteArray = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];

const convertFormatDate = (date, addOneDay) => {
  const dateNow = new Date(date);
  const year = dateNow.getFullYear();
  let day;
  if (addOneDay) {
    day = dateNow.getDate() + 1;
  } else {
    day = dateNow.getDate();
  }
  const month = monthArray[dateNow.getMonth()];
  const dateFormat = `${day}/${month}/${year}`;
  return dateFormat;
};

const convertFormatTime = (time) => {
  const timeNow = new Date(time);
  const hours = timeNow.getHours();
  const minutes = timeNow.getMinutes();
  let hourLocal;
  let minutesLocal;
  if (hours >= 13 && hours <= 23) {
    hourLocal = hours - 12;
  } else {
    hourLocal = hours;
  }
  if (minutes >= 0 && minutes < 10) {
    minutesLocal = "0" + minutes;
  } else {
    minutesLocal = minutes;
  }
  return `${hourLocal}:${minutesLocal}${hours >= 12 && hours <= 23 ? "PM" : "AM"}`;
};

const beforeMonths = (dateCurrent) => {
  let months = [];
  const mothCurrent = parseInt(new Date(dateCurrent).getMonth());
  for (var i = 0; i <= mothCurrent; i++) {
    months.push(monthArray[i]);
  }
  return months;
};

const getMonthNameComplete = (dateCurrent) => {
  const mothCurrent = parseInt(new Date(dateCurrent).getMonth());
  return monthCompleteArray[mothCurrent - 1];
};

export { convertFormatDate, convertFormatTime, beforeMonths, getMonthNameComplete };
