const elementsTabReports = [
  { id: "tab-1", name: "DATOS DEMOGRÁFICOS" },
  { id: "tab-2", name: "ATENCIÓN LEGAL" },
  { id: "tab-3", name: "ATENCIÓN EMOCIONAL" },
  { id: "tab-4", name: "FEMICIDIOS" },
  { id: "tab-5", name: "TABLAS" },
];

const elementsTabCareRecords = [
  { id: "tab-1", name: "Emocional" },
  { id: "tab-2", name: "Legal" },
];

// Texto de Fuente para las gráficas
const footerFontChartsText =
  "Fuente: Sistema de Atención Integral para Sobrevivientes de Violencia (SAISV), Movimiento de Mujeres Por La Paz Visitación Padilla.";

export { elementsTabReports, footerFontChartsText, elementsTabCareRecords };
