import React from "react";
import { CContainer, CRow, CCol } from "@coreui/react";
import { Input, Dropdown, Label, TextArea, Imagen, Spinner, CheckBoxes } from "../../atoms";
import { DateTimePicker, ListGroup, ProgressBar } from "..";
import { useSelector } from "react-redux";

const FollowForm = ({ paraments, controllers, onChangeInputs, deleteItem }) => {
  const stateGlobal = useSelector((state) => ({
    crimes: state.crimes,
    support: state.support,
    virtualOptions: state.virtualOptions,
    attentionMode: state.attentionMode,
    photoUser: state.currentUser.photoURL,
    displayName: state.currentUser.displayName,
    dateCurrent: state.dateCurrent,
  }));

  const {
    names,
    lastnames,
    dateFollow,
    hourFollow,
    nextQuoteDate,
    nextQuoteHourStart,
    nextQuoteHourEnd,
    crimesFollow,
    otherCrimes,
    descriptionFollow,
    supportFollow,
    otherSupportFollow,
    attentionModeFollow,
    virtualOptionsFollow,
    progressFollow,
    photoUser,
    attentionBy,
  } = paraments;
  const { spinner } = controllers;

  return (
    <CContainer fluid className="rounded-lg">
      {spinner ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <>
          <CRow>
            <CCol xs="12" lg="6">
              <Label text="1. Fecha y hora" className="mt-3 h5" isRequeried />
              <DateTimePicker
                disabled
                disableTime2
                valueDate={dateFollow}
                valueTime={hourFollow}
                onChangeDate={(e) => onChangeInputs(e, "dateFollow", "input")}
                onChangeTime={(e) => onChangeInputs(e, "hourFollow", "input")}
                maxDate={stateGlobal.dateCurrent}
              />
              <Label text="2. Nombres" className="h5 mt-3" />
              <Input type="text" value={names} disabled={true} />
              <Label text="3. Apellidos" className="h5 mt-3" />
              <Input type="text" value={lastnames} disabled={true} />
              <ListGroup
                isRequeried
                title="4. Delitos o faltas denunciadas"
                list={stateGlobal.crimes}
                placeholder={crimesFollow.selected}
                itemsSelected={crimesFollow.items}
                onClick={(e) => onChangeInputs(e, "crimesFollow", "list-group")}
                itemDelete={(e) => deleteItem(e, "crimesFollow")}
              />
              <TextArea
                height={80}
                value={otherCrimes}
                placeholder="Otros delitos o faltas"
                onChange={(e) => onChangeInputs(e, "otherCrimes", "input")}
                maxLength={500}
              />
              <Label text="5. Descripción" className="mt-3 h5" />
              <TextArea maxLength={3000} value={descriptionFollow} onChange={(e) => onChangeInputs(e, "descriptionFollow", "input")} />
            </CCol>
            <CCol xs="12" lg="6">
              <Label text="6. Apoyo brindado" className="h5 mt-3" isRequeried />
              <CheckBoxes
                name="support"
                classNameGroup="ml-4"
                list={stateGlobal.support}
                onChange={(e) => onChangeInputs(e, "supportFollow", "check-box")}
                checked={supportFollow}
              />
              <Input placeholder="Otros" value={otherSupportFollow} onChange={(e) => onChangeInputs(e, "otherSupportFollow", "input")} />
              <Label text="7. Progreso" className="mt-3 h5" isRequeried />
              <Input
                type="number"
                value={progressFollow}
                onChange={(e) => onChangeInputs(e, "progressFollow", "number")}
                placeholder="Nivel de progreso anterior"
                classNameInput="w-50"
                min={0}
                max={100}
              />
              <ProgressBar xs="12" md="12" valueProgress={progressFollow} />
              <Label text="8. Agenda de próxima cita" className="mt-3 h5" isRequeried />
              <DateTimePicker
                valueDate={nextQuoteDate}
                valueTime={nextQuoteHourStart}
                valueTime2={nextQuoteHourEnd}
                onChangeDate={(e) => onChangeInputs(e, "nextQuoteDate", "input")}
                onChangeTime={(e) => onChangeInputs(e, "nextQuoteHourStart", "input")}
                onChangeTime2={(e) => onChangeInputs(e, "nextQuoteHourEnd", "input")}
                minDate={stateGlobal.dateCurrent}
              />
              <Label text="9. Modalidad de atención" className="h5 mt-3" isRequeried />
              <Dropdown
                data={stateGlobal.attentionMode}
                color={attentionModeFollow.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "attentionModeFollow", "dropdown")}
                placeholder={attentionModeFollow.label}
              />
              {attentionModeFollow.value === "R925ema5ejFwt1y4HICn" && (
                <div className="bg-light rounded p-3 my-3 text-dark">
                  <Label isRequeried text="10.1 - Elegir el o los tipos de modalidades virtuales" />
                  <CheckBoxes
                    name="virtualOptionsFollow"
                    list={stateGlobal.virtualOptions}
                    checked={virtualOptionsFollow}
                    onChange={(e) => onChangeInputs(e, "virtualOptionsFollow", "check-box")}
                  />
                </div>
              )}
              <Label text="Atendida por:" className="h5 mt-4" />
              <div className="d-flex flex-row align-items-center">
                <Imagen src={photoUser || stateGlobal.photoUser} width={80} height={80} className="rounded-circle" />
                <p className="h5 font-italic ml-2">{attentionBy || stateGlobal.displayName.username}</p>
              </div>
            </CCol>
          </CRow>
        </>
      )}
    </CContainer>
  );
};

export default FollowForm;
