const setCurrentUser = (value) => {
  return {
    type: "SET_CURRENT_USER",
    payload: value,
  };
};

const setShowSideBar = (value) => {
  return {
    type: "SHOW_SIDEBAR",
    payload: value,
  };
};

const setDateCurrent = (value) => {
  return {
    type: "SET_DATE_CURRENT",
    payload: value,
  };
};

const setLoading = (value) => {
  return {
    type: "SET_LOADING",
    payload: value,
  };
};

const setShowNotifications = (value) => {
  return {
    type: "SET_SHOW_NOTIFICATIONS",
    payload: value,
  };
};

const setTheme = (value) => {
  return {
    type: "SET_THEME",
    payload: value,
  };
};

const setFilterCases = (value) => {
  return {
    type: "SET_FILTER_CASES",
    payload: value,
  };
};

const setVarsInitials = (value) => {
  return {
    type: "SET_VARS_INITIALS",
    payload: value,
  };
};

const setRefreshToken = (value) => {
  return {
    type: "SET_REFRESH_TOKEN",
    payload: value,
  };
};

const setDataReports = (value) => {
  return {
    type: "SET_DATA_REPORTS",
    payload: value,
  };
};

const setFemicidesDashboard = (value) => {
  return {
    type: "SET_FEMICIDES_DASHBOARD",
    payload: value,
  };
};

const setAreasAttentionDashboard = (value) => {
  return {
    type: "SET_AREAS_ATTENTION_DASHBOARD",
    payload: value,
  };
};

const setStatusCaseDashboard = (value) => {
  return {
    type: "SET_STATUS_CASE_DASHBOARD",
    payload: value,
  };
};

export {
  setShowSideBar,
  setDateCurrent,
  setLoading,
  setShowNotifications,
  setCurrentUser,
  setTheme,
  setFilterCases,
  setVarsInitials,
  setRefreshToken,
  setDataReports,
  setFemicidesDashboard,
  setAreasAttentionDashboard,
  setStatusCaseDashboard,
};
