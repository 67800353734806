import React from "react";
import { Redirect, Route } from "react-router-dom";

export default function PrivateRouter({ component: Component, ...rest }) {
  const isSessionActived = sessionStorage.getItem("isLogged");

  return (
    <Route {...rest}>
      {isSessionActived ? <Component /> : <Redirect to="/" />}
    </Route>
  );
}
