import React, { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { CContainer, CCol, CRow, CCard, CCardBody, CCardHeader, CCardTitle } from "@coreui/react";
import { Imagen, Toast, Spinner, Label } from "../../atoms";
import { TableSearch, ModalDigitizer, CardInfo, ModalResetToken } from "../../molecules";
import { fieldsAtentions } from "../../../commons/static/tables";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { verifyTokenExpired } from "../../../commons/helpers/validations";
import { setFilterCases } from "../../../actionsCreators/Global";
// Services
import { getUserData } from "../../../commons/services/users";
import { getAllCareRecordsList } from "../../../commons/services/carerecords";
import loadingImage from "../../../assets/loading.svg";

const EvaluationUser = () => {
  let timeOut = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const stateGlobal = useSelector((state) => ({
    idRol: state.currentUser.displayName.id_rol.value,
    idToken: state.currentUser.idToken,
    itemsPerPage: state.itemsPerPage,
  }));
  const { idToken, itemsPerPage } = stateGlobal;

  const [modal, setModal] = useState({
    show: false,
    token: false,
    textButtonOnConfrm: "Guardar",
    showModalSendEvaluation: false,
    survivor: { name: "", phone: "", statusCase: 0 },
  });
  const [details, setDetails] = useState([]);
  // States
  const [userData, setUserdata] = useState({
    user: { id_rol: {}, id_charge: {}, records: {} },
    spinner: true,
    spinnerTable: true,
    spinnerItem: true,
    data: [],
    pagination: 1,
    paginationTotal: 1,
    itemIndex: -1,
    tableFilterValue: {
      name: "",
      startDate: "",
      endDate: "",
      userId: id,
    },
  });

  const [toast, setToast] = useState({
    show: false,
    textContent: "",
    title: "Campos incorrectos",
    className: "bg-danger text-white",
  });

  const toggleModal = (survivor) => {
    if (survivor && survivor.id) {
      const { names, lastnames, generalInfo, statusCase } = survivor;
      setModal((prevState) => ({
        ...prevState,
        showModalSendEvaluation: true,
        survivor: { name: `${names} ${lastnames}`, phone: generalInfo.phoneNumber, statusCase },
      }));
    } else {
      setModal((prevState) => ({
        ...prevState,
        showModalSendEvaluation: false,
        survivor: { name: "", phone: "", statusCase: 0 },
      }));
    }
  };

  const onChangeInputs = (event, field, arg) => {
    const { target } = event ? event : {};
    switch (arg) {
      case "tableFilterValue":
        if (field === "name") {
          setUserdata((prevState) => ({
            ...prevState,
            tableFilterValue: { ...userData.tableFilterValue, [field]: event },
          }));
        } else if (field === "fields-clear") {
          setUserdata(
            (prevState) => ({
              ...prevState,
              tableFilterValue: { name: "", startDate: "", endDate: "", userId: id },
              spinnerTable: true,
            }),
            onHandleGetAllCareRecordsList(itemsPerPage, 1, {
              name: "",
              startDate: "",
              endDate: "",
              userId: id,
            })
          );
        } else {
          setUserdata((prevState) => ({
            ...prevState,
            tableFilterValue: {
              ...userData.tableFilterValue,
              [field]: target.value,
            },
          }));
        }
        break;
      default:
        break;
    }
  };

  const onHandleGetAllCareRecordsList = useCallback(
    async (limit, pagination, tableFilterValue) => {
      try {
        const { success, careRecordsList, title, message, paginationTotal, code } = await getAllCareRecordsList({
          idToken,
          limit,
          pagination,
          tableFilterValue,
        });
        if (success) {
          setUserdata((prevState) => ({
            ...prevState,
            data: careRecordsList,
            pagination,
            paginationTotal,
          }));
        } else {
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, token: true }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              message,
              className: "text-whiter bg-danger",
            }));
          }
        }
      } catch (error) {
        setToast((prevState) => ({
          ...prevState,
          show: true,
          title: "Error en getAllCareRecordsList",
          className: "text-white bg-dabger",
          message: error.message,
        }));
      } finally {
        hiddenToast();
        setUserdata((prevState) => ({ ...prevState, spinnerTable: false }));
      }
    },
    [idToken]
  );

  const getHandleUserData = useCallback(async () => {
    try {
      const { user, success, code, title, message } = await getUserData({ id, idRol: stateGlobal.idRol, idToken: stateGlobal.idToken });
      if (success) {
        console.log(user);
        setUserdata((prevState) => ({ ...prevState, user }));
      } else {
        if (verifyTokenExpired(code)) {
          setModal((prevState) => ({ ...prevState, show: false, token: true }));
        } else {
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title,
            className: "text-white bg-danger",
            textContent: message,
          }));
        }
      }
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: !toast.show,
        title: "Error: " + error.code,
        textContent: error.message,
        className: "text-white bg-danger",
      }));
    } finally {
      setUserdata((prevState) => ({ ...prevState, spinner: false }));
      hiddenToast();
    }
  }, [id, stateGlobal.idRol]);

  const toggleDetails = useCallback(
    async (index) => {
      const position = details.indexOf(index);
      let newDetails = details.slice();

      if (position !== -1) {
        newDetails.splice(position, 1);
      } else {
        newDetails = [...details, index];
      }

      setDetails(newDetails);
    },
    [details, idToken]
  );

  // Tables
  const onPaginationChange = (limit) => {
    const { tableFilterValue } = userData;
    dispatch(setFilterCases(tableFilterValue));
    setUserdata((prevState) => ({ ...prevState, spinnerTable: true }), onHandleGetAllCareRecordsList(limit, 1, tableFilterValue));
    //sessionStorage.setItem("itemsPerPage", limit);
  };

  const onReadySearches = () => {
    const { tableFilterValue, pagination } = userData;
    setUserdata((prevState) => ({ ...prevState, spinnerTable: true }));
    onHandleGetAllCareRecordsList(itemsPerPage, pagination, tableFilterValue);
  };

  const toggleModalToken = () => {
    setModal((prevState) => ({ ...prevState, token: !modal.token }));
  };

  const hiddenToast = () => {
    // TimeOut for Toast
    timeOut = setTimeout(() => setToast((prevState) => ({ ...prevState, show: false })), 6500);
  };

  const willUnMount = useCallback(() => {
    clearTimeout(timeOut);
  }, []);

  const messageModalSendEvaluation = () => {
    const { statusCase } = modal.survivor;
    switch (statusCase) {
      case 1:
        return (
          <div>
            <p>
              No se puede enviar encuesta de calidad, el caso sigue abierto, asegurese de cambiar el estado del caso a "CERRADO" o
              "ABANDONADO".
            </p>
          </div>
        );
      case 0:
      case 2:
        return (
          <div>
            <p>
              ¿Está segura de enviar la evaluación a <b>{modal.survivor && modal.survivor.name}</b> al número de télefono{" "}
              <b>{modal.survivor && modal.survivor.phone}</b>?
            </p>
          </div>
        );
      default:
        return (
          <div>
            <p>Estado no definido.</p>
          </div>
        );
    }
  };

  useEffect(() => {
    if (idToken) {
      getHandleUserData();
      onHandleGetAllCareRecordsList(itemsPerPage, 1, {
        name: "",
        startDate: "",
        endDate: "",
        userId: id,
      });
    }
    return willUnMount;
  }, [idToken, willUnMount]);

  const { firstname, lastname, id_rol, photoURL, age, phone_cell1, id_charge, records } = userData.user;

  const info = {
    Usuaria: `${firstname} ${lastname}`,
    Edad: age,
    Cargo: id_charge.label,
    Celular: phone_cell1 ? phone_cell1 : "No registrado",
    Rol: id_rol.label,
  };

  return (
    <CContainer fluid>
      <StyledCard>
        <StyledCardHeader className="h3">
          Evaluación de {firstname} {lastname}
        </StyledCardHeader>
        {userData.spinner ? (
          <div>
            <Spinner />
            <p className="h4 font-regular text-center">Espere un momento por favor...</p>
          </div>
        ) : (
          <>
            <CRow className="align-items-center justify-content-between px-5">
              <CCardTitle className="pt-3">DATOS PERSONALES Y PUNTAJES</CCardTitle>
            </CRow>
            <CCardBody className="px-5">
              <CRow className="justify-content-center mb-5">
                <CCol xs="12" lg="3" className="align-self-center p-0 text-center">
                  <Imagen src={photoURL} className="rounded-circle" width={180} />
                </CCol>
                <CCol xs="12" sm="6" lg="3" className="align-self-center">
                  <CardInfo obj={info} />
                </CCol>
                <CCol xs="12" sm="8" lg="3" className="text-white bg-gradient-primary p-4 rounded-lg">
                  <div className="d-flex flex-column h-100 justify-content-center">
                    <CRow className="justify-content-center">
                      <Imagen src={loadingImage} width="25%" />
                    </CRow>
                    <CRow className="justify-content-between">
                      <p className="m-2 font-bold">Casos abiertos:</p>
                      <p className="m-2 font-regular h5">{records.casesOpened}</p>
                    </CRow>
                    <CRow className="justify-content-between">
                      <p className="m-2 font-bold">Casos cerrados:</p>
                      <p className="m-2 font-regular h5">{records.casesClosed}</p>
                    </CRow>
                    <CRow className="justify-content-between">
                      <p className="m-2 font-bold">Casos abandonados:</p>
                      <p className="m-2 font-regular h5">{records.casesAbandoned}</p>
                    </CRow>
                    <CRow className="justify-content-between text-blue">
                      <p className="m-2 font-bold h5">Total de casos:</p>
                      <p className="m-2 font-regular h4">{records.casesTotal}</p>
                    </CRow>
                  </div>
                </CCol>
                <CCol xs="12" sm="6" lg="3" className="text-center">
                  <Label text="CALIDAD DE ATENCIÓN" className="h5 font-regular text-purple mb-5" />
                  <h4 className="text-warning text-center p-2 bg-dark rounded-lg">EVALUACIÓN: 100%</h4>
                  <Label text="NIVEL" className="h3 font-semi-bold text-purple mt-3" />
                  <p className="h4 font-semi-bold text-primary">EXCELENTE</p>
                </CCol>
              </CRow>
              <TableSearch
                onlyShow
                details={details}
                elements={userData}
                fields={fieldsAtentions}
                toggleModal={toggleModal}
                onPaginationChange={onPaginationChange}
                onTableFilterChange={onChangeInputs}
                onReadySearches={onReadySearches}
                toggleDetails={toggleDetails}
              />
            </CCardBody>
          </>
        )}
      </StyledCard>
      <ModalDigitizer
        title="Confirmar"
        show={modal.showModalSendEvaluation}
        toggleModal={toggleModal}
        sizeModal="sm"
        textBtnLeft="CANCELAR"
        textBtnRight="ENVIAR"
        content={messageModalSendEvaluation()}
      />
      <Toast show={toast.show} title={toast.title} className={toast.className} textContent={toast.textContent} autohide={6000} />
      <ModalResetToken show={modal.token} spinner={modal.spinner} setModal={setModal} toggleModal={toggleModalToken} />
    </CContainer>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transation: all 0.5s ease;
`;

const StyledCardHeader = styled(CCardHeader)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transation: all 0.5s ease;
`;

export default EvaluationUser;
