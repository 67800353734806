import React from "react";
import { CWidgetDropdown, CDropdownToggle, CDropdown, CDropdownMenu, CDropdownItem } from "@coreui/react";
import ChartGeneric from "../ChartGeneric";
import { Icon, Spinner } from "..";

const WidgetDropdown = ({
  color = "gradient-primary",
  loading,
  type,
  dataPoints,
  label,
  text,
  labels,
  title,
  value,
  valueOpened,
  valueClosed,
  onClickDropdownItem,
  footerText,
}) => {
  return (
    <CWidgetDropdown
      className={"pb-3"}
      color={color}
      text={text}
      header={title}
      footerSlot={
        <>
          {loading ? (
            <Spinner />
          ) : (
            <div className="position-relative text-center">
              <div className="d-flex flex-row align-items-center">
                <div className="chart-wrapper w-75 px-3 py-2">
                  <ChartGeneric
                    style={{ height: 240 }}
                    pointed
                    type={type}
                    dataPoints={dataPoints}
                    label={label}
                    labels={labels}
                    nTotal={value || valueOpened + valueClosed}
                  />
                </div>
                <div className="w-25">
                  {valueOpened >= 0 && valueClosed >= 0 ? (
                    <>
                      <h2 className="m-0 p-0">{valueOpened}</h2>
                      <Icon name="folderOpen" size="xl" />
                      <h2 className="m-0 p-0 mt-2">{valueClosed}</h2>
                      <Icon name="folder" size="xl" />
                    </>
                  ) : (
                    <>
                      <h2>{value}</h2>
                      <h4>Total</h4>
                    </>
                  )}
                </div>
              </div>
              <div className="text-left px-3">
                <small>{footerText}</small>
              </div>
            </div>
          )}
        </>
      }
    >
      <CDropdown>
        <CDropdownToggle color="transparent">
          <Icon name="calendarTimes" size="2x" />
        </CDropdownToggle>
        <CDropdownMenu className="p-0 dropdown-scroll" placement="bottom-end">
          <CDropdownItem onClick={onClickDropdownItem}>2023</CDropdownItem>
          <CDropdownItem onClick={onClickDropdownItem}>2022</CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </CWidgetDropdown>
  );
};

export default WidgetDropdown;
