import React from "react";
import { CContainer, CCol, CRow } from "@coreui/react";
import { Input, Dropdown, Label, TextArea, Icon, Spinner } from "../../atoms";

const VariableRegisterForm = ({
  paraments,
  categoryName,
  loading,
  onChangeInputs,
  dependency,
  dependencyItems,
}) => {
  const { name, description } = paraments;
  return (
    <CContainer fluid>
      {loading ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">
            Espere un momento por favor...
          </p>
        </div>
      ) : (
        <>
          <h5 className="font-bold">
            Crea o edita un nueva variable en la categoría de {categoryName}.
          </h5>
          <p className="font-italic mt-3">
            <b>Advertencia:</b> Tenga en concideración que la variable no se
            debe eliminar por temas de historial, a menos que la variable nueva
            no se haya utilizado por primera vez.
          </p>
          <div className="d-flex flex-row justify-content-end">
            <Label
              className="font-bold text-danger"
              text="Campo requerido"
              isRequeried
            />
            <Icon name="handPointLeft" className="ml-1" size="1x" />
          </div>
          <CRow>
            <CCol>
              <Label text="Nombre de variable:" isRequeried />
              <Input
                type="text"
                classNameInput={name ? "border-success" : "border-danger"}
                value={name}
                onChange={(e) => onChangeInputs(e, "name", "input")}
              />
            </CCol>
            {dependency && (
              <CCol>
                <Label text="Seleccione:" isRequeried className="mr-1" />
                <Dropdown
                  data={dependencyItems}
                  placeholder={paraments.dependency.label}
                  onClick={(e) => onChangeInputs(e, "dependency", "dropdown")}
                  color={
                    paraments.dependency.value === -1 ? "secondary" : "purple"
                  }
                />
              </CCol>
            )}
          </CRow>
          <CRow>
            <CCol>
              <Label text="Descripción:" className="mt-3" />
              <TextArea
                maxLength={800}
                height={200}
                value={description}
                onChange={(e) => onChangeInputs(e, "description", "text-area")}
              />
            </CCol>
          </CRow>
        </>
      )}
    </CContainer>
  );
};

export default VariableRegisterForm;
