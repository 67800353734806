import React from "react";
import { CCollapse, CCardBody, CCard, CRow, CCol } from "@coreui/react";
import { Label, Imagen, Badge } from "..";
import { useSelector } from "react-redux";
import { decodeFormatBase64 } from "../../../commons/consts/images";

const Collapse = ({ item, index, details }) => {
  const stateGlobal = useSelector((state) => ({
    violenceTypes: state.violenceTypes,
    attentionMode: state.attentionMode,
    affectationLevel: state.affectationLevel,
    displayName: state.currentUser.displayName,
    photoUser: state.currentUser.photoURL,
    victimRelationship: state.victimRelationship,
    ethnicities: state.ethnicities,
    maritalStatus: state.maritalStatus,
    educationLevel: state.educationLevel,
    dateCurrent: state.dateCurrent,
    sexualOrientations: state.sexualOrientations,
    disabilityTypes: state.disabilityTypes,
    aggressorTypes: state.aggressorTypes,
    familyRights: state.familyRights,
    crimes: state.crimes,
    support: state.support,
    caseReferred: state.caseReferred,
    virtualOptions: state.virtualOptions,
    theme: state.theme,
    users: state.users,
  }));
  const {
    names,
    lastnames,
    age,
    dateAreaLegal,
    hourAreaLegal,
    identity,
    tutor,
    ethnicities,
    maritalStatus,
    occupation,
    educationLevel,
    address,
    numDaughters,
    numSons,
    agesChildren,
    phoneNumber,
    phoneHouse,
    callEmergency,
    sexualOrientations,
    disability,
    disabilityType,
    aggressorNames,
    aggressorLastnames,
    aggressorIdentity,
    aggressorAge,
    aggressorEthnicity,
    aggressorEducationLevel,
    aggressorOccupation,
    aggressorAddress,
    aggressorPhoneNumber,
    aggressorPhoneHouse,
    aggressorPhoneWork,
    aggressorPlaceWork,
    aggressorType,
    aggressorNumber,
    aggressorViolenceTypes,
    familyRights,
    otherFamilyRights,
    crimes,
    otherCrime,
    description,
    support,
    usersAccess,
    otherSupport,
    caseReferred,
    caseFrom,
    otherReferredOgWomen,
    otherReferred,
    observationsLegal,
    recommendationsLegal,
    caseActions,
    virtualOptionsLegal,
    attentionModeLegal,
    digitalFootprint,
    attentionBy,
  } = item.generalInfo;
  return (
    <CCard className="border-0">
      <CCollapse show={details.includes(index)} className={`${stateGlobal.theme === "dark" ? "bg-dark text-white" : "bg-light text-dark"}`}>
        <CCardBody>
          <CRow>
            <CCol xs="12" lg="6">
              <h4 className="mt-3">1. Datos personales</h4>
              <Label text="1.1 Fecha y hora" className="mt-2 h6 text-blue" />
              <p>
                {dateAreaLegal} {hourAreaLegal}
              </p>
              <Label text="1.2 Nombres y apellidos" className="mt-2 h6 text-blue" />
              <p>
                {names} {lastnames}
              </p>
              <Label text="1.3 Identidad" className="mt-2 h6 text-blue" />
              <p>{identity}</p>
              <Label text="1.4 Edad" className="mt-2 h6 text-blue" />
              <p>{age}</p>
              {age <= 18 && (
                <div className={`${stateGlobal.theme === "dark" ? "bg-info text-white" : "bg-light text-dark"} mt-2rounded-lg px-4 pb-4`}>
                  <p className="h5">Menor de edad</p>
                  <Label text="1.5.1 Nombres del tutor(a)" className="mt-2 h6 text-blue" />
                  <p>{tutor.names}</p>
                  <Label text="1.5.2 Apellidos del tutor(a)" className="mt-2 h6 text-blue" />
                  <p>{tutor.lastnames}</p>
                  <Label text="1.5.3 Identidad del tutor(a)" className="mt-2 h6 text-blue" />
                  <p>{tutor.identity}</p>
                  <Label text="1.5.4 Teléfono celular de tutor(a)" className="mt-2 h6 text-blue" />
                  <p>{tutor.numberPhone}</p>
                  <Label text="1.5.5 Parentesco con tutor(a)" className="mt-2 h6 text-blue" />
                  <p>{tutor.victimRelationship.label}</p>
                </div>
              )}
              <Label text="1.5 Grupo étnico al que pertenece" className="mt-2 h6 text-blue" />
              <p>{ethnicities.label}</p>
              <Label text="1.6 Nivel educativo" className="mt-2 h6 text-blue" />
              <p>{educationLevel.label}</p>
              <Label text="1.7 Profesión u oficio (víctima)" className="mt-2 h6 text-blue" />
              <p>{occupation.label}</p>
              <Label text="1.8 Dirección / Residencia" className="mt-2 h6 text-blue" />
              <p>{address}</p>
              <Label text="1.9 Estado civil" className="mt-2 h6 text-blue" />
              <p>{maritalStatus.label}</p>
              <Label text="1.10 Hijas e hijos" className="mt-2 h6 text-blue" />
              <p>Hijas: {numDaughters ? numDaughters : "No registrado"}</p>
              <p>Hijos: {numSons ? numSons : "No registrado"}</p>
              <p>Edades: {agesChildren ? agesChildren : "No registrado"}</p>
              <Label text="1.11 Teléfonos" className="mt-2 h6 text-blue" />
              <p>
                Celular: {phoneNumber ? phoneNumber : "No registrado"}, Casa: {phoneHouse ? phoneHouse : "No registrado"}
              </p>
              <Label text="1.12 En caso de emergencia llamar a:" className="mt-2 h6 text-blue" />
              <div className={`${stateGlobal.theme === "dark" ? "bg-info text-white" : "bg-light text-dark"} rounded-lg p-3`}>
                <p>Nombre: {callEmergency.nameComplete}</p>
                <p>Celular: {callEmergency.phoneNumber ? callEmergency.phoneNumber : "No registrado"}</p>
                <p>Trabajo: {callEmergency.phoneWork ? callEmergency.phoneWork : "No registrado"}</p>
                <p>Dirección: {callEmergency.address ? callEmergency.address : "No registrado"}</p>
              </div>
              <Label text="1.13 Orientación sexual" className="mt-2 h6 text-blue" />
              <p>{sexualOrientations.label}</p>
              <Label text="1.14 Discapacidad" className="mt-2 h6 text-blue" />
              <p>
                {disability.label},{" "}
                {disabilityType.map((disability, index) => {
                  return index === disabilityType.length - 1 ? disability.label : disability.label + ", ";
                })}
              </p>
              <Label text="4. Descripción del problema" className="h6 mt-2 text-blue" />
              <p>{description}</p>
              <Label text="5. Observaciones" className="h6 mt-2 text-blue" />
              <p>{observationsLegal}</p>
              <Label text="6. Acciones a realizar" className="h6 mt-2 text-blue" />
              <p>{caseActions}</p>
              <Label text="7. Caso remitido por:" className="h6 mt-2 text-blue" />
              <p>{caseFrom}</p>
              <Label text="8. Caso remitido a:" className="h6 text-blue" />
              <p>
                {caseReferred.map((element, index) => {
                  return index === caseReferred.length - 1 ? element.label : element.label + ", ";
                })}
              </p>
              <p>Organización de mujeres u otras: {otherReferredOgWomen ? otherReferredOgWomen : "NO APLICA"}</p>
              <p>Instituciones estatales o dependencias: {otherReferred ? otherReferred : "NO APLICA"}</p>
              <Label text="9. Recomendaciones" className="h6 mt-2 text-blue" />
              <p>{recommendationsLegal}</p>
            </CCol>
            <CCol xs="12" lg="6" className="py-3">
              <h4>2. Datos del denunciado</h4>
              <Label text="2.1 Nombres y apellidos" className="mt-2 h6 text-blue" />
              <p>
                {aggressorNames} {aggressorLastnames}
              </p>
              <Label text="2.2 Identidad" className="mt-2 h6 text-blue" />
              <p>{aggressorIdentity ? aggressorIdentity : "No registrado"}</p>
              <Label text="2.3 Edad" className="mt-2 h6 text-blue" />
              <p>{aggressorAge}</p>
              <Label text="2.4 Grupo etnico al que pertenece" className="mt-2 h6 text-blue" />
              <p>{aggressorEthnicity.label}</p>
              <Label text="2.5 Nivel educativo" className="mt-2 h6 text-blue" />
              <p>{aggressorEducationLevel.label}</p>
              <Label text="2.6 Profesión u oficio (agresor)" className="mt-2 h6 text-blue" />
              <p>{aggressorOccupation.label}</p>
              <Label text="2.7 Dirección / Residencia" className="mt-2 h6 text-blue" />
              <p>{aggressorAddress}</p>
              <Label text="2.8 Teléfonos" className="mt-2 h6 text-blue" />
              <p>
                Celular: {aggressorPhoneNumber ? aggressorPhoneNumber : "No registrado"}, Casa:{" "}
                {aggressorPhoneHouse ? aggressorPhoneHouse : "No registrado"}, Trabajo:{" "}
                {aggressorPhoneWork ? aggressorPhoneWork : "No registrado"}
              </p>
              <Label text="2.9 Lugar y Dirección de trabajo" className="mt-2 h6 text-blue" />
              <p>{aggressorPlaceWork ? aggressorPlaceWork : "No registrado"}</p>
              <Label text="2.10 Número de agresores" className="mt-2 h6 text-blue" />
              <p>{aggressorNumber}</p>
              <Label text="2.11 Parentesco(s) con denunciado" className="mt-2 h6 text-blue" />
              <p>{aggressorType}</p>
              <h4 className="mt-2 h5">3. Delitos o faltas denunciadas.</h4>
              <Label text="3.1 Tipos de violencias" className="mt-2 h6 text-blue" />
              <div className={`p-1 rounded border ${stateGlobal.theme === "dark" ? "bg-dark" : "bg-light"}`}>
                {aggressorViolenceTypes.map((item, index) => (
                  <Badge
                    key={"Violencias" + index}
                    field={"Violencias"}
                    label={item.label}
                    value={item.value}
                    dependency={item.dependency}
                    className="m-1 p-2 text-white"
                    onClick={() => {}}
                    icon={null}
                  />
                ))}
              </div>
              <Label text="3.2 Derechos de familia" className="mt-2 h6 text-blue" />
              <p>{familyRights}</p>
              <p>{otherFamilyRights}</p>
              <Label text="3.3 Delitos y/o faltas identificadas" className="mt-2 h6 text-blue" />
              <p>{crimes}</p>
              <p>{otherCrime}</p>
              <Label text="10. Apoyo brindado" className="h6 mt-2 text-blue" />
              <p>{support}</p>
              <p>{otherSupport}</p>
              <Label text="11. Acceso a colaboradoras" className="h6 mt-2 text-blue" />
              <p>{usersAccess}</p>
              <Label text="12. Modalidad de atención" className="h6 mt-2 text-blue" />
              <p>{attentionModeLegal.label}</p>
              <p>{virtualOptionsLegal}</p>
              <p className="h5 mt-2">13. Firma digital</p>
              <Imagen src={decodeFormatBase64 + digitalFootprint} width={200} className="bg-light p-3 rounded-lg mt-3" />
              <p className="h5 mt-2">Atendida por: {attentionBy || stateGlobal.displayName.username}</p>
            </CCol>
          </CRow>
        </CCardBody>
      </CCollapse>
    </CCard>
  );
};

export default Collapse;
