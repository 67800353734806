import { colors } from "../consts/colors";

// Variables comunes
const styleBordersLeft = {
  width: "10%",
  borderWidth: 0,
  borderTopLeftRadius: 12,
};

const styleBordersRight = {
  width: "10%",
  borderWidth: 0,
  borderTopRightRadius: 12,
};

// Varibles globales
const fields = (index) => {
  return [
    {
      key: index,
      label: "ID",
      _style: {
        ...styleBordersLeft,
      },
      filter: false,
    },
    { key: "name", label: "NOMBRE", _style: { width: "40%" } },
    { key: "registered", label: "REGISTRADO", _style: { width: "40%" } },
    {
      key: "options",
      label: "ESTATUS",
      _style: {
        ...styleBordersRight,
      },
      sorter: false,
      filter: false,
    },
  ];
};

const fieldsWidthDependency = (index) => {
  return [
    {
      key: index,
      label: "ID",
      _style: {
        ...styleBordersLeft,
      },
      filter: false,
    },
    { key: "name", label: "NOMBRE", _style: { width: "30%" } },
    { key: "registered", label: "REGISTRADO", _style: { width: "30%" } },
    { key: "dependencyName", label: "DEPENDENCIA", _style: { width: "30%" } },
    {
      key: "options",
      label: "ESTATUS",
      _style: {
        ...styleBordersRight,
      },
      sorter: false,
      filter: false,
    },
  ];
};

const fieldsRegions = [
  {
    key: "id_region",
    label: "ID",
    _style: { width: "10%" },
    filter: false,
  },
  { key: "name", label: "NOMBRE", _style: { width: "30%" } },
  { key: "state", label: "DEPARTAMENTOS", _style: { width: "30%" } },
  { key: "created", label: "REGISTRADO", _style: { width: "30%" } },
  {
    key: "options",
    label: "Opciones",
    _style: { width: "1%" },
    sorter: false,
    filter: false,
  },
];

const fieldsVillagesAndSurbubes = (keyId) => {
  return [
    {
      key: keyId,
      label: "ID",
      _style: { width: "10%" },
      filter: false,
    },
    { key: "name", label: "NOMBRE", _style: { width: "30%" } },
    { key: "region", label: "MUNICIPIOS", _style: { width: "30%" } },
    { key: "created", label: "REGISTRADO", _style: { width: "30%" } },
    {
      key: "options",
      label: "Opciones",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];
};

const fieldsViolences = [
  {
    key: "id_violence",
    label: "ID",
    _style: { width: "10%" },
    filter: false,
  },
  { key: "name", label: "NOMBRE", _style: { width: "30%" } },
  {
    key: "violence_type",
    label: "Tipo de violencia",
    _style: { width: "30%" },
  },
  { key: "created", label: "REGISTRADO", _style: { width: "30%" } },
  {
    key: "options",
    label: "Opciones",
    _style: { width: "1%" },
    sorter: false,
    filter: false,
  },
];

const fieldsMedia = [
  {
    key: "id_media",
    label: "ID",
    _style: { width: "10%" },
    filter: false,
  },
  { key: "name", label: "NOMBRE", _style: { width: "30%" } },
  { key: "media_type", label: "TIPO DE MEDIO", _style: { width: "30%" } },
  { key: "created", label: "REGISTRADO", _style: { width: "30%" } },
  {
    key: "options",
    label: "Opciones",
    _style: { width: "1%" },
    sorter: false,
    filter: false,
  },
];

const fieldsUsers = [
  {
    key: "identity",
    label: "ID",
    _style: {
      ...styleBordersLeft,
    },
  },
  {
    key: "username",
    label: "USUARIA",
    _style: { width: "30%" },
  },
  {
    key: "registered",
    label: "REGISTRADA",
    _style: { width: "20%" },
  },
  {
    key: "rol",
    label: "ROL",
    _style: { width: "30%" },
  },
  {
    key: "options",
    label: "OPCIONES",
    _style: {
      ...styleBordersRight,
    },
    sorter: false,
  },
];

const fieldsAtentions = [
  {
    key: "identity",
    label: "ID",
    _style: {
      ...styleBordersLeft,
      width: "10%",
    },
    filter: false,
  },
  {
    key: "names",
    label: "NOMBRES",
    _style: { width: "15%" },
    filter: false,
  },
  {
    key: "lastnames",
    label: "APELLIDOS",
    _style: { width: "15%" },
    filter: false,
  },
  {
    key: "age",
    label: "EDAD",
    _style: { width: "5%" },
    filter: false,
  },
  {
    key: "registered",
    label: "FECHA",
    _style: { width: "10%" },
    filter: false,
  },
  {
    key: "attentionBy",
    label: "ATENDIDA POR",
    _style: {
      width: "15%",
    },
    sorter: false,
    filter: false,
  },
  {
    key: "statusCase",
    label: "ESTADO",
    _style: {
      width: "6%",
    },
    sorter: false,
    filter: false,
  },
  {
    key: "options",
    label: "OPCIONES",
    _style: { ...styleBordersRight },
    filter: false,
  },
];

const fieldsVictims = (withFilters) => {
  return [
    {
      key: "index",
      label: "ÍNDICE",
      _style: {
        ...styleBordersLeft,
        backgroundColor: colors.headerTableVictim,
      },
      sorter: false,
      filter: false,
    },
    {
      key: "firstname",
      label: "NOMBRE",
      _style: {
        width: "12%",
        backgroundColor: colors.headerTableVictim,
      },
      sorter: withFilters,
      filter: withFilters,
    },
    {
      key: "lastname",
      label: "APELLIDO",
      _style: {
        width: "12%",
        backgroundColor: colors.headerTableVictim,
      },
      sorter: withFilters,
      filter: withFilters,
    },
    {
      key: "date",
      label: "FECHA",
      _style: {
        width: "12%",
        backgroundColor: colors.headerTableVictim,
      },
      sorter: withFilters,
      filter: withFilters,
    },
    {
      key: "weaponType",
      label: "ARMA",
      _style: {
        width: "12%",
        backgroundColor: colors.headerTableVictim,
      },
      sorter: withFilters,
      filter: withFilters,
    },
    {
      key: "stateId",
      _style: {
        width: "12%",
        backgroundColor: colors.headerTableVictim,
      },
      label: "DEPARTAMENTO",
      sorter: withFilters,
      filter: withFilters,
    },
    {
      key: "regionId",
      _style: {
        width: "12%",
        backgroundColor: colors.headerTableVictim,
      },
      label: "MUNICIPIO",
      sorter: withFilters,
      filter: withFilters,
    },
    {
      key: "options",
      label: "ACCIONES",
      _style: {
        ...styleBordersRight,
        backgroundColor: colors.headerTableVictim,
      },
      sorter: false,
      filter: false,
    },
  ];
};

const fieldsMultimedia = [
  {
    key: "name",
    label: "NOMBRE",
    _style: { width: "30%" },
    sorter: false,
    filter: false,
  },
  {
    key: "registered",
    label: "FECHA DE CARGA",
    _style: { width: "20%" },
    sorter: false,
    filter: false,
  },
  {
    key: "type",
    label: "TIPO",
    _style: { width: "20%" },
    sorter: false,
    filter: false,
  },
  {
    key: "show_details",
    label: "",
    _style: { width: "1%" },
    sorter: false,
    filter: false,
  },
];

export {
  fields,
  fieldsWidthDependency,
  fieldsUsers,
  fieldsAtentions,
  fieldsVillagesAndSurbubes,
  fieldsViolences,
  fieldsVictims,
  fieldsRegions,
  fieldsMedia,
  fieldsMultimedia,
};
