import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import { CardLogin, CardBanner } from "../../organims";
import { Redirect } from "react-router-dom";
import { urlsAdmin, urlsDigitizer } from "../../../commons/consts/urls";
import { useSelector } from "react-redux";

const Login = () => {
  const isSessionActived = sessionStorage.getItem("isLogged");
  const rolId = useSelector((state) => state.currentUser.displayName.id_rol.value);
  return (
    <>
      {!isSessionActived ? (
        <div className="c-app c-default-layout flex-row align-items-center bg-gradient-primary">
          <CContainer className="bg-none">
            <CRow className="p-0 m-3">
              <CCol xs="12" lg="6" className="text-primary py-3">
                <CardLogin />
              </CCol>
              <CCol xs="12" lg="6" className="d-none d-md-block text-white py-3">
                <CardBanner />
              </CCol>
            </CRow>
          </CContainer>
        </div>
      ) : (
        //className="d-none d-md-block text-white"
        <Redirect
          to={rolId === "KVr8DMQv2hb0r8lhrShP" ? urlsAdmin.dashboard : rolId === "zLLLhDnXmTG1fOov0oy0" ? urlsDigitizer.dashboard : "/"}
        />
      )}
    </>
  );
};

export default Login;
