import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { urlInformedConsentMayor, urlInformedConsentMenor } from "../consts/urls";
import { getMonthNameComplete } from "../helpers/dates";

export const getCodeImagenBase64 = (imagenCode) => {
  return imagenCode.substring(22, imagenCode.length);
};

export const getListGroup = (array, target) => {
  const temp = array;
  temp.push({
    value: target.getAttribute("value"),
    label: target.text,
  });
  return temp;
};

export const getCheckBoxes = (array, target) => {
  const temp = array;
  const label = target.getAttribute("id").split("_")[1];
  if (!temp.find((item) => item.label === label)) {
    if (target.getAttribute("dependency")) {
      temp.push({
        label,
        value: target.getAttribute("value"),
        dependency: JSON.parse(target.getAttribute("dependency")),
      });
    } else {
      temp.push({
        label,
        value: target.getAttribute("value"),
      });
    }
  } else {
    const index = temp.findIndex((item) => item.label === label);
    temp.splice(index, 1);
  }
  return temp;
};

export const getBadge = (array, target) => {
  const temp = array;
  const value = target.getAttribute("value");
  const index = temp.findIndex((item) => item.value === value);
  temp.splice(index, 1);
  return temp;
};

export const getMultimedia = (array, item) => {
  if (array.length) {
    let newArray = array;
    const index = newArray.findIndex((field) => field.name === item.name);
    if (index !== -1) {
      newArray.splice(index, 1, item);
    } else {
      newArray.push(item);
    }
    return newArray;
  }
  return [item];
};

export const getListGroupCustomArray = (array, index) => {
  const tempArray = array;
  tempArray.splice(index, 1);
  for (var i = 0; i < tempArray.length; i++) {
    tempArray[i].value = i;
  }
  return tempArray;
};

export const modifiedInformedConsent = async (options) => {
  try {
    const { age, names, lastnames, date, tutor, identity, attentionBy, address } = options;
    const url = age <= 18 ? urlInformedConsentMenor : urlInformedConsentMayor;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    // Get the width and height of the first page
    const { width, height } = firstPage.getSize();
    if (age <= 18) {
      firstPage.drawText(`${tutor.names} ${tutor.lastnames}`, {
        x: width * 0.17,
        y: height * 0.753,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(`${names} ${lastnames}`, {
        x: width * 0.2,
        y: height * 0.716,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(tutor.identity, {
        x: width * 0.235,
        y: height * 0.734,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(tutor.victimRelationship.label.toLowerCase(), {
        x: width * 0.52,
        y: height * 0.734,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(identity, {
        x: width * 0.45,
        y: height * 0.698,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(attentionBy.username, {
        x: width * 0.13,
        y: height * 0.663,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(date.substring(8, 10), {
        x: width * 0.51,
        y: height * 0.205,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(date.substring(0, 4), {
        x: width * 0.84,
        y: height * 0.205,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(getMonthNameComplete(date), {
        x: width * 0.68,
        y: height * 0.205,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(attentionBy.identity, {
        x: width * 0.65,
        y: height * 0.11,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(tutor.identity, {
        x: width * 0.216,
        y: height * 0.11,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
    } else {
      firstPage.drawText(`${names} ${lastnames}`, {
        x: width * 0.157,
        y: height * 0.739,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(identity, {
        x: width * 0.145,
        y: height * 0.701,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(attentionBy.username, {
        x: width * 0.125,
        y: height * 0.643,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(date.substring(8, 10), {
        x: width * 0.68,
        y: height * 0.202,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(date.substring(0, 4), {
        x: width * 0.338,
        y: height * 0.185,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(getMonthNameComplete(date), {
        x: width * 0.13,
        y: height * 0.185,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(attentionBy.identity, {
        x: width * 0.65,
        y: height * 0.07,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(identity, {
        x: width * 0.2,
        y: height * 0.07,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(address, {
        x: width * 0.12,
        y: height * 0.72,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    return blob;
  } catch (error) {
    alert(error);
  }
};

export const getSpinnerArg = (arg) =>
  arg === "registerGeneralTab"
    ? "spinnerTabGeneral"
    : arg === "registerFollow"
    ? "spinnerFollow"
    : arg === "registerAccompaniment"
    ? "spinnerAccompaniments"
    : "spinnerQuotesFollow";
