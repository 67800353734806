import React, { useState } from "react";
import { CInputFile, CLabel, CProgress } from "@coreui/react";
import { Button } from "..";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getStorageRefPath } from "../../../config/config";
import { errorsUpLoadStorage } from "../../../commons/helpers/errors";
import { imagesResizer } from "../../../commons/consts/images";
import { validationTypeFile } from "../../../commons/helpers/validations";

const InputFile = ({ uid, field, placeholder, onChange, accept, dataArg }) => {
  const [load, setLoad] = useState({
    show: false,
    value: 0,
    message: "",
    disabledButtons: false,
    uploadTask: null,
    iconName: "pause",
    isPaused: false,
  });
  // Subida de archivos
  const fileState = (snapshot) => {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    let message = "";
    switch (snapshot.state) {
      case "paused":
        message = "Subida pausada";
        break;
      case "running":
        message = "Cargando, por favor espere...";
        break;
      case "canceled":
        message = "Subida cancelada.";
        break;
      default:
        message = "El archivo existe o se subio.";
        break;
    }
    setLoad((prevState) => ({
      ...prevState,
      message,
      value: Math.ceil(progress),
    }));
  };

  const fileProcessError = (error) => {
    let messageError = errorsUpLoadStorage(error.code);
    setLoad((prevState) => ({
      ...prevState,
      message: messageError,
      disabledButtons: true,
    }));
  };

  const fileResponse = (event, uploadTask) => {
    // Upload completed successfully, now we can get the download URL
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      if (downloadURL) {
        onChange(event, downloadURL, "input-files", dataArg);
        setLoad((prevState) => ({
          ...prevState,
          message: "Completado",
          disabledButtons: true,
        }));
        setTimeout(
          () => setLoad((prevState) => ({ ...prevState, show: false })),
          2000,
        );
      }
    });
  };
  const upLoadFile = async (event, uid) => {
    try {
      if (event.target.files.length > 0) {
        let file;
        switch (event.target.files[0].type) {
          case "image/jpeg":
          case "image/gif":
          case "image/png":
          case "image/web":
            file = await imagesResizer(event.target.files[0]);
            break;
          default:
            file = event.target.files[0];
            break;
        }
        const validation = validationTypeFile(event.target.files[0].type);
        if (validation.valid) {
          if (file.size <= 25000000) {
            const newE = { target: { files: [file] } };
            onChange(newE, field, "input-files");
            const firestorePath =
              field === "filePath"
                ? `victims/${uid}/multimedias/${file.name}`
                : `victims/${uid}/therapeutic_plan/${file.name}`;
            const storageRef = getStorageRefPath(firestorePath);
            const uploadTask = uploadBytesResumable(storageRef, file);
            setLoad((prevState) => ({
              ...prevState,
              show: true,
              uploadTask,
              disabledButtons: false,
            }));
            uploadTask.on("state_changed", fileState, fileProcessError, () =>
              fileResponse(newE, uploadTask),
            );
          } else {
            setLoad((prevState) => ({
              ...prevState,
              show: true,
              disabledButtons: true,
              message:
                "*** Archivo pesado excede los 25mb, favor reducir el tamaño del archivo. ***",
            }));
          }
        } else {
          setLoad((prevState) => ({
            ...prevState,
            show: true,
            disabledButtons: true,
            message: validation.message,
          }));
        }
      }
    } catch (error) {
      setLoad((prevState) => ({ ...prevState, message: error.message }));
    }
  };

  const onHandlePauseResumen = () => {
    if (!load.isPaused) {
      load.uploadTask.pause();
      setLoad((prevState) => ({
        ...prevState,
        iconName: "play",
        isPaused: true,
      }));
    } else {
      load.uploadTask.resume();
      setLoad((prevState) => ({
        ...prevState,
        iconName: "pause",
        isPaused: false,
      }));
    }
  };

  const onHandleCancel = () => {
    load.uploadTask.cancel();
    setLoad((prevState) => ({
      ...prevState,
      show: false,
      disabledButtons: false,
      message: "Subida cancelada.",
      value: 0,
    }));
  };

  return (
    <div className="position-relative my-2">
      <CInputFile
        id={field}
        onChange={(e) => {
          upLoadFile(e, uid);
        }}
        custom
        accept={accept}
      />
      <CLabel htmlFor={field} variant="custom-file">
        {placeholder && (
          <>
            {placeholder.length > 35
              ? `${placeholder.substring(0, 38)}..${placeholder.substring(
                  placeholder.length - 4,
                  placeholder.length,
                )}`
              : placeholder}
          </>
        )}
      </CLabel>
      <CLabel className="mt-2">{load.message}</CLabel>
      {load.show && (
        <div className="row px-3">
          <CProgress
            animated
            striped
            showValue
            showPercentage
            color="success"
            value={load.value}
            className="bg-white w-100"
          />
          <div className="w-100 text-right mt-2">
            <Button
              icon={load.iconName}
              color="blue"
              size="md"
              sizeIcon="lg"
              className="mx-1"
              disabled={load.disabledButtons}
              onClick={onHandlePauseResumen}
            />
            <Button
              icon="cancel"
              disabled={load.disabledButtons}
              color="danger"
              size="md"
              sizeIcon="lg"
              onClick={onHandleCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InputFile;
