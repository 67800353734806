import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { CContainer, CFade } from "@coreui/react";
import { Spinner } from "../../atoms";
// routes config
import routes from "../../../routes";
import { useSelector } from "react-redux";

const loading = (
  <div className="pt-3 text-center">
    <Spinner />
  </div>
);

const Container = () => {
  const rolId = useSelector(
    (state) => state.currentUser.displayName.id_rol.value,
  );
  const theme = useSelector((state) => state.theme);
  const routesArray = routes(rolId);

  return (
    <main
      className={`c-main ${theme === "light" ? "bg-imagen" : "bg-imagendark"}`}
    >
      <CContainer fluid>
        <Suspense fallback={loading}>
          <Switch>
            {routesArray.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <CFade>
                        <route.component {...props} />
                      </CFade>
                    )}
                  />
                )
              );
            })}
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  );
};

export default Container;
