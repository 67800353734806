import React from "react";
import { CCard, CRow, CCol } from "@coreui/react";
import { colorsBadged } from "../../../commons/consts/colors";
import { ChartBarCard, ChartPieCard, ChartDoughnutCard } from "../../molecules";
import { footerFontChartsText } from "../../../commons/static/tabs";
import { elementsTabReports } from "../../../commons/static/tabs";
import styled from "styled-components";

const ChartsLegalLayout = ({ tabName, data, spinner }) => {
  const { crimes, familyRights, legalModules, support, violenceTypes } = data;
  return (
    <>
      {tabName === elementsTabReports[1].name && (
        <StyledCard className="p-4 border-0">
          <CRow>
            <CCol xs="12">
              <ChartPieCard
                titleLitte="GRÁFICO 1"
                title="TOTAL ATENCIÓN DE CASOS DEL ÁREA LEGAL"
                dataPoints={legalModules && legalModules.dataPoints}
                labels={legalModules && legalModules.labels}
                nTotal={legalModules && legalModules.total}
                footerText={footerFontChartsText}
                loading={spinner}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12">
              <ChartPieCard
                titleLitte="GRÁFICO 2"
                title="DELITOS Y FALTAS DENUNCIADAS EN LA ORGANIZACIÓN"
                dataPoints={crimes && crimes.dataPoints}
                labels={crimes && crimes.labels}
                nTotal={crimes && crimes.total}
                footerText={footerFontChartsText}
                loading={spinner}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12">
              <ChartDoughnutCard
                titleLitte="GRÁFICO 3"
                title="TOTAL DE CASOS DE DERECHOS DE FAMILIA"
                dataPoints={familyRights && familyRights.dataPoints}
                labels={familyRights && familyRights.labels}
                nTotal={familyRights && familyRights.total}
                footerText={footerFontChartsText}
                loading={spinner}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12">
              <ChartBarCard
                titleLitte="GRÁFICO 4"
                title="APOYO BRINDADO A MUJERES SOBREVIVIENTES DE VIOLENCIAS"
                dataPoints={support && support.dataPoints}
                label="APOYO BRINDADO"
                backgroundColor={support ? colorsBadged(2) : colorsBadged(10)}
                labels={support && support.labels}
                nTotal={support && support.total}
                footerText={footerFontChartsText}
                loading={spinner}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12">
              <ChartBarCard
                titleLitte="GRÁFICO 5"
                title="TIPOS DE VIOLENCIAS REGISTRADOS EN LOS CASOS ATENDIDOS"
                dataPoints={violenceTypes && violenceTypes.dataPoints}
                label="TIPOS DE VIOLENCIAS"
                backgroundColor={violenceTypes ? colorsBadged(9) : colorsBadged(10)}
                labels={violenceTypes && violenceTypes.labels}
                nTotal={violenceTypes && violenceTypes.total}
                footerText={footerFontChartsText}
                loading={spinner}
              />
            </CCol>
          </CRow>
        </StyledCard>
      )}
    </>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.cardBackground};
  transition: all 0.5s ease;
`;

export default ChartsLegalLayout;
