import React from "react";
import { urlsDigitizer, urlsAdmin } from "./commons/consts/urls";

// Admin
const AdminEvaluations = React.lazy(() =>
  import("./components/organims/AdminEvaluations"),
);
const AdminUsers = React.lazy(() => import("./components/organims/AdminUsers"));
const AdminLegal = React.lazy(() => import("./components/organims/AdminLegal"));
const AdminEmotional = React.lazy(() =>
  import("./components/organims/AdminEmotional"),
);
const AdminGlobals = React.lazy(() =>
  import("./components/organims/AdminGlobals"),
);

const EvaluationUser = React.lazy(() =>
  import("./components/organims/EvaluationUser"),
);

// Digitizer
const Dashboard = React.lazy(() => import("./components/organims/Dashboard"));
const Reports = React.lazy(() =>
  import("./components/organims/DigitizerReports"),
);
const Femicides = React.lazy(() => import("./components/organims/Femicides"));

const CareRecords = React.lazy(() =>
  import("./components/organims/CareRecords"),
);

const CareRecordForm = React.lazy(() =>
  import("./components/organims/CareRecordForm"),
);

const CareRecordSurvivor = React.lazy(() =>
  import("./components/organims/CareRecordForm"),
);

const routes = (rolId) => {
  return rolId === "KVr8DMQv2hb0r8lhrShP"
    ? [
        {
          path: urlsAdmin.dashboard,
          exact: true,
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: urlsAdmin.femicides,
          exact: true,
          name: "Femicides",
          component: Femicides,
        },
        {
          path: urlsAdmin.evaluations,
          exact: true,
          name: "AdminEvaluations",
          component: AdminEvaluations,
        },
        {
          path: urlsAdmin.users,
          exact: true,
          name: "AdminUsers",
          component: AdminUsers,
        },
        {
          path: urlsAdmin.care_records,
          exact: true,
          name: "CareRecords",
          component: CareRecords,
        },
        {
          path: urlsAdmin.care_records_form,
          exact: true,
          name: "CareRecordForm",
          component: CareRecordForm,
        },
        {
          path: urlsAdmin.care_records_survivor,
          exact: true,
          name: "CareRecordSurvivor",
          component: CareRecordSurvivor,
        },
        {
          path: urlsAdmin.area_emotional,
          exact: true,
          name: "Area emocional",
          component: AdminEmotional,
        },
        {
          path: urlsAdmin.area_legal,
          exact: true,
          name: "Area Legal",
          component: AdminLegal,
        },
        {
          path: urlsAdmin.globals,
          exact: true,
          name: "AdminGlobals",
          component: AdminGlobals,
        },
        {
          path: urlsAdmin.reports,
          exact: true,
          name: "Reports",
          component: Reports,
        },
        {
          path: urlsAdmin.evaluationUser,
          exact: true,
          name: "EvaluationUser",
          component: EvaluationUser,
        },
      ]
    : [
        {
          path: urlsDigitizer.dashboard,
          exact: true,
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: urlsDigitizer.care_records,
          exact: true,
          name: "CareRecords",
          component: CareRecords,
        },
        {
          path: urlsDigitizer.care_records_form,
          exact: true,
          name: "CareRecordForm",
          component: CareRecordForm,
        },
        {
          path: urlsDigitizer.care_records_survivor,
          exact: true,
          name: "CareRecordSurvivor",
          component: CareRecordSurvivor,
        },
        {
          path: urlsDigitizer.femicides,
          exact: true,
          name: "Femicides",
          component: Femicides,
        },
        {
          path: urlsDigitizer.reports,
          exact: true,
          name: "Reports",
          component: Reports,
        },
      ];
};

export default routes;
