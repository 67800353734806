import React from "react";
import { CModal, CModalHeader, CModalFooter, CModalBody, CCard } from "@coreui/react";
import { Button, Spinner } from "../../atoms";
import styled from "styled-components";

const ModalDigitizer = ({
  content,
  show,
  toggleModal,
  onConfirm,
  textBtnLeft = "Cancelar",
  textBtnRight = "Acción",
  title = "Title",
  sizeModal = "",
  spinner,
  classModalHeader = "h3",
  disableButtonConfirm,
}) => {
  return (
    <CModal show={show} onClose={toggleModal} size={sizeModal} className="bg-gradient-modal" closeOnBackdrop={false}>
      <StyledCard>
        <CModalHeader className={classModalHeader} closeButton>
          {title}
        </CModalHeader>
        <CModalBody className="align-self-center w-20">{spinner ? <Spinner /> : content}</CModalBody>
        <CModalFooter>
          <Button color="danger" text={textBtnLeft} onClick={toggleModal} />{" "}
          <Button color="success" text={textBtnRight} onClick={onConfirm} disabled={disableButtonConfirm} />
        </CModalFooter>
      </StyledCard>
    </CModal>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default ModalDigitizer;
