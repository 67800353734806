import React from "react";
import { CCard, CRow, CCol } from "@coreui/react";
import { colorsBadged } from "../../../commons/consts/colors";
import { ChartBarCard, ChartPieCard } from "../../molecules";
import { footerFontChartsText } from "../../../commons/static/tabs";
import { elementsTabReports } from "../../../commons/static/tabs";
import styled from "styled-components";

const ChartsEmotionalLayout = ({ tabName, data, spinner }) => {
  const { emotionalModules, violenceAreas } = data;
  return (
    <>
      {tabName === elementsTabReports[2].name && (
        <StyledCard className="p-4 border-0">
          <CRow>
            <CCol xs="12">
              <ChartPieCard
                titleLitte="GRÁFICO 1"
                title="TOTAL DE ATENCIONES DEL ÁREA EMOCIONAL"
                dataPoints={emotionalModules && emotionalModules.dataPoints}
                labels={emotionalModules && emotionalModules.labels}
                nTotal={emotionalModules && emotionalModules.total}
                loading={spinner}
                footerText={footerFontChartsText}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12">
              <ChartBarCard
                titleLitte="GRÁFICO 2"
                title="ÁREAS DE AFECTACIÓN EN LA VIDA DE LAS MUJERES A CAUSA DE LAS VIOLENCIAS"
                dataPoints={violenceAreas && violenceAreas.dataPoints}
                label="ÁREAS"
                backgroundColor={violenceAreas ? colorsBadged(6) : colorsBadged(10)}
                labels={violenceAreas && violenceAreas.labels}
                nTotal={violenceAreas && violenceAreas.total}
                loading={spinner}
                footerText={footerFontChartsText}
              />
            </CCol>
          </CRow>
        </StyledCard>
      )}
    </>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.cardBackground};
  transition: all 0.5s ease;
`;

export default ChartsEmotionalLayout;
