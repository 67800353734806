import React from "react";
import { CWidgetDropdown } from "@coreui/react";
import { useSelector } from "react-redux";
import { convertFormatDate, convertFormatTime } from "../../../commons/helpers/dates";

const MenuItemSlider = ({ title, index, itemId, width = 200, header, text, dateQuote, dateAttention, color, toggleModal }) => {
  const dateCurrent = useSelector((state) => state.dateCurrent);
  const getStyleDate = () => (dateCurrent <= dateQuote ? "text-white font-italic" : "");

  return (
    <div className="btn border-0" id={itemId}>
      <CWidgetDropdown
        onClick={() => toggleModal(title, index, true)}
        style={{ width, fontSize: 13 }}
        color={color}
        header={header}
        text={text}
        footerSlot={
          <div className={"text-right my-3 mr-3"}>
            <p className="m-0 p-0">
              Aten: {convertFormatDate(dateAttention)} {convertFormatTime(dateAttention)}
            </p>
            <p className={`m-0 p-0 ${getStyleDate()}`}>
              Cita: {convertFormatDate(dateQuote)} {convertFormatTime(dateQuote)}
            </p>
          </div>
        }
      />
    </div>
  );
};

export default MenuItemSlider;
