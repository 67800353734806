import React, { memo } from "react";
import { CCol } from "@coreui/react";
import { WidgetDropdown } from "../../atoms";
import { footerFontChartsText } from "../../../commons/static/tabs";
import { useSelector } from "react-redux";

const WidgetsCount = ({ state, onClickDropdownItem }) => {
  const theme = useSelector((state) => state.theme);
  const { spinnerFemicides, spinnerAreas, spinnerCases, dataFemicides, dataAreasAttention, dataStatusCases } = state;

  return (
    <>
      <CCol xs="12">
        <WidgetDropdown
          type="line"
          color={theme === "dark" ? "gradient-dark" : "gradient-danger"}
          loading={spinnerFemicides}
          dataPoints={dataFemicides.dataPoints}
          labels={dataFemicides.labels}
          label="Femicidios"
          value={dataFemicides.total}
          title="Casos de femicidios"
          onClickDropdownItem={(event) => onClickDropdownItem(event, "Femicidios")}
          text={"Año de consulta: " + dataFemicides.yearSelected}
          footerText={footerFontChartsText}
        />
      </CCol>
      <CCol xs="12" lg="6">
        <WidgetDropdown
          text={"Año de consulta: " + dataStatusCases.yearSelected}
          type="line"
          color={theme === "dark" ? "gradient-dark" : "gradient-success"}
          loading={spinnerCases}
          dataPoints={dataStatusCases.dataPoints}
          labels={dataStatusCases.labels}
          label="Atenciones"
          valueOpened={dataStatusCases.casesOpenedTotal}
          valueClosed={dataStatusCases.casesClosedTotal}
          title="Estado de casos atendidos"
          onClickDropdownItem={(event) => onClickDropdownItem(event, "Atenciones")}
          footerText={footerFontChartsText}
        />
      </CCol>
      <CCol xs="12" lg="6">
        <WidgetDropdown
          text={"Año de consulta: " + dataAreasAttention.yearSelected}
          type="pie"
          loading={spinnerAreas}
          color={theme === "dark" ? "gradient-dark" : "gradient-info"}
          dataPoints={dataAreasAttention.dataPoints}
          labels={dataAreasAttention.labels}
          label="Expedientes"
          value={dataAreasAttention.total}
          title="Atención de casos por área"
          onClickDropdownItem={(event) => onClickDropdownItem(event, "Expedientes")}
          footerText={footerFontChartsText}
        />
      </CCol>
    </>
  );
};

export default memo(WidgetsCount);
