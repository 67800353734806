import React from "react";
import SignatureCanvas from "react-signature-canvas";

const canvasPropsDefault = {
  height: 300,
  className: "bg-light sigCanvas",
};

const Signature = ({ penColor, canvasProps, state, onEnd }) => {
  return (
    <SignatureCanvas
      ref={(ref) => (state.ref = ref)}
      penColor={penColor}
      canvasProps={canvasProps ? canvasProps : canvasPropsDefault}
      onEnd={onEnd}
      clearOnResize={false}
    />
  );
};

export default Signature;
