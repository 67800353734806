import React, { useCallback, useState, useRef, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { CCard, CContainer, CCol, CRow, CCardTitle, CCardSubtitle } from "@coreui/react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  ContainerSlider,
  ProgressBar,
  ListGroup,
  ModalRegister,
  GeneralTab,
  EmotionalTab,
  FollowForm,
  AccompanimentForm,
  MultimediaForm,
  QuoteFollowForm,
  TherapeuticPlanForm,
  TestAppQuoteForm,
  CalendarForm,
  ImageButton,
  AlertNoApply,
  ModalResetToken,
} from "../../molecules";
import { Icon, Label, ChartLineSimple, Button, Imagen, Toast, Spinner, Dropdown } from "../../atoms";
import imgCalendar from "../../../assets/calendar-svg.svg";
import imgTestApp from "../../../assets/test-app.svg";
import imgDocument from "../../../assets/document.svg";
import imgNoteBook from "../../../assets/notebook.svg";
// Helpers
import { getCheckBoxes, getBadge, getListGroup, getListGroupCustomArray, getSpinnerArg } from "../../../commons/helpers/utils";
import { getMultimedia } from "../../../commons/helpers/utils";
import {
  validationsEmotionalForm,
  validationsLegalForm,
  validationsFollowForm,
  validationsAccompaniemtForm,
  validationsMultimedias,
  validationsTestAppQuoteForm,
  validationsQuoteFollowForm,
  verifyTokenExpired,
  validationsTherapeuticPlanForm,
} from "../../../commons/helpers/validations";
import { convertFormatDate } from "../../../commons/helpers/dates";
import { errorsUpLoadStorage } from "../../../commons/helpers/errors";
import { urlsAdmin, urlsDigitizer } from "../../../commons/consts/urls";
// Firebase
import { getStorage, ref, deleteObject, getDownloadURL } from "firebase/storage";
// SERVICES
import { getOccupations, getViolences } from "../../../commons/services/femicides";
import {
  registerGeneralTab,
  registerEmotionalTab,
  registerFollow,
  getAccompanimentsReasons,
  registerAccompaniment,
  registerMultimediaAL,
  registerMultimediaAE,
  registerTestAppQuote,
  deleteFileInFirestorage,
  registerQuoteFollow,
  registerResourcesUsed,
  getInfoCareRecord,
  getAllInfoCareRecord,
  downloadModifiedPDF,
  setChangeStatusCase,
} from "../../../commons/services/carerecords";

const optionsStatus = [
  { value: 0, label: "CERRADO" },
  { value: 1, label: "ABIERTO" },
  { value: 2, label: "ABANDONADO" },
];

const CareReccordForm = () => {
  let timeOut = useRef(null);
  const history = useHistory();
  const { names, lastnames, id, statusCase } = useParams();
  const stateGlobal = useSelector((state) => ({
    idToken: state.currentUser.idToken,
    displayName: state.currentUser.displayName,
    currentUser: state.currentUser,
    dateCurrent: state.dateCurrent,
    violencesTypes: state.violencesTypes,
    theme: state.theme,
  }));
  const { idToken, displayName, currentUser, theme, dateCurrent } = stateGlobal;
  const [paraments, setParaments] = useState({
    // Form Emotional
    date: dateCurrent,
    hour: "",
    names: "",
    lastnames: "",
    age: 21,
    exposition: "",
    developmentSession: "",
    agreements: "",
    affectationLevel: { value: -1, label: "undefined" },
    nextQuoteDate: "",
    nextQuoteHourStart: "",
    nextQuoteHourEnd: "",
    violencesTypes: {
      selected: "Seleccione",
      items: [],
      itemsSelected: [],
      loading: false,
    },
    violencesApproaches: {
      selected: "Seleccione",
      items: [],
      itemsSelected: [],
      loading: false,
    },
    violenceAreas: [],
    generalAsssessment: "",
    therapeuticTechniques: { selected: "Seleccione", items: [] },
    otherTherapeuticTechniques: "",
    observations: "",
    attentionModeEmotional: { value: -1, label: "Seleccione" },
    reasonQuery: "",
    othersViolenceArea: "",
    virtualOptionsEmotional: [],
    resourcesUsed: { selected: "", items: [] },
    photoUser: "",
    attentionBy: "",
    // Form Legal
    dateAreaLegal: dateCurrent,
    hourAreaLegal: "",
    identity: "",
    tutor: {
      victimRelationship: { value: -1, label: "Seleccione" },
      names: "",
      lastnames: "",
      numberPhone: "",
      identity: "",
    },
    ethnicities: { label: "Seleccione", value: -1 },
    maritalStatus: { label: "Seleccione", value: -1 },
    occupation: "",
    educationLevel: { value: -1, label: "Seleccione" },
    address: "",
    numDaughters: "",
    numSons: "",
    agesChildren: "",
    phoneNumber: "",
    phoneHouse: "",
    callEmergency: {
      nameComplete: "",
      phoneNumber: "",
      phoneWork: "",
      address: "",
    },
    sexualOrientations: { value: -1, label: "Seleccione" },
    disability: { value: -1, label: "undefined" },
    disabilityType: [],
    aggressorNames: "",
    aggressorLastnames: "",
    aggressorIdentity: "",
    aggressorAge: 0,
    aggressorEthnicity: { value: -1, label: "Seleccione" },
    aggressorEducationLevel: { value: -1, label: "Seleccione" },
    aggressorOccupation: "",
    aggressorAddress: "",
    aggressorPhoneNumber: "",
    aggressorPhoneHouse: "",
    aggressorPhoneWork: "",
    aggressorPlaceWork: "",
    aggressorType: [],
    aggressorNumber: 1,
    aggressorViolenceTypes: {
      selected: "Seleccione",
      items: [],
      itemsSelected: [],
      loading: false,
    },
    familyRights: { selected: "Seleccione", items: [] },
    otherFamilyRights: "",
    crimes: { selected: "Seleccione", items: [] },
    otherCrime: "",
    description: "",
    support: [],
    otherSupport: "",
    caseReferred: [],
    caseFrom: "",
    otherReferredOgWomen: "",
    otherReferred: "",
    observationsLegal: "",
    recommendationsLegal: "",
    caseActions: "",
    virtualOptionsLegal: [],
    attentionModeLegal: { value: -1, label: "Seleccione" },
    usersAccess: [],
    digitalFootprint: null,
    // Follow
    dateFollow: dateCurrent,
    hourFollow: "",
    progressFollow: 0,
    crimesFollow: { selected: "Seleccione", items: [] },
    otherCrimes: "",
    supportFollow: [],
    otherSupportFollow: "",
    descriptionFollow: "",
    attentionModeFollow: { value: -1, label: "Seleccione" },
    virtualOptionsFollow: [],
    // Accompaniment
    dateAccompaniment: dateCurrent,
    hourAccompaniment: "",
    observationsAccompaniment: "",
    actionsPublic: [],
    otherActionPublic: "",
    justiceOperators: { value: -1, label: "undefined" },
    incidentsJusticeOperators: "",
    accompanimentsReasons: {
      selected: "Seleccione",
      items: [],
      itemsSelected: [],
      loading: false,
    },
    othersAccompanimentsReasons: "",
    progressAccompaniment: 0,
    // TestAppQuoteForm
    dateTestApp: dateCurrent,
    hourTestApp: "",
    queryReasonTestApp: "",
    appliedTests: "",
    observationsTestApp: "",
    resultsTestApp: "",
    attentionModeTestApp: { value: -1, label: "Seleccione" },
    virtualOptionsTestApp: [],
    // QuoteFollow
    dateQuoteFollow: dateCurrent,
    hourQuoteFollow: "",
    feedBack: "",
    therapeuticCompliance: { value: -1, label: "undefined" },
    developmentAttention: "",
    violencesApproachesQuoteFollow: {
      selected: "Seleccione",
      items: [],
      itemsSelected: [],
      loading: false,
    },
    affectationLevelQuoteFollow: { value: -1, label: "undefined" },
    generalAsssessmentQuoteFollow: { value: -1, label: "undefined" },
    therapeuticTechniquesQuoteFollow: { selected: "Seleccione", items: [] },
    attentionModeQuoteFollow: { value: -1, label: "Seleccione" },
    virtualOptionQuoteFollow: [],
    progressQuoteFollow: 0,
  });

  // Archivos para evidencias.
  const [file, setFile] = useState({
    data: [],
    filePath: { name: "" },
    dataEmotional: [],
    filePathEmotional: { name: "" },
  });

  const [controllers, setControllers] = useState({
    loadingSelectInput: false,
    spinner: false,
    signatureState: { ref: useRef(null), isEmpty: true, show: false },
    statusCaseCurrent: statusCase,
    colorStatusCase: "purple",
    //informedConsentPDF: null, // Estado para visualizar el consentimiento informado.
    // array
    dataFollow: [],
    dataAccompaniments: [],
    dataQuotesFollow: [],
    testAppQuotesActivations: [],
    chartQuoteFollow: {},
    progressLegal: 0,
    // Spinners
    spinnerButtons: false,
    spinnerResourcesUsed: false,
    spinnerTabGeneral: false,
    spinnerFollow: false,
    spinnerAccompaniments: false,
    spinnerQuotesFollow: false,
    spinnerStatusCase: false,
    // Calendars
    date: stateGlobal.dateCurrent,
    issueQuote: "",
    events: [],
  });

  const [toast, setToast] = useState({
    show: false,
    message: "",
    className: "",
    title: "",
  });

  const [modal, setModal] = useState({
    show: false,
    title: "",
    numberForm: 0,
    token: false,
    spinner: false,
    textBtnConfirm: "Guardar",
  });

  const onChangeInputs = (event, field, arg, argData) => {
    let validNumberPhone;
    const { target } = event ? event : {};
    switch (arg) {
      case "input":
        setParaments((prevState) => ({ ...prevState, [field]: target.value }));
        break;
      case "number":
        validNumberPhone =
          field === "phoneHouse" ||
          field === "phoneNumber" ||
          field === "aggressorPhoneNumber" ||
          field === "aggressorPhoneHouse" ||
          field === "aggressorPhoneWork";
        if (field === "age" && paraments.age > 18) {
          setParaments(
            (prevState) => ({
              ...prevState,
              [field]: target.value ? parseInt(target.value) : target.value,
            }),
            setParaments((prevState) => ({
              ...prevState,
              tutor: {
                victimRelationship: { value: -1, label: "Seleccione" },
                names: "",
                lastnames: "",
                numberPhone: "",
              },
            }))
          );
        } else if (validNumberPhone) {
          if (target.value.length < 9) {
            setParaments((prevState) => ({
              ...prevState,
              [field]: target.value ? parseInt(target.value) : target.value,
            }));
          }
        } else {
          setParaments((prevState) => ({
            ...prevState,
            [field]: target.value ? parseInt(target.value) : target.value,
          }));
        }
        break;
      case "dropdown":
        setParaments((prevState) => ({
          ...prevState,
          [field]: { value: target.getAttribute("value"), label: target.text },
          virtualOptionsEmotional: field !== "attentionModeEmotional" ? paraments.virtualOptionsEmotional : [],
          virtualOptionsLegal: field !== "attentionModeLegal" ? paraments.virtualOptionsLegal : [],
          virtualOptionsFollow: field !== "attentionModeFollow" ? paraments.virtualOptionsFollow : [],
          virtualOptionsTestApp: field !== "attentionModeTestApp" ? paraments.virtualOptionsTestApp : [],
          virtualOptionQuoteFollow: field !== "attentionModeQuoteFollow" ? paraments.virtualOptionQuoteFollow : [],
        }));
        break;
      case "dropdown-tutor":
        setParaments((prevState) => ({
          ...prevState,
          tutor: {
            ...paraments.tutor,
            victimRelationship: {
              value: target.getAttribute("value"),
              label: target.text,
            },
          },
        }));
        break;
      case "list-group":
        const array = paraments[field]["items"];
        if (!array.find((item) => item.value === target.getAttribute("value"))) {
          setParaments((prevState) => ({
            ...prevState,
            [field]: {
              selected: target.text,
              items: getListGroup(array, target),
            },
          }));
        }
        break;
      case "list-group-custom":
        setParaments((prevState) => ({
          ...prevState,
          [field]: { ...paraments[field], selected: target.value },
        }));
        break;
      case "select-input":
        setParaments((prevState) => ({
          ...prevState,
          [field]: {
            value: event ? event.value : -1,
            label: event ? event.label : "",
          },
        }));
        break;
      case "check-box":
        const valuesBox = getCheckBoxes(paraments[field], target);
        setParaments((prevState) => ({
          ...prevState,
          [field]: valuesBox,
        }));
        break;
      case "tutor":
        validNumberPhone = field === "numberPhone";
        if (validNumberPhone) {
          if (target.value.length < 9) {
            setParaments((prevState) => ({
              ...prevState,
              tutor: { ...paraments.tutor, [field]: target.value },
            }));
          }
        } else {
          setParaments((prevState) => ({
            ...prevState,
            tutor: { ...paraments.tutor, [field]: target.value },
          }));
        }
        break;
      case "call-emergency":
        validNumberPhone = field === "phoneWork" || field === "phoneNumber";
        if (validNumberPhone) {
          if (target.value.length < 9) {
            setParaments((prevState) => ({
              ...prevState,
              callEmergency: { ...paraments.callEmergency, [field]: target.value },
            }));
          }
        } else {
          setParaments((prevState) => ({
            ...prevState,
            callEmergency: { ...paraments.callEmergency, [field]: target.value },
          }));
        }
        break;
      case "badge":
        const itemsSelected = getBadge(paraments[field]["itemsSelected"], target);
        setParaments((prevState) => ({
          ...prevState,
          [field]: {
            ...paraments[field],
            itemsSelected,
          },
        }));
        break;
      case "check-box-multiple":
        if (paraments[field]["selected"] !== target.text) {
          setParaments((prevState) => ({
            ...prevState,
            [field]: {
              ...paraments[field],
              loading: true,
            },
          }));
          if (field === "accompanimentsReasons") {
            onHandleGetAccompanimentsReasons(target, field);
          } else {
            onHandleGetViolences(target, field);
          }
        }
        break;
      case "check-boxes":
        const valuesBoxes = getCheckBoxes(paraments[field]["itemsSelected"], target);
        setParaments((prevState) => ({
          ...prevState,
          [field]: {
            ...paraments[field],
            itemsSelected: valuesBoxes,
          },
        }));
        break;
      case "input-files":
        setFile((prevState) => ({
          ...prevState,
          [field]: target.files[0],
        }));
        if (field !== "filePath" && field !== "filePathEmotional") {
          const itemNew = {
            attentionBy: displayName.username,
            attentionUid: currentUser.uid,
            name: target.files[0].name,
            type: target.files[0].type.split("/")[0],
            registered: convertFormatDate(stateGlobal.dateCurrent),
            url: field,
          };
          const tempData = getMultimedia(file[argData], itemNew);
          setFile((prevState) => ({
            ...prevState,
            [argData]: tempData,
          }));
        }
        break;
      default:
        setParaments((prevState) => ({
          ...prevState,
          [field]: {
            value: target.getAttribute("value"),
            label: target.getAttribute("id"),
          },
        }));
        break;
    }
  };

  const onChangeCalendar = (arg) => {
    setControllers((prevState) => ({ ...prevState, date: arg.dateStr }));
  };

  const addListGroupCustom = useCallback(
    (item) => {
      const array = paraments.resourcesUsed.items;
      array.push(item);
      setParaments((prevState) => ({
        ...prevState,
        resourcesUsed: { selected: "", items: array },
      }));
    },
    [paraments.resourcesUsed]
  );

  const deleteItemListGroupCustom = useCallback(
    (event, field) => {
      const { items } = paraments[field];
      const index = parseInt(event.target.getAttribute("value"));
      const array = getListGroupCustomArray(items, index);
      setParaments((prevState) => ({
        ...prevState,
        [field]: { ...paraments[field], items: array },
      }));
    },
    [paraments]
  );

  const deleteItemListGroup = useCallback(
    (event, field) => {
      const { target } = event;
      const temp = paraments[field]["items"].filter((item) => {
        if (item.value === target.getAttribute("value")) {
          return false;
        } else {
          return true;
        }
      });
      setParaments((prevState) => ({
        ...prevState,
        [field]: { selected: "Seleccione", items: temp.length > 0 ? temp : [] },
      }));
    },
    [paraments]
  );

  const loadOptionsSelectInput = useCallback(
    async (inputValue) => {
      try {
        const { success, result, title, message, code } = await getOccupations({
          idToken,
          occupation: inputValue,
        });

        if (success) {
          return result;
        } else {
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, token: true }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              className: "text-white bg-danger",
              message,
            }));
          }
        }
      } catch (error) {
        setToast({
          show: true,
          title: "Error in getOccupations",
          message: error.message,
          className: "text-white bg-danger",
        });
      } finally {
        hiddenToast();
        setControllers((prevState) => ({
          ...prevState,
          loadingSelectInput: false,
        }));
      }
    },
    [idToken]
  );

  const onEndSignature = useCallback(() => {
    setControllers((prevState) => ({
      ...prevState,
      signatureState: { ...controllers.signatureState, isEmpty: false },
    }));
  }, [controllers.signatureState]);

  const onHandleClearSignature = useCallback(() => {
    const { digitalFootprint } = paraments;
    const { signatureState } = controllers;
    if (!signatureState.ref.isEmpty() || digitalFootprint) {
      signatureState.ref.clear();
      setControllers(
        (prevState) => ({
          ...prevState,
          signatureState: { ...controllers.signatureState, isEmpty: true },
        }),
        setParaments((prevState) => ({ ...prevState, digitalFootprint: null }))
      );
    }
  }, [controllers, paraments]);

  const onHandleShowSignature = useCallback(
    (value) => {
      setControllers((prevState) => ({
        ...prevState,
        signatureState: {
          ...controllers.signatureState,
          show: value,
        },
      }));
    },
    [controllers.signatureState]
  );

  const onHandleSaveSignature = useCallback(() => {
    const { isEmpty, getTrimmedCanvas } = controllers.signatureState.ref;
    if (!isEmpty()) {
      const reducerImageBase64 = getTrimmedCanvas().toDataURL();
      const decodeImageBase64 = reducerImageBase64.split(",", 2);
      setParaments(
        (prevState) => ({
          ...prevState,
          digitalFootprint: decodeImageBase64[1],
        }),
        onHandleShowSignature(false)
      );
    }
  }, [paraments.digitalFootprint, controllers.signatureState]);

  const onHandleDeleteFile = useCallback(
    async (index, nameFile, dataArg, collectionName) => {
      try {
        setControllers((prevState) => ({ ...prevState, spinnerButtons: true }));
        const tempData = dataArg === "data" ? file.data : file.dataEmotional;
        tempData.splice(index, 1);

        const storage = getStorage();
        const firestorePath = dataArg === "data" ? `victims/${id}/multimedias/${nameFile}` : `victims/${id}/therapeutic_plan/${nameFile}`;
        const desertRef = ref(storage, firestorePath);
        await deleteObject(desertRef);

        // Actualizar el firestore
        const { success, title, message, code } = await deleteFileInFirestorage({
          idToken,
          id,
          paraments: tempData,
          nameFile,
          collectionName,
        });
        if (success) {
          setFile(
            (prevState) => ({ ...prevState, [dataArg]: tempData }),
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              message,
              className: success ? "text-white bg-success" : "text-white bg-danger",
            }))
          );
        } else {
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, token: true }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              className: "text-white bg-danger",
              message,
            }));
          }
        }
      } catch (error) {
        executeMessageError("Error al eliminar archivo del Storage", error.message);
      } finally {
        executeFinally("spinnerButtons");
      }
    },
    [file, id, idToken]
  );

  const onHandleDonwloadFile = useCallback(
    (nameFile, filePath) => {
      const storage = getStorage();
      const firestorePath =
        filePath === "filePath" ? `victims/${id}/multimedias/${nameFile}` : `victims/${id}/therapeutic_plan/${nameFile}`;
      const starsRef = ref(storage, firestorePath);

      // Get the download URL
      getDownloadURL(starsRef)
        .then(async (url) => {
          await fetch(url)
            .then((resp) => resp.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              // the filename you want
              a.download = nameFile;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => alert(error.message));
        })
        .catch((error) => {
          const messageError = errorsUpLoadStorage(error.code);
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title: "Error en la descarga del archivo",
            message: messageError,
            className: "text-white bg-danger",
          }));
          hiddenToast();
        });
    },
    [idToken]
  );

  const onInputSelectChange = (text) => {
    if (text) {
      setControllers((prevState) => ({
        ...prevState,
        loadingSelectInput: true,
      }));
      return text;
    }
  };

  const modalComponent = (paraments) => {
    let component;
    if (id) {
      switch (modal.title) {
        case "Ficha general":
          component = (
            <GeneralTab
              paraments={paraments}
              controllers={controllers}
              loadOptionsSelectInput={loadOptionsSelectInput}
              onInputSelectChange={onInputSelectChange}
              onChangeInputs={onChangeInputs}
              deleteItem={deleteItemListGroup}
              onHandleShowSignature={onHandleShowSignature}
              onHandleSaveSignature={onHandleSaveSignature}
              onEndSignature={onEndSignature}
              onHandleClearSignature={onHandleClearSignature}
            />
          );
          break;
        case "Seguimiento":
          component = (
            <FollowForm paraments={paraments} controllers={controllers} onChangeInputs={onChangeInputs} deleteItem={deleteItemListGroup} />
          );
          break;
        case "Acompañamiento":
          component = <AccompanimentForm paraments={paraments} controllers={controllers} onChangeInputs={onChangeInputs} />;
          break;
        case "Multimedias":
          component = (
            <MultimediaForm
              uid={id}
              file={file}
              controllers={controllers}
              onChangeInputs={onChangeInputs}
              deleteFile={onHandleDeleteFile}
              donwloadFile={onHandleDonwloadFile}
            />
          );
          break;
        case "Calendarización de citas":
          component = (
            <CalendarForm
              state={controllers}
              onChangeCalendar={onChangeCalendar}
              paraments={paraments}
              onChangeInputs={onChangeInputs}
              deleteItem={deleteItemListGroup}
              handleEventClick={(info) => {
                const { id, extendedProps } = info.event;
                setControllers((prevState) => ({ ...prevState, spinner: true, issueQuote: extendedProps.description }));
                onHandleGetInfoCareRecord("Calendarización de citas", parseInt(id), extendedProps.description);
              }}
              handleBlack={() => {
                setControllers((prevState) => ({ ...prevState, issueQuote: "" }), clearParaments(controllers.issueQuote));
              }}
            />
          );
          break;
        case "Citas de Seguimiento":
          component = (
            <QuoteFollowForm
              paraments={paraments}
              controllers={controllers}
              onChangeInputs={onChangeInputs}
              deleteItem={deleteItemListGroup}
            />
          );
          break;
        case "Plan terapéutico":
          component = (
            <TherapeuticPlanForm
              uid={id}
              file={file}
              controllers={controllers}
              onChangeInputs={onChangeInputs}
              deleteFile={onHandleDeleteFile}
              donwloadFile={onHandleDonwloadFile}
            />
          );
          break;
        case "Ficha de atención emocional":
          component = (
            <EmotionalTab
              paraments={paraments}
              controllers={controllers}
              onChangeInputs={onChangeInputs}
              deleteItem={deleteItemListGroup}
            />
          );
          break;
        default:
          component = <TestAppQuoteForm paraments={paraments} controllers={controllers} onChangeInputs={onChangeInputs} />;
          break;
      }
    } else if (modal.title === "Ficha general") {
      component = (
        <GeneralTab
          paraments={paraments}
          controllers={controllers}
          loadOptionsSelectInput={loadOptionsSelectInput}
          onInputSelectChange={onInputSelectChange}
          onChangeInputs={onChangeInputs}
          deleteItem={deleteItemListGroup}
          onHandleShowSignature={onHandleShowSignature}
          onHandleSaveSignature={onHandleSaveSignature}
          onEndSignature={onEndSignature}
          onHandleClearSignature={onHandleClearSignature}
        />
      );
    } else {
      component = <AlertNoApply />;
    }
    const disabledBtnConfirmAction = controllers.issueQuote === "" && modal.title === "Calendarización de citas" ? true : false;
    return (
      <ModalRegister
        show={modal.show}
        title={modal.title}
        content={component}
        toggleModal={toggleModal}
        sizeModal={modal.title === "Calendarización de citas" ? "xl" : "lg"}
        classModalHeader="h2"
        textButtonOnConfirm={modal.textBtnConfirm}
        disabledBtnConfirm={disabledBtnConfirmAction}
        onConfirm={validationsInputs}
      />
    );
  };

  const toggleModal = useCallback(
    (event, numberForm, isNotEmpty) => {
      const title = !event ? "" : event.target ? "" : event;
      const validationModal =
        isNotEmpty ||
        title === "Ficha general" ||
        title === "Ficha de atención emocional" ||
        title === "Cita de Aplicación de pruebas" ||
        title === "Calendarización de citas";
      setModal(
        (prevState) => ({
          ...prevState,
          show: !modal.show,
          title,
          numberForm,
          textBtnConfirm: validationModal && id ? "Actualizar" : "Guardar",
        }),
        onHandleShowSignature(false)
      );
      if (id) {
        if (modal.show) {
          clearParaments(modal.title);
        } else if (validationModal) {
          setControllers((prevState) => ({ ...prevState, spinner: true, issueQuote: "", date: stateGlobal.dateCurrent }));
          onHandleGetInfoCareRecord(title, numberForm);
        }
      }
    },
    [modal.show, onHandleShowSignature, idToken, stateGlobal.dateCurrent]
  );

  const toggleModalToken = () => {
    setModal((prevState) => ({ ...prevState, token: !modal.token }));
  };

  const hiddenToast = () => {
    timeOut = setTimeout(() => {
      setToast((prevState) => ({ ...prevState, show: false }));
    }, 10500);
  };

  const goToCareRecordUser = useCallback((response, params) => {
    const { userId } = response;
    const { names, lastnames } = params;
    willUnMount();
    const url =
      stateGlobal.displayName.id_rol.value !== "zLLLhDnXmTG1fOov0oy0"
        ? `${urlsAdmin.care_records}/${names}/${lastnames}/${userId}/${1}`
        : `${urlsDigitizer.care_records}/${names}/${lastnames}/${userId}/${1}`;
    history.push(url);
  }, []);

  // Clear function
  const clearParaments = useCallback(
    (arg) => {
      switch (arg) {
        case "registerGeneralTab":
        case "Ficha general":
          setParaments((prevState) => ({
            ...prevState, // Form Legal
            dateAreaLegal: dateCurrent,
            hourAreaLegal: "",
            ethnicities: { label: "Seleccione", value: -1 },
            maritalStatus: { label: "Seleccione", value: -1 },
            occupation: "",
            educationLevel: { value: -1, label: "Seleccione" },
            numDaughters: "",
            numSons: "",
            agesChildren: "",
            phoneNumber: "",
            phoneHouse: "",
            callEmergency: {
              nameComplete: "",
              phoneNumber: "",
              phoneWork: "",
              address: "",
            },
            sexualOrientations: { value: -1, label: "Seleccione" },
            disability: { value: -1, label: "undefined" },
            disabilityType: [],
            aggressorNames: "",
            aggressorLastnames: "",
            aggressorIdentity: "",
            aggressorAge: 0,
            aggressorEthnicity: { value: -1, label: "Seleccione" },
            aggressorEducationLevel: { value: -1, label: "Seleccione" },
            aggressorOccupation: "",
            aggressorAddress: "",
            aggressorPhoneNumber: "",
            aggressorPhoneHouse: "",
            aggressorPhoneWork: "",
            aggressorPlaceWork: "",
            aggressorType: [],
            aggressorNumber: 1,
            aggressorViolenceTypes: {
              selected: "Seleccione",
              items: [],
              itemsSelected: [],
              loading: false,
            },
            familyRights: { selected: "Seleccione", items: [] },
            otherFamilyRights: "",
            crimes: { selected: "Seleccione", items: [] },
            otherCrime: "",
            description: "",
            support: [],
            otherSupport: "",
            caseReferred: [],
            caseFrom: "",
            otherReferredOgWomen: "",
            otherReferred: "",
            observationsLegal: "",
            recommendationsLegal: "",
            caseActions: "",
            virtualOptionsLegal: [],
            attentionModeLegal: { value: -1, label: "Seleccione" },
            digitalFootprint: null,
            attentionBy: "",
            photoUser: "",
            usersAccess: [],
          }));
          break;
        case "registerEmotionalTab":
        case "Ficha de atención emocional":
          setParaments((prevState) => ({
            ...prevState, // Form Emotional
            date: dateCurrent,
            hour: "",
            names: id ? paraments.names : "",
            lastnames: id ? paraments.lastnames : "",
            age: id ? paraments.age : 21,
            exposition: "",
            developmentSession: "",
            nextQuoteDate: "",
            nextQuoteHourStart: "",
            nextQuoteHourEnd: "",
            agreements: "",
            affectationLevel: { value: -1, label: "undefined" },
            violencesTypes: {
              selected: "Seleccione",
              items: [],
              itemsSelected: [],
              loading: false,
            },
            violencesApproaches: {
              selected: "Seleccione",
              items: [],
              itemsSelected: [],
              loading: false,
            },
            violenceAreas: [],
            generalAsssessment: "",
            therapeuticTechniques: { selected: "Seleccione", items: [] },
            otherTherapeuticTechniques: "",
            observations: "",
            attentionModeEmotional: { value: -1, label: "Seleccione" },
            reasonQuery: "",
            othersViolenceArea: "",
            virtualOptionsEmotional: [],
            attentionBy: "",
            photoUser: "",
          }));
          break;
        case "registerFollow":
        case "Seguimiento":
        case "AL/Seguimiento":
          setParaments((prevState) => ({
            ...prevState, // Follow
            dateFollow: dateCurrent,
            hourFollow: "",
            nextQuoteDate: "",
            nextQuoteHourStart: "",
            nextQuoteHourEnd: "",
            crimesFollow: { selected: "Seleccione", items: [] },
            otherCrimes: "",
            supportFollow: [],
            progressFollow: 0,
            otherSupportFollow: "",
            descriptionFollow: "",
            attentionModeFollow: { value: -1, label: "Seleccione" },
            virtualOptionsFollow: [],
            attentionBy: "",
            photoUser: "",
          }));
          break;
        case "registerAccompaniment":
        case "Acompañamiento":
        case "AL/Acompañamiento":
          setParaments((prevState) => ({
            ...prevState, // Accompaniment
            dateAccompaniment: dateCurrent,
            hourAccompaniment: "",
            nextQuoteDate: "",
            nextQuoteHourStart: "",
            nextQuoteHourEnd: "",
            observationsAccompaniment: "",
            actionsPublic: [],
            otherActionPublic: "",
            justiceOperators: { value: -1, label: "undefined" },
            incidentsJusticeOperators: "",
            accompanimentsReasons: {
              selected: "Seleccione",
              items: [],
              itemsSelected: [],
              loading: false,
            },
            othersAccompanimentsReasons: "",
            progressAccompaniment: 0,
            attentionBy: "",
            photoUser: "",
          }));
          break;
        case "registerQuoteFollow":
        case "Citas de Seguimiento":
        case "AE/Citas de Seguimiento":
          setParaments((prevState) => ({
            ...prevState, // QuoteFollow
            dateQuoteFollow: dateCurrent,
            hourQuoteFollow: "",
            nextQuoteDate: "",
            nextQuoteHourStart: "",
            nextQuoteHourEnd: "",
            feedBack: "",
            therapeuticCompliance: { value: -1, label: "undefined" },
            developmentAttention: "",
            nextQuoteDateFollow: "",
            nextQuoteHourFollow: "",
            violencesApproachesQuoteFollow: {
              selected: "Seleccione",
              items: [],
              itemsSelected: [],
              loading: false,
            },
            affectationLevelQuoteFollow: { value: -1, label: "undefined" },
            generalAsssessmentQuoteFollow: { value: -1, label: "undefined" },
            therapeuticTechniquesQuoteFollow: { selected: "Seleccione", items: [] },
            attentionModeQuoteFollow: { value: -1, label: "Seleccione" },
            virtualOptionQuoteFollow: [],
            progressQuoteFollow: 0,
            attentionBy: "",
            photoUser: "",
          }));
          break;
        case "registerTestAppQuote":
        case "Cita de Aplicación de pruebas":
        case "AE/Cita de Aplicación de pruebas":
          setParaments((prevState) => ({
            ...prevState, // TestAppQuoteForm
            dateTestApp: dateCurrent,
            hourTestApp: "",
            nextQuoteDate: "",
            nextQuoteHourStart: "",
            nextQuoteHourEnd: "",
            queryReasonTestApp: "",
            appliedTests: "",
            observationsTestApp: "",
            resultsTestApp: "",
            attentionModeTestApp: { value: -1, label: "Seleccione" },
            virtualOptionsTestApp: [],
            attentionBy: "",
            photoUser: "",
          }));
          break;
        default:
          break;
      }
    },
    [paraments]
  );

  const willUnMount = () => {
    clearTimeout(timeOut);
    clearInterval(controllers.signatureState.ref);
  };

  // ----- Services
  const validationsInputs = () => {
    let verify;
    switch (modal.title) {
      case "Ficha general":
        verify = validationsLegalForm(paraments);
        break;
      case "Ficha de atención emocional":
        verify = validationsEmotionalForm(paraments);
        break;
      case "Seguimiento":
        verify = validationsFollowForm(paraments);
        break;
      case "Acompañamiento":
        verify = validationsAccompaniemtForm(paraments);
        break;
      case "Multimedias":
        verify = validationsMultimedias(file);
        break;
      case "Cita de Aplicación de pruebas":
        verify = validationsTestAppQuoteForm(paraments);
        break;
      case "Citas de Seguimiento":
        verify = validationsQuoteFollowForm(paraments);
        break;
      case "Plan terapéutico":
        verify = validationsTherapeuticPlanForm(file);
      default:
        switch (controllers.issueQuote) {
          case "AL/Seguimiento":
            verify = validationsFollowForm(paraments);
            break;
          case "AL/Acompañamiento":
            verify = validationsAccompaniemtForm(paraments);
            break;
          case "AE/Citas de Seguimiento":
            verify = validationsQuoteFollowForm(paraments);
            break;
          case "AE/Cita de Aplicación de pruebas":
            verify = validationsTestAppQuoteForm(paraments);
            break;
          default:
            break;
        }
        break;
    }

    if (verify.valid) {
      switch (modal.title) {
        case "Ficha general":
          setControllers((prevState) => ({ ...prevState, spinner: true }));
          onHandleRegisterForm(paraments, "registerGeneralTab");
          break;
        case "Ficha de atención emocional":
          validationRegisterForm("registerEmotionalTab");
          break;
        case "Seguimiento":
          validationRegisterForm("registerFollow");
          break;
        case "Acompañamiento":
          validationRegisterForm("registerAccompaniment");
          break;
        case "Multimedias":
          validationRegisterForm("registerMultimediaAL");
          break;
        case "Cita de Aplicación de pruebas":
          validationRegisterForm("registerTestAppQuote");
          break;
        case "Citas de Seguimiento":
          validationRegisterForm("registerQuoteFollow");
          break;
        case "Plan terapéutico":
          validationRegisterForm("registerMultimediaAE");
          break;
        default:
          switch (controllers.issueQuote) {
            case "AL/Seguimiento":
              validationRegisterForm("registerFollow");
              break;
            case "AL/Acompañamiento":
              validationRegisterForm("registerAccompaniment");
              break;
            case "AE/Citas de Seguimiento":
              validationRegisterForm("registerQuoteFollow");
              break;
            case "AE/Cita de Aplicación de pruebas":
              validationRegisterForm("registerTestAppQuote");
              break;
            default:
              break;
          }
          break;
      }
    } else {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title: "Campos requeridos",
        message: verify.message,
        className: "bg-danger text-white",
      }));
    }
    hiddenToast();
  };

  const validationRegisterForm = (arg) => {
    if (id) {
      setControllers((prevState) => ({ ...prevState, spinner: true }));
      switch (modal.title) {
        case "Multimedias":
          onHandleRegisterForm(file.data, arg);
          break;
        case "Plan terapéutico":
          onHandleRegisterForm(file.dataEmotional, arg);
          break;
        default:
          onHandleRegisterForm(paraments, arg);
          break;
      }
    } else {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title: "Atención",
        message: "Es requerido crear primeramente la ficha general.",
        className: "bg-danger text-white",
      }));
    }
  };

  const onHandleGetViolences = useCallback(
    async (target, field) => {
      try {
        const { success, violences, message, title, code } = await getViolences({
          id: target.getAttribute("value"),
          idToken,
        });
        if (success) {
          setParaments((prevState) => ({
            ...prevState,
            [field]: {
              ...paraments[field],
              selected: target.text,
              items: violences,
              loading: false,
            },
          }));
        } else {
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, token: true }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              className: "text-white bg-danger",
              message,
            }));
          }
        }
      } catch (error) {
        executeMessageError("Error en getViolence", error.message);
      } finally {
        hiddenToast();
      }
    },
    [
      paraments.violencesTypes,
      paraments.violencesApproaches,
      paraments.aggressorViolenceTypes,
      paraments.violencesApproachesQuoteFollow,
      idToken,
    ]
  );

  const onHandleGetAccompanimentsReasons = useCallback(
    async (target, field) => {
      try {
        const { success, accompanimentsReasons, message, title, code } = await getAccompanimentsReasons({
          id: target.getAttribute("value"),
          idToken,
        });
        if (success) {
          setParaments((prevState) => ({
            ...prevState,
            [field]: {
              ...paraments[field],
              selected: target.text,
              items: accompanimentsReasons,
              loading: false,
            },
          }));
        } else {
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, token: true }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              className: "text-white bg-danger",
              message,
            }));
          }
        }
      } catch (error) {
        executeMessageError("Error en getViolence", error.message);
      } finally {
        hiddenToast();
      }
    },
    [paraments.accompanimentsReasons, idToken]
  );

  const onHandleRegisterForm = useCallback(
    async (params, arg) => {
      try {
        let response = { success: false, title: "", message: "" };
        let fieldRequest = {
          idToken,
          paraments: params,
          charge: displayName.id_charge.value,
          attentionBy: displayName.username,
          photoUser: currentUser.photoURL,
        };
        switch (arg) {
          case "registerGeneralTab":
            response = await registerGeneralTab({ ...fieldRequest, id });
            break;
          case "registerEmotionalTab":
            response = await registerEmotionalTab({
              ...fieldRequest,
              id,
            });
            break;
          case "registerFollow":
            response = await registerFollow({
              ...fieldRequest,
              id,
              numberForm: modal.numberForm,
            });
            break;
          case "registerAccompaniment":
            response = await registerAccompaniment({
              ...fieldRequest,
              id,
              numberForm: modal.numberForm,
            });
            break;
          case "registerTestAppQuote":
            response = await registerTestAppQuote({
              ...fieldRequest,
              id,
              numberForm: modal.numberForm,
            });
            break;
          case "registerQuoteFollow":
            response = await registerQuoteFollow({
              ...fieldRequest,
              id,
              numberForm: modal.numberForm,
            });
            break;
          case "registerMultimediaAE":
            response = await registerMultimediaAE({
              ...fieldRequest,
              id,
            });
            break;
          default:
            response = await registerMultimediaAL({
              ...fieldRequest,
              id,
            });
            break;
        }
        if (response.success) {
          if (controllers.issueQuote) {
            setControllers((prevState) => ({ ...prevState, issueQuote: "" }));
            await onHandleGetInfoCareRecord("Calendarización de citas");
          } else {
            setModal((prevState) => ({
              ...prevState,
              show: false,
              title: "",
              numberForm: 0,
            }));
          }
          setToast(
            (prevState) => ({
              ...prevState,
              show: true,
              title: response.title,
              className: response.success ? "text-white bg-success" : "text-white bg-danger",
              message: response.message,
            }),
            clearParaments(arg)
          );

          if (arg !== "registerGeneralTab") {
            // Cargar el modulo que se ha registrado.
            const spinnerArg = getSpinnerArg(arg);
            setControllers((prevState) => ({ ...prevState, [spinnerArg]: true }));
            await onHandleGetAllInfoCareRecord(spinnerArg);
          } else if (id === undefined && arg === "registerGeneralTab") {
            goToCareRecordUser(response, params);
          }
        } else {
          if (verifyTokenExpired(response.code)) {
            setModal((prevState) => ({
              ...prevState,
              token: true,
            }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title: response.title,
              className: "text-white bg-danger",
              message: response.message,
            }));
          }
        }
      } catch (error) {
        executeMessageError("Error en registerFemicide", error.message);
      } finally {
        executeFinally("spinner");
      }
    },
    [modal.numberForm, idToken]
  );

  const onHanldeRegisterResourcesUsed = useCallback(async () => {
    try {
      setControllers((prevState) => ({
        ...prevState,
        spinnerResourcesUsed: true,
      }));
      const { success, title, message, code } = await registerResourcesUsed({
        idToken,
        resourcesUsed: paraments.resourcesUsed.items,
        id,
      });

      if (verifyTokenExpired(code) && !success) {
        setModal((prevState) => ({ ...prevState, token: true }));
      } else {
        setToast((prevState) => ({
          ...prevState,
          show: true,
          title,
          className: success ? "text-white bg-success" : "text-white bg-danger",
          message,
        }));
      }
    } catch (error) {
      executeMessageError("Error en registerResourcesUsed", error.message);
    } finally {
      executeFinally("spinnerResourcesUsed");
    }
  }, [idToken, paraments.resourcesUsed.items, id]);

  const onHandleGetAllInfoCareRecord = useCallback(
    async (spinnerArg) => {
      try {
        const { success, data, message, title, code } = await getAllInfoCareRecord({ idToken, id });
        if (success) {
          setParaments(
            (prevState) => ({ ...prevState, ...data.tabGeneral, resourcesUsed: data.resourcesUsed }),
            setControllers(
              (prevState) => ({
                ...prevState,
                dataFollow: data.follow,
                dataAccompaniments: data.accompaniments,
                dataQuotesFollow: data.quotesFollow,
                testAppQuotesActivations: data.testAppQuotes,
                chartQuoteFollow: data.progressEmotional,
                progressLegal: data.progressLegal,
              }),
              setFile((prevState) => ({ ...prevState, data: data.mediaAL, dataEmotional: data.mediaAE }))
            )
          );
        } else {
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, token: true }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              className: "text-white bg-danger",
              message,
            }));
          }
        }
      } catch (error) {
        executeMessageError("Error en getAllInfoCareRecord", error.message);
      } finally {
        if (!spinnerArg) {
          setControllers((prevState) => ({
            ...prevState,
            spinnerTabGeneral: false,
            spinnerFollow: false,
            spinnerAccompaniments: false,
            spinnerQuotesFollow: false,
            spinnerResourcesUsed: false,
          }));
        } else {
          setControllers((prevState) => ({
            ...prevState,
            [spinnerArg]: false,
          }));
        }
        hiddenToast();
      }
    },
    [idToken]
  );

  const onHandleChangeStatusCase = useCallback(
    async (event) => {
      try {
        const statusNumber = parseInt(event.target.getAttribute("value"));
        const { success, message, code, title } = await setChangeStatusCase({ idToken, statusCaseNew: statusNumber, id });
        if (verifyTokenExpired(code)) {
          setModal((prevState) => ({ ...prevState, token: true }));
        } else {
          const colorStatusCase = statusNumber === 0 ? "success" : statusNumber === 1 ? "purple" : "danger";
          setToast(
            (prevState) => ({
              ...prevState,
              show: true,
              title,
              className: success ? "text-white bg-success" : "text-white bg-danger",
              message,
            }),
            setControllers((prevState) => ({
              ...prevState,
              colorStatusCase,
              statusCaseCurrent: success ? statusNumber : prevState.statusCaseCurrent,
            }))
          );
        }
      } catch (error) {
        executeMessageError("Error en setChangeStatusCase", error.message);
      } finally {
        executeFinally("spinnerStatusCase");
      }
    },
    [paraments.usersAccess, idToken]
  );

  const onHandleGetInfoCareRecord = useCallback(
    async (issue, index, issueQuote) => {
      try {
        // Dejaremos para despues el index de la cita de aplicacion de pruebas
        const { success, data, title, message, code } = await getInfoCareRecord({ issue, id, idToken, index, issueQuote });
        if (success) {
          if (issue !== "Calendarización de citas" || issueQuote) {
            setParaments(
              (prevState) => ({ ...prevState, ...data }),
              setModal((prevState) => ({ ...prevState, numberForm: index }))
            );
          } else {
            setControllers((prevState) => ({ ...prevState, events: data }));
          }
        } else {
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, token: true }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              className: "text-white bg-danger",
              message,
            }));
          }
        }
      } catch (error) {
        executeMessageError("Error en getItemQuotesCareRecord", error.message);
      } finally {
        executeFinally("spinner");
      }
    },
    [id, idToken]
  );

  const onHanldeGetInformedConsent = async () => {
    const options = {
      names: paraments.names,
      lastnames: paraments.lastnames,
      age: paraments.age,
      identity: paraments.identity,
      tutor: paraments.tutor,
      attentionBy: stateGlobal.displayName,
      date: stateGlobal.dateCurrent,
      address: paraments.address,
    };
    const resBlob = await downloadModifiedPDF("informedConsent", options);
    // const docUrl = URL.createObjectURL(resBlob);
    const docUrl = window.URL.createObjectURL(resBlob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = docUrl;
    // the filename you want
    a.download = `Consentimiento de ${options.names} ${options.lastnames}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(docUrl);
    // Cambio de estado para visualizar el PDF.
    //setControllers((prevState) => ({ ...prevState, informedConsentPDF: docUrl }));
  };

  const executeFinally = (spinner) => {
    setControllers((prevState) => ({
      ...prevState,
      [spinner]: false,
    }));
    hiddenToast();
  };

  const executeMessageError = (title, message) => {
    setToast((prevState) => ({
      ...prevState,
      show: true,
      title,
      message,
      className: "text-white bg-danger",
    }));
  };

  useEffect(() => {
    if (id && idToken) {
      setControllers((prevState) => ({
        ...prevState,
        spinnerTabGeneral: true,
        spinnerFollow: true,
        spinnerAccompaniments: true,
        spinnerQuotesFollow: true,
        spinnerTestAppQoutes: true,
        spinnerResourcesUsed: true,
        colorStatusCase: statusCase === "0" ? "success" : statusCase === "1" ? "purple" : "danger",
      }));
      onHandleGetAllInfoCareRecord();
    }
    return willUnMount;
  }, [id, idToken]);

  const {
    spinnerFollow,
    spinnerAccompaniments,
    spinnerQuotesFollow,
    spinnerResourcesUsed,
    dataFollow,
    dataAccompaniments,
    dataQuotesFollow,
    testAppQuotesActivations,
    chartQuoteFollow,
    progressLegal,
    statusCaseCurrent,
    colorStatusCase,
  } = controllers;

  return (
    <CContainer fluid className="bg-none text-dark">
      <StyledCard className="p-4">
        <div className="row justify-content-between px-3">
          <CCardTitle className="mb-4 font-bold">
            EXPEDIENTE {id ? `de ${names} ${lastnames}`.toLocaleUpperCase() : "LEGAL Y EMOCIONAL"}
          </CCardTitle>
          {id && (
            <div className="mb-3">
              <p className="m-0">ESTADO ACTUAL</p>
              <Dropdown
                color={colorStatusCase}
                data={optionsStatus}
                onClick={(e) => onHandleChangeStatusCase(e)}
                placeholder={optionsStatus[statusCaseCurrent].label}
              />
            </div>
          )}
        </div>
        <CCardSubtitle className="mb-4">
          Expediente, atención legal, atención emocional ,Citas de Seguimiento, acompañamientos, aplicación de pruebas, progresos, plan
          terapéutico, calendarización y recursos a utilizar.
        </CCardSubtitle>
        <CCardTitle className="mb-4 font-bold text-left w-100 border-top pt-2">ÁREA LEGAL</CCardTitle>
        <CRow>
          <CCol rowSpan={2} xs="12" md="4" type="button" onClick={() => toggleModal("Ficha general")} className="mb-sm-4 mb-md-0">
            <div
              style={{ minHeight: 350 }}
              className={`d-flex flex-row align-items-center justify-content-center ${
                theme === "dark" ? "bg-info text-white" : "bg-light text-dark"
              } h-100 rounded-lg p-3`}
            >
              <p className="h6 font-semiBold text-center">
                <Imagen src={imgDocument} width={120} />
                <br />
                <br />
                Haga click para abrir la ficha general
              </p>
            </div>
          </CCol>
          <CCol xs="12" md="8" className="mt-3">
            {spinnerFollow ? (
              <Spinner width={120} />
            ) : (
              <ContainerSlider
                data={dataFollow}
                title="Seguimiento"
                toggleModal={() => toggleModal("Seguimiento")}
                toggleModalItem={toggleModal}
              />
            )}
            {spinnerAccompaniments ? (
              <Spinner width={120} />
            ) : (
              <ContainerSlider
                data={dataAccompaniments}
                title="Acompañamiento"
                className="mt-4"
                toggleModal={() => toggleModal("Acompañamiento")}
                toggleModalItem={toggleModal}
              />
            )}
          </CCol>
        </CRow>
        <CRow className="mt-4">
          <CCol xs="12" md="4">
            {id && (
              <Button text="Consentimiento Informado" color="info" className="mb-3 w-100" size="lg" onClick={onHanldeGetInformedConsent} />
            )}
          </CCol>
          <CCol>
            <ProgressBar showValue title="Progreso Legal" size="md" valueProgress={progressLegal} md="12" />
          </CCol>
        </CRow>
        <CRow className="justify-content-center align-items-center">
          <CCol xs="12" md="4" className="text-center">
            <Label text="Archivos Mutlimedia" className="h3 font-bold w-100 text-center" />
            <Icon name="play" size="3x" color="#5e039b" />
            <Icon name="fileAlt" size="3x" color="#bb2865" className="mx-3" />
            <Icon name="images" size="3x" color="#4ea5ec" />
          </CCol>
          <CCol xs="12" md="8">
            <div className="m-3">
              <h6>
                Para agregar o editar archivos mutlimedia presione el botón de [+].
                <br />
                <small className="font-bold text-danger">
                  Recuerde que cada archivo a subir tiene un límite de 25mb de no lo contrario no se aceptará el guardado, esto aplica más
                  para archivos de formato video en donde tendrá que reducir su tamaño.
                </small>
              </h6>
              <Button icon="noteAdd" size="4x" color="secondary" classNameIcon="text-light" onClick={() => toggleModal("Multimedias")} />
            </div>
          </CCol>
        </CRow>
        <CCardTitle className="mt-4 font-bold w-100 border-top text-left pt-2">ÁREA EMOCIONAL</CCardTitle>
        <CRow className="mt-4">
          <CCol
            style={{ minHeight: 350 }}
            rowSpan={2}
            xs="12"
            md="4"
            type="button"
            onClick={() => toggleModal("Ficha de atención emocional")}
            className="mb-sm-4 mb-md-0"
          >
            <div
              className={`d-flex flex-row justify-content-center align-items-center h-100 rounded-lg p-3 ${
                theme === "dark" ? "bg-info text-white" : "bg-light text-dark"
              } `}
            >
              <p className="h6 font-semiBold text-center">
                <Imagen src={imgNoteBook} width={100} />
                <br />
                <br />
                Haga click para abrir la ficha de atención emocional
              </p>
            </div>
          </CCol>
          <CCol xs="12" md="8">
            {spinnerQuotesFollow ? (
              <Spinner width={120} />
            ) : (
              <ContainerSlider
                data={dataQuotesFollow}
                title="Citas de Seguimiento"
                toggleModal={() => toggleModal("Citas de Seguimiento")}
                toggleModalItem={toggleModal}
              />
            )}
            <CCol xs="12">
              <Label text="Citas de Aplicación de pruebas (Máx.: 3)" className="h4 font-bold mt-3" />
              <CRow>
                {testAppQuotesActivations[0] && (
                  <ImageButton
                    image={imgTestApp}
                    width={80}
                    classNameLabel="mt-2"
                    classNameContainer={`${theme === "dark" ? "bg-info text-white" : "bg-light text-dark"} rounded-lg p-4 mt-2`}
                    onClick={() => toggleModal("Cita de Aplicación de pruebas", 0)}
                    text="Aplicación de prueba 1"
                  />
                )}
                {testAppQuotesActivations[1] && (
                  <ImageButton
                    image={imgTestApp}
                    width={80}
                    classNameLabel="mt-2"
                    classNameContainer={`${theme === "dark" ? "bg-info text-white" : "bg-light text-dark"} rounded-lg p-4 mt-2`}
                    onClick={() => toggleModal("Cita de Aplicación de pruebas", 1)}
                    text="Aplicación de prueba 2"
                  />
                )}
                {testAppQuotesActivations[2] && (
                  <ImageButton
                    image={imgTestApp}
                    width={80}
                    classNameLabel="mt-2"
                    classNameContainer={`${theme === "dark" ? "bg-info text-white" : "bg-light text-dark"} rounded-lg p-4 mt-2`}
                    onClick={() => toggleModal("Cita de Aplicación de pruebas", 2)}
                    text="Aplicación de prueba 3"
                  />
                )}
              </CRow>
            </CCol>
          </CCol>
        </CRow>
        <CRow className="mt-5">
          <ImageButton
            xs="12"
            md="4"
            width={120}
            className="align-self-center text-center"
            classNameContainer={`${theme === "dark" ? "bg-info text-white" : "bg-light text-dark"} rounded-lg py-5`}
            text={`Calendario de citas ${id ? `de ${names} ${lastnames}` : ""}`}
            classNameLabel="mt-3 h6"
            onClick={() => toggleModal("Calendarización de citas")}
            image={imgCalendar}
          />
          <CCol xs="12" md="8">
            <Label text="Progreso Emocional" className="h4 font-bold" />
            <ChartLineSimple
              style={{ height: 240 }}
              max={100}
              min={0}
              borderColor="#5e039b"
              label="Citas de Seguimiento"
              labels={chartQuoteFollow.labels}
              dataPoints={chartQuoteFollow.dataPoints}
            />
          </CCol>
        </CRow>
        <CRow className="mt-4">
          <ImageButton
            xs="12"
            md="4"
            width={120}
            classNameContainer={`${theme === "dark" ? "bg-info text-white" : "bg-light text-dark"} rounded-lg p-4`}
            text={`Plan Terapéutico ${id ? `de ${names} ${lastnames}` : ""}`}
            classNameLabel="mt-2"
            onClick={() => toggleModal("Plan terapéutico")}
          />
          {id && (
            <CCol xs="12" md="6">
              <ListGroup
                custom
                list={paraments.resourcesUsed.items}
                title="Recursos utilizados"
                itemsSelected={paraments.resourcesUsed.items}
                onChangeTextArea={(e) => onChangeInputs(e, "resourcesUsed", "list-group-custom")}
                valueTextArea={paraments.resourcesUsed !== undefined && paraments.resourcesUsed.selected}
                onClick={addListGroupCustom}
                itemDelete={(e) => deleteItemListGroupCustom(e, "resourcesUsed")}
              />
              {spinnerResourcesUsed ? (
                <Spinner width={120} height={120} />
              ) : (
                <Button
                  text="Guardar recursos utilizados"
                  color="primary"
                  className="w-100 text-white"
                  onClick={onHanldeRegisterResourcesUsed}
                />
              )}
            </CCol>
          )}
        </CRow>
        {/* Modales */}
        {modalComponent(paraments)}
        {/* <iframe title="test-frame" src={informedConsentPDF} type="application/pdf" style={{ height: 1200 }} /> */}
      </StyledCard>
      <ModalResetToken show={modal.token} spinner={modal.spinner} setModal={setModal} toggleModal={toggleModalToken} />
      <Toast show={toast.show} title={toast.title} textContent={toast.message} className={toast.className} autohide={9500} />
    </CContainer>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default CareReccordForm;
