import React from "react";
import { useSelector } from "react-redux";
import { Container } from "../../templates";
import { Header, SideMenu, Notifications } from "../../organims";
import { Toast } from "../../atoms";
import { ThemeProvider } from "styled-components";

const lightTheme = {
  pageBackground: "#d7d8d9",
  cardBackground: "#ebedef",
  titleColor: "#000000",
  tagLineColor: "black",
};
const darkTheme = {
  pageBackground: "#282c36",
  cardBackground: "#474a55",
  titleColor: "#ffffff",
  tagLineColor: "white",
};

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

const Admin = () => {
  const modeTheme = useSelector((state) => state.theme);
  const displayName = useSelector((state) => state.currentUser.displayName);
  const { username } = displayName;

  return (
    <ThemeProvider theme={themes[modeTheme]}>
      <div className="c-app c-default-layout">
        <SideMenu />
        <div className="c-wrapper">
          <Header />
          <div className="c-body py-3">
            <Container />
          </div>
          {/* <Footer /> */}
          <Notifications />
        </div>
        <Toast
          show={sessionStorage.getItem("messageWelcome") ? true : false}
          className="bg-success text-white"
          textContent={`Bienvenida al SAISV ${username}`}
          title="Acceso aprobado"
        />
      </div>
    </ThemeProvider>
  );
};

export default Admin;
