import React from "react";
import { CDataTable, CPagination, CRow, CCol } from "@coreui/react";
import { Button, Collapse, Dropdown, Imagen } from "../../atoms";
import { DateTimePicker } from "../../molecules";
import { useSelector } from "react-redux";
// Resources
import imgClosed from "../../../assets/closed.svg";
import imgOpen from "../../../assets/open.svg";

const TableSearch = ({
  elements,
  onlyShow,
  onTableFilterChange,
  fields,
  toggleModal,
  details,
  toggleDetails,
  onActivePageChange,
  onReadySearches,
  onPaginationChange,
}) => {
  const { spinnerTable, data, pagination, paginationTotal, tableFilterValue } = elements;
  const stateGlobal = useSelector((state) => ({
    theme: state.theme === "light" ? false : true,
    itemsPerPage: state.itemsPerPage,
  }));
  const theme = useSelector((state) => (state.theme === "light" ? false : true));

  const getImageStatusCase = (item) => {
    switch (item.statusCase) {
      case 0:
        return (
          <td>
            <Imagen src={imgClosed} width={60} />
          </td>
        );
      case 1:
        return (
          <td>
            <Imagen src={imgOpen} width={60} />
          </td>
        );
      default:
        // Imagen de abandono
        break;
    }
  };

  return (
    <>
      <CDataTable
        items={data}
        fields={fields}
        columnFilter
        loading={spinnerTable}
        tableFilterValue={tableFilterValue && tableFilterValue.name}
        tableFilter={
          onReadySearches
            ? {
                label: "Filtros: ",
                placeholder: "TODOS",
                external: true,
                lazy: true,
              }
            : false
        }
        noItemsView={{
          noResults: "Registros no encontrados...",
          noItems: "Data vacia...",
        }}
        pagination={{ align: "center", size: "lg" }}
        // Por defeto sera 20, para pruebas sera de 10 items
        hover
        sorter
        striped
        dark={theme}
        // Por defeto sera 20, para pruebas sera de 10 items.
        itemsPerPage={stateGlobal.itemsPerPage}
        itemsPerPageSelect={
          onPaginationChange
            ? {
                label: "Elementos por página: ",
                values: [10, 20, 30, 50, 80],
              }
            : false
        }
        onTableFilterChange={(value) => {
          if (tableFilterValue) {
            onTableFilterChange(value, "name", "tableFilterValue");
          }
        }}
        onPaginationChange={onPaginationChange}
        overTableSlot={
          onReadySearches && (
            <CRow className="px-3 mb-2">
              <CCol xs="12" md="6">
                <DateTimePicker
                  disableTime
                  disableTime2
                  label="Desde: "
                  valueDate={tableFilterValue.startDate}
                  onChangeDate={(event) => onTableFilterChange(event, "startDate", "tableFilterValue")}
                />
                <DateTimePicker
                  disableTime
                  disableTime2
                  label="Hasta: "
                  className="my-2"
                  valueDate={tableFilterValue.endDate}
                  onChangeDate={(event) => onTableFilterChange(event, "endDate", "tableFilterValue")}
                />
              </CCol>
              <CCol xs="12" md="6">
                <div>
                  <Button
                    icon="brushAlt"
                    className="w-50 mb-2"
                    text="Limpiar filtros"
                    color="dark"
                    onClick={(e) => onTableFilterChange(e, "fields-clear", "tableFilterValue")}
                    disabled={!(tableFilterValue.name || tableFilterValue.startDate || tableFilterValue.endDate)}
                  />
                </div>
                <div>
                  <Button icon="search" className="w-50" text="Buscar" color="info" onClick={() => onReadySearches(tableFilterValue)} />
                </div>
              </CCol>
            </CRow>
          )
        }
        scopedSlots={{
          statusCase: getImageStatusCase,
          options: (item, index) => {
            return onlyShow ? (
              <td className="d-flex">
                <Button
                  icon={details.includes(index) ? "eyeSlash" : "eye"}
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="lg"
                  onClick={() => {
                    toggleDetails(index);
                  }}
                  className="mr-1 px-2"
                />
                <Button
                  icon="send"
                  shape="square"
                  size="lg"
                  color="success"
                  onClick={() => {
                    toggleModal(item);
                  }}
                  className="ml-1 px-2"
                />
              </td>
            ) : (
              <td>
                <Dropdown onClick={(e) => toggleModal(e, item)} />
              </td>
            );
          },
          details: (item, index) => {
            return <Collapse item={item} index={index} details={details} />;
          },
        }}
      />
      {paginationTotal > 1 && (
        <CPagination activePage={pagination} pages={paginationTotal} onActivePageChange={onActivePageChange} align="center" size="lg" />
      )}
    </>
  );
};

export default TableSearch;
