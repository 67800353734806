export const emailReg =
  /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/g;
export const whiteSpaces = /[\n# $&:\n\t]/g;
export const pageWebRegExHTTPS = /https?:\/\/(?:[-w]+.)?([-w]+).w+(?:.w+)?\/?.*\w/g;
export const pageWebRegExHTTP = /http?:\/\/(?:[-w]+.)?([-w]+).w+(?:.w+)?\/?.*\w/g;
export const nameOrLastnameReg = /^[A-ZÁÉÍÓÚ][a-zñáéíóú]+/g;
export const numberIdentityReg = /[0-9]{13}/g;
export const phoneNumberHomeReg = /(2[0-9]{7})$/g;
export const phoneNumberReg = /(3|8|7|9)[0-9]{7}/g;
export const dateReg = /(\d{4})(\/|-)(0[1-9]|1[0-2])(\/|-)([0-2][0-9]|3[0-1])/g; // yyyy-mm-dd o yyyy/mm/dd
export const ageReg = /^(-1|0?[0-9]{1,2}|1[0-1][0-9]|120)$/g;
export const spaceWhiteReg = /\s+/g;
