import React from "react";
import { CCol } from "@coreui/react";
import { Imagen, Label } from "../../atoms";
import PropTypes from "prop-types";
import imgIdea from "../../../assets/bulb-idea.svg";

const ImageButton = ({ xs, md, onClick, className, image, width, classNameContainer, text, classNameLabel }) => {
  return (
    <CCol xs={xs} md={md} className={className}>
      <div className={classNameContainer} type="button" onClick={onClick}>
        <Imagen src={image} width={width} />
        <p className="m-0">
          <Label text={text} className={classNameLabel} />
        </p>
      </div>
    </CCol>
  );
};

ImageButton.propTypes = {
  xs: PropTypes.string,
  md: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
  image: PropTypes.string,
  width: PropTypes.any,
  text: PropTypes.string,
};

ImageButton.defaultProps = {
  xs: "12",
  md: "4",
  onClick: null,
  className: "text-center",
  classNameContainer: "bg-light rounded-lg p-4",
  image: imgIdea,
  width: 180,
  text: "Texto de prueba",
  classNameLabel: "text-dark",
};

export default ImageButton;
