import React from "react";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import { Button } from "../../atoms";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExportData = ({ startDate, endDate, data, className }) => {
  const { atenttionEmotional, demographicData, atenttionLegal } = data;

  const getMultiDataSet = (dataSet) => {
    let dataNew = [];
    let labels = dataSet.labels;
    let frequencies = dataSet.dataPoints;
    let total = dataSet.total.toString();

    dataNew = labels.map((item, index) => [{ value: item }, { value: frequencies[index].toString() }]);
    dataNew.push([{ value: "Total" }, { value: total }]);

    const multiDataSet = [
      {
        columns: [
          {
            value: "Descripción",
            widthPx: 180,
            style: { font: { sz: 24, bold: true }, fill: { bgColor: { rgb: "#FFAA00" } } },
          },
          {
            value: "Frecuencia",
            widthPx: 160,
            style: { font: { sz: 24, bold: true }, fill: { bgColor: { rgb: "#FFAA00" } } },
          },
        ],
        data: dataNew,
      },
    ];
    return multiDataSet;
  };

  return (
    <ExcelFile
      filename={`REPORTE_VP-SAISV_${startDate}_al_${endDate}`}
      element={<Button icon="fileExcel" text="Exportar datos" color="success" className={className} />}
    >
      <ExcelSheet dataSet={getMultiDataSet(demographicData.disability)} name="Discapacidades" />
      <ExcelSheet dataSet={getMultiDataSet(demographicData.educationLevel)} name="Niveles educativo" />
      <ExcelSheet dataSet={getMultiDataSet(demographicData.ethnicities)} name="Etnias" />
      <ExcelSheet dataSet={getMultiDataSet(demographicData.maritalStatus)} name="Estados civiles" />

      <ExcelSheet dataSet={getMultiDataSet(atenttionEmotional.emotionalModules)} name="Citas emocionales" />
      <ExcelSheet dataSet={getMultiDataSet(atenttionEmotional.violenceAreas)} name="Area de Violencias" />

      <ExcelSheet dataSet={getMultiDataSet(atenttionLegal.crimes)} name="Delitos" />
      <ExcelSheet dataSet={getMultiDataSet(atenttionLegal.familyRights)} name="Derechos de familia" />
      <ExcelSheet dataSet={getMultiDataSet(atenttionLegal.legalModules)} name="Citas legales" />
      <ExcelSheet dataSet={getMultiDataSet(atenttionLegal.support)} name="Apoyos brindados" />
      <ExcelSheet dataSet={getMultiDataSet(atenttionLegal.violenceTypes)} name="Tipos de violencias" />
    </ExcelFile>
  );
};

export default ExportData;
