import React from "react";
import { CContainer, CRow, CCol, CImg } from "@coreui/react";
import img from '../../../assets/NotFound.jpg'

const NotMatch = () => {
  return (
    <CContainer>
      <CRow className="align-items-center vh-100 text-center">
        <CCol>
          <CImg src={img}width={600} height="auto" style={{borderRadius:15}}/>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default NotMatch;
