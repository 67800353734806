const colors = {
  headerTableVictim: "rgb(60,75,100,0.6)",
};

const colorsWithOpacityAreas = [
  "rgb(75,0,129,0.85)", // color de
  "rgb(175,105,205,0.85)", // color de
];

const colorsWithOpacity = [
  "rgb(217,48,37,0.85)", // color de
  "rgb(50,163,80,0.85)", // color de
  "rgb(255,131,0,0.85)", // color de
  "rgb(0,118,188,0.85)", // color de
  "rgb(34,45,135,0.85)", // color de
  "rgb(0,138,155,0.85)", // color de
  "rgb(90,36,130,0.85)", // color de
  "rgb(78,94,110,0.85)", // color de
  "rgba(135, 116, 43, 0.85)", // color de
  "rgba(118, 8, 56, 0.85)", // color de
  "rgb(171,171,171)", // Color para los temas ligth y dark
  "rgba(239, 110, 249, 0.85)", // color de ..
  "rgba(96, 219, 207, 0.85)", // color de ..
  "rgba(255,219, 112, 0.85)", // color de ..
  "rgba(245, 146, 146, 0.85)", // color de ..
  "rgba(166, 119, 242, 0.85)", // color de..
  "rgba(150, 189, 223, 0.85)", // color de..
  "rgba(54, 32, 32, 0.85)", // color de ..
  "rgba(255, 237, 112, 0.85)", // color de ..
  "rgba(0, 0, 0, 0.6)",
];

const colorsForBadge = {
  "4aYOmdPtauIkN5fkY8Uh": "rgb(217,48,37,0.85)",
  "8RVTAXH7jvvSg4C1pnMG": "rgb(50,163,80,0.85)",
  AEkfqlr2q3qznP5s5ZfF: "rgb(255,131,0,0.85)",
  BJDw0vmIbdeUrdTHX3wk: "rgb(0,118,188,0.85)",
  EynVM8yyxOnkVNGAdBWl: "rgb(34,45,135,0.85)",
  IPK7sPwn98FzOXcxpXei: "rgb(0,138,155,0.85)",
  S6kYjpxU5dvDA7qATYiI: "rgb(90,36,130,0.85)",
  Yt3DhFNqcnjpOKU5jp1J: "rgb(78,94,110,0.85)",
  hIywlwhEe62lqV9N60hB: "rgba(135, 116, 43, 0.85)",
  t8hUWare9PE9PQAjzwxL: "rgba(96, 219, 207, 0.85)",
};

const colorsBadged = (index) => {
  return typeof index === "string" ? colorsForBadge[index] : colorsWithOpacity[index];
};

const BackgroundColorsDoughnut = (Array) => {
  let colors = [];
  for (var i = 0; i < Array.length; i++) {
    if (Array.length <= colorsWithOpacity.length) {
      colors.push(colorsWithOpacity[i]);
    } else {
      colors.push("rgb(228,228,228)");
    }
  }
  return colors;
};

const BackgroundColorsAreas = (Array) => {
  let colors = [];
  for (var i = 0; i < Array.length; i++) {
    if (Array.length <= colorsWithOpacityAreas.length) {
      colors.push(colorsWithOpacityAreas[i]);
    } else {
      colors.push("rgb(228,228,228)");
    }
  }
  return colors;
};

export { colorsBadged, BackgroundColorsDoughnut, colors, BackgroundColorsAreas };
