import React from "react";
import { CWidgetProgress } from "@coreui/react";
import { Imagen } from "../../atoms";
import imageProfile from "../../../assets/photo-profile.jpg";

const WidgetProgress = ({
  type,
  text,
  title,
  footer,
  value,
  className,
  onClick,
}) => {
  return (
    <div className="position-relative mt-1 px-3">
      <CWidgetProgress
        inverse
        className={className}
        color={type}
        header={title}
        text={text}
        footer={footer}
        value={value}
        onClick={onClick}
      />
      <div
        className="position-absolute z-index-front"
        style={{ bottom: 0, right: 5, left: "auto", top: "auto" }}
      >
        <Imagen src={imageProfile} className="rounded-circle" width={50} />
      </div>
    </div>
  );
};

export default WidgetProgress;
