import React, { useEffect, useState, useRef, useCallback } from "react";
import { Label, TextArea, Dropdown, RadioButton, Imagen, Spinner, Button, Toast } from "../../atoms";
import { CCol, CContainer, CRow } from "@coreui/react";
import { useParams } from "react-router-dom";
import { getInfoSurvey } from "../../../commons/services/users";

const options1 = [
  {
    label: "NO",
    value: 0,
  },
  {
    label: "SI",
    value: 1,
  },
];

const options2 = [
  {
    label: "NECESITA MEJORAR",
    value: 1,
  },
  {
    label: "BUENA",
    value: 2,
  },
  {
    label: "EXCELENTE",
    value: 3,
  },
];

const Survey = () => {
  let timeOut = useRef(null);
  const { rolId, victimId } = useParams();

  const [state, setState] = useState({
    usersAccess: [],
    spinner: false,
    processContinue: false,
    evaluations: {},
    answer1: "",
    answer6: "",
    answer7: "",
    recommendations: { label: "", value: undefined },
  });
  const [modal, setModal] = useState({
    show: false,
    title: "",
    spinner: false,
    textBtnConfirm: "Guardar",
  });

  const [toast, setToast] = useState({
    show: false,
    message: "",
    className: "",
    title: "",
  });

  const onChangeInputs = (event, field, arg, position) => {
    const { target } = event;
    switch (arg) {
      case "text-area":
        setState((prevState) => ({ ...prevState, [field]: target.value }));
        break;
      case "radio-button":
        setState((prevState) => ({
          ...prevState,
          [field]: {
            value: parseInt(target.getAttribute("value")),
            label: target.getAttribute("id"),
          },
        }));
        break;
      default:
        let userEvaluation = state.evaluations[field];
        userEvaluation.splice(position, 1, { label: target.text, value: parseInt(target.getAttribute("value")) });
        setState((prevState) => ({ ...prevState, evaluations: { ...state.evaluations, [field]: userEvaluation } }));
        break;
    }
  };

  const onHandleGetInfosurvey = useCallback(async () => {
    try {
      const { success, usersAccess, title, message } = await getInfoSurvey({ rolId, victimId, source: "public", enviroment: "production" });
      if (success) {
        // Agregar las usuarias a evaluations
        let evaluations = {};
        usersAccess.forEach((element) => {
          Object.assign(evaluations, { [element.value]: [-1, -1, -1, -1] });
        });
        setState((prevState) => ({ ...prevState, usersAccess, processContinue: success, evaluations }));
      }
      setToast((prevState) => ({
        ...prevState,
        show: true,
        message,
        className: success ? "text-white bg-success" : "text-white bg-danger",
        title,
      }));
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        message: error.message,
        className: "text-white bg-danger",
        title: "Error en getInfoSurvey",
      }));
    } finally {
      setState((prevState) => ({ ...prevState, spinner: false }));
      hiddenToast();
    }
  }, []);

  const hiddenToast = () => {
    timeOut = setTimeout(() => {
      setToast((prevState) => ({ ...prevState, show: false }));
    }, 8000);
  };

  const willUnMount = () => {
    clearTimeout(timeOut);
  };

  useEffect(() => {
    onHandleGetInfosurvey();
    return willUnMount;
  }, []);

  return state.processContinue ? (
    <CContainer fluid className="p-5 bg-gradient-secondary">
      <CRow className="text-center">
        <CCol xs="12">
          <div className="bg-imagen" />
          <Spinner />
          <Label className="h2 font-bold mb-4 text-purple" text="Encuesta de Calidad de Atención" />
        </CCol>
        <CCol xs="12">
          <Label
            className="h4 text-justify font-bold my-4"
            text="Al Programa de Atención Integral a Sobrevivientes de Violencias le interesa conocer tu opinión acerca de los servicios que te hemos brindado. Con esta información podremos mejorar la atención que tú y todas las mujeres merecen. Siéntete en la libertad de compartirnos tu experiencia, de antemano gracias por tu honestidad."
          />
          <Label
            className="h5 text-justify text-danger"
            text="Nota: Tus datos se mantienen de manera confidencial y anónima, no se solicitará información personal que atente en
            contra de la integridad de ninguna persona."
          />
        </CCol>
      </CRow>
      <CRow className="justify-content-center my-3">
        <CCol xs="12">
          <Label text="1. ¿Qué opina de la atención que recibió en Visitación Padilla?" className="mt-3 h4 font-medium" />
          <TextArea
            height={150}
            value={state.answer1}
            placeholder="Describa..."
            onChange={(e) => onChangeInputs(e, "answer1", "text-area")}
            maxLength={1500}
            size="lg"
          />
        </CCol>
      </CRow>
      <CRow className="justify-content-center my-3">
        <CCol xs="12">
          <Label text="2. ¿Cómo calificaría esta atención?" className="mt-3 h4 font-medium" isRequeried />
          <CRow>
            {state.usersAccess.map((item) => (
              <CCol key={`ev1_${item.value}`} xs="12" sm="6" lg="4" className="my-3 text-center">
                <Imagen src={item.image} width={80} height={80} className="rounded-circle" />
                <p className="h5 font-italic ml-2">{item.label}</p>
                <Dropdown
                  data={options2}
                  className="mx-4"
                  placeholder={state.evaluations[item.value][0] !== -1 ? state.evaluations[item.value][0].label : "Seleccione..."}
                  color={state.evaluations[item.value][0] !== -1 ? "purple" : "secondary"}
                  onClick={(e) => onChangeInputs(e, item.value, "dropdown", 0)}
                />
              </CCol>
            ))}
          </CRow>
        </CCol>
      </CRow>
      <CRow className="justify-content-center my-3">
        <CCol xs="12">
          <Label text="3. ¿Ha sido tratada de forma cálida y respetuosa?" className="mt-3 h4 font-medium" isRequeried />
          <CRow>
            {state.usersAccess.map((item) => (
              <CCol key={`ev2_${item.value}`} xs="12" sm="6" lg="4" className="my-3 text-center">
                <Imagen src={item.image} width={80} height={80} className="rounded-circle" />
                <p className="h5 font-italic ml-2">{item.label}</p>
                <Dropdown
                  data={options2}
                  className="mx-4"
                  placeholder={state.evaluations[item.value][1] !== -1 ? state.evaluations[item.value][1].label : "Seleccione..."}
                  color={state.evaluations[item.value][1] !== -1 ? "purple" : "secondary"}
                  onClick={(e) => onChangeInputs(e, item.value, "dropdown", 1)}
                />
              </CCol>
            ))}
          </CRow>
        </CCol>
      </CRow>
      <CRow className="justify-content-center my-3">
        <CCol xs="12">
          <Label text="4. ¿Se le informó del proceso que iba a requerir su caso?" className="mt-3 h4 font-medium" isRequeried />
          <CRow>
            {state.usersAccess.map((item) => (
              <CCol key={`ev2_${item.value}`} xs="12" sm="6" lg="4" className="my-3 text-center">
                <Imagen src={item.image} width={80} height={80} className="rounded-circle" />
                <p className="h5 font-italic ml-2">{item.label}</p>
                <Dropdown
                  data={options2}
                  className="mx-4"
                  placeholder={state.evaluations[item.value][2] !== -1 ? state.evaluations[item.value][2].label : "Seleccione..."}
                  color={state.evaluations[item.value][2] !== -1 ? "purple" : "secondary"}
                  onClick={(e) => onChangeInputs(e, item.value, "dropdown", 2)}
                />
              </CCol>
            ))}
          </CRow>
        </CCol>
      </CRow>
      <CRow className="justify-content-center my-3">
        <CCol xs="12">
          <Label text="5. ¿Hubo respuesta adecuada y oportuna a sus consultas y dudas?" className="mt-3 h4 font-medium" isRequeried />
          <CRow>
            {state.usersAccess.map((item) => (
              <CCol key={`ev3_${item.value}`} xs="12" sm="6" lg="4" className="my-3 text-center">
                <Imagen src={item.image} width={80} height={80} className="rounded-circle" />
                <p className="h5 font-italic ml-2">{item.label}</p>
                <Dropdown
                  data={options2}
                  className="mx-4"
                  placeholder={state.evaluations[item.value][3] !== -1 ? state.evaluations[item.value][3].label : "Seleccione..."}
                  color={state.evaluations[item.value][3] !== -1 ? "purple" : "secondary"}
                  onClick={(e) => onChangeInputs(e, item.value, "dropdown", 3)}
                />
              </CCol>
            ))}
          </CRow>
        </CCol>
      </CRow>
      <CRow className="justify-content-center my-3">
        <CCol xs="12">
          <Label text="6. ¿Tiene algún comentario, queja o sugerencia que informarnos?" className="mt-3 h4 font-medium" />
          <TextArea
            height={150}
            value={state.answer6}
            placeholder="Describa..."
            onChange={(e) => onChangeInputs(e, "answer6", "text-area")}
            maxLength={1500}
            size="lg"
          />
        </CCol>
      </CRow>
      <CRow className="justify-content-center my-3">
        <CCol xs="12">
          <Label text="7. ¿Recomendaría estos servicios a otras mujeres? ¿Por qué?" className="mt-3 h5 font-medium" />
          <RadioButton
            name="Recommendations"
            options={options1}
            optionSelected={options1[state.recommendations.value]}
            onChange={(e) => onChangeInputs(e, "recommendations", "radio-button")}
            classNameGroup="ml-4 h5"
          />
          <TextArea
            height={150}
            value={state.answer7}
            placeholder="¿Por qué?"
            onChange={(e) => onChangeInputs(e, "answer7", "text-area")}
            maxLength={1500}
            size="lg"
          />
        </CCol>
      </CRow>
      <CRow className="justify-content-center my-3">
        <CCol xs="12" className="text-center">
          <Button text="ENVIAR ENCUESTA" color="success" size="lg" className="w-75" />
        </CCol>
      </CRow>
      <Toast show={toast.show} title={toast.title} textContent={toast.message} className={toast.className} autohide={7000} />
    </CContainer>
  ) : (
    <Toast show={toast.show} title={toast.title} textContent={toast.message} className={toast.className} autohide={7000} />
  );
};

export default Survey;
