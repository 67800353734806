import React, { useState, useRef, useEffect, useCallback } from "react";
import { CContainer, CCard, CCardBody, CCardTitle, CCol, CRow, CCardSubtitle } from "@coreui/react";
import { Button, Toast } from "../../atoms";
import { ModalRegister, TableRegister, FemicideForm, ModalDigitizer, ModalResetToken } from "../../molecules";
import { useSelector } from "react-redux";
// Static
import { fieldsVictims } from "../../../commons/static/tables";
import styled from "styled-components";
import { validationsFemicidesForm, verifyTokenExpired } from "../../../commons/helpers/validations";
// Services
import { getRegions } from "../../../commons/services/variables";
import {
  registerFemicide,
  getAllListFemicides,
  getOccupations,
  getFemicide,
  getViolences,
  updateFemicide,
  deleteFemicide,
} from "../../../commons/services/femicides";
import { getListGroup, getCheckBoxes, getBadge } from "../../../commons/helpers/utils";

let clearForm = false,
  femicideToDelete = null;

const Femicides = () => {
  let timeOut = useRef(null);
  const stateGlobal = useSelector((state) => ({
    idToken: state.currentUser.idToken,
    violenceTypes: state.violenceTypes,
    filterCases: state.filterCases,
  }));
  const { idToken, filterCases } = stateGlobal;
  const [modal, setModal] = useState({
    registerOrUpdate: false,
    delete: false,
    token: false,
    spinner: false,
  });

  const [state, setState] = useState({
    data: [],
    itemIndex: -1,
    spinner: true,
    spinnerRegion: false,
    regions: [],
    paginationTotal: 0,
    loadingSelectInput: false,
    filters: filterCases,
  });

  const [toast, setToast] = useState({
    show: false,
    title: "",
    className: "",
    message: "",
  });

  const [paraments, setParaments] = useState({
    firstname: "",
    secondname: "",
    lastname: "",
    secondlastname: "",
    age: 0,
    occupation: { value: -1, label: "" },
    sexualOrientationId: { value: -1, label: "Seleccione" },
    maritalStatusId: { value: -1, label: "Seleccione" },
    ethnicityId: { value: -1, label: "Seleccione" },
    pregnancy: { value: -1, label: "undefined" },
    // Medio
    medioId: { selected: "Seleccione", items: [] },
    woundsNumber: 0,
    weaponId: { value: -1, label: "Seleccione" },
    causeId: { value: -1, label: "Seleccione" },
    aggressorsNumber: 1,
    aggressorTypeId: [],
    violenceTypes: {
      selected: "Seleccione",
      items: [],
      itemsSelected: [],
      loading: false,
    },
    // Place
    headline: "",
    resumen: "",
    date: "",
    time: "",
    stateId: { value: -1, label: "Seleccione" },
    regionId: { value: -1, label: "Seleccione" },
    place: "",
    zone: { value: -1, label: "undefined" },
  });

  const onChangeInputs = (event, field, arg) => {
    const { target } = event ? event : {};
    switch (arg) {
      case "input":
        setParaments((prevState) => ({ ...prevState, [field]: target.value }));
        break;
      case "number":
        setParaments((prevState) => ({
          ...prevState,
          [field]: target.value ? parseInt(target.value) : target.value,
        }));
        break;
      case "dropdown":
        setParaments((prevState) => ({
          ...prevState,
          [field]: { value: target.getAttribute("value"), label: target.text },
        }));
        if (field === "stateId") {
          setState((prevState) => ({ ...prevState, spinnerRegion: true }));
          setParaments((prevState) => ({
            ...prevState,
            regionId: { value: -1, label: "Seleccione" },
          }));
          onHandleGetRegions(target.getAttribute("value"));
        }
        break;
      case "list-group":
        const array = paraments[field]["items"];
        if (!array.find((item) => item.value === target.getAttribute("value"))) {
          setParaments((prevState) => ({
            ...prevState,
            [field]: {
              selected: target.text,
              items: getListGroup(array, target),
            },
          }));
        }
        break;
      case "badge":
        const itemsSelected = getBadge(paraments[field]["itemsSelected"], target);
        setParaments((prevState) => ({
          ...prevState,
          [field]: {
            ...paraments[field],
            itemsSelected,
          },
        }));
        break;
      case "select-input":
        setParaments((prevState) => ({
          ...prevState,
          [field]: {
            value: event ? event.value : -1,
            label: event ? event.label : "",
          },
        }));
        break;
      case "tableFilterValue":
        if (field === "name") {
          setState((prevState) => ({
            ...prevState,
            filters: { ...state.filters, tableFilterValue: { ...state.filters.tableFilterValue, [field]: event } },
          }));
        } else if (field === "fields-clear") {
          setState((prevState) => ({
            ...prevState,
            filters: { ...state.filters, tableFilterValue: { name: "", startDate: "", endDate: "" } },
            spinner: true,
          }));
          onHandleGetAllListFemicides(state.filters.limit, 1, {
            name: "",
            startDate: "",
            endDate: "",
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            filters: {
              ...state.filters,
              tableFilterValue: {
                ...state.filters.tableFilterValue,
                [field]: target.value,
              },
            },
          }));
        }
        break;
      case "check-box-multiple":
        if (paraments[field]["selected"] !== target.text) {
          setParaments(
            (prevState) => ({
              ...prevState,
              [field]: {
                ...paraments[field],
                loading: true,
              },
            }),
            onHandleGetViolences(target, field)
          );
        }
        break;
      case "check-boxes":
        if (field === "violenceTypes") {
          setParaments((prevState) => ({
            ...prevState,
            violenceTypes: {
              ...paraments.violenceTypes,
              [field]: getCheckBoxes(paraments[field]["itemsSelected"], target),
            },
          }));
        } else {
          setParaments((prevState) => ({
            ...prevState,
            [field]: getCheckBoxes(paraments[field], target),
          }));
        }
        break;
      default:
        setParaments((prevState) => ({
          ...prevState,
          [field]: {
            value: parseInt(target.getAttribute("value")),
            label: target.getAttribute("id"),
          },
        }));
        break;
    }
  };

  const onInputSelectChange = (text) => {
    if (text) {
      setState((prevState) => ({ ...prevState, loadingSelectInput: true }));
      return text;
    }
  };

  const loadOptionsSelectInput = useCallback(
    async (inputValue) => {
      try {
        const { success, result, title, message, code } = await getOccupations({
          idToken,
          occupation: inputValue,
        });
        if (success) {
          return result;
        } else {
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, registerOrUpdate: false, delete: false, token: true }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              className: "text-white bg-danger",
              message,
            }));
          }
        }
      } catch (error) {
        setToast({
          show: true,
          title: "Error in getOccupations",
          message: error.message,
          className: "text-white bg-danger",
        });
      } finally {
        hiddenToast();
        setState((prevState) => ({
          ...prevState,
          loadingSelectInput: false,
        }));
      }
    },
    [idToken]
  );

  const validationFemicidesForm = (id) => {
    const verify = validationsFemicidesForm(paraments);
    if (verify.valid) {
      setState((prevState) => ({ ...prevState, spinner: true }));
      if (!id) {
        onHandleRegisterFemicide();
      } else {
        onHandleUpdateFemicide();
      }
    } else {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title: "Campos",
        className: "bg-danger text-white",
        message: verify.message,
      }));
    }
    hiddenToast();
  };

  const onHandleGetRegions = useCallback(
    async (stateId) => {
      try {
        const response = await getRegions({
          idState: stateId,
          idToken,
        });
        if (verifyTokenExpired(response.code)) {
          setModal((prevState) => ({ ...prevState, registerOrUpdate: false, delete: false, token: true }));
        } else if (!response.success) {
          setToast((prevState) => ({
            ...prevState,
            show: true,
            message: response.message,
            className: "text-white bg-danger",
            title: "Error in getRegions",
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            regions: response.regions,
          }));
        }
      } catch (error) {
        setToast((prevState) => ({
          ...prevState,
          show: true,
          title: "ERROR",
          message: error.message,
          className: "text-white bg-danger",
        }));
      } finally {
        setState((prevState) => ({ ...prevState, spinnerRegion: false }));
      }
    },
    [idToken]
  );

  const onHandleGetAllListFemicides = useCallback(
    async (limit, pagination, tableFilterValue) => {
      try {
        const { success, femicidesList, message, title, paginationTotal, code } = await getAllListFemicides({
          idToken,
          limit,
          pagination,
          tableFilterValue,
        });
        if (success) {
          setState((prevState) => ({
            ...prevState,
            data: femicidesList,
            filters: { ...state.filters, limit, pagination, tableFilterValue },
            paginationTotal,
          }));
        } else {
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, registerOrUpdate: false, delete: false, token: true }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              className: "text-white bg-danger",
              message,
            }));
          }
        }
      } catch (error) {
        setToast((prevState) => ({
          ...prevState,
          show: true,
          title: "ERROR",
          className: "text-white bg-danger",
          textContent: error.message,
        }));
      } finally {
        hiddenToast();
        setState((prevState) => ({ ...prevState, spinner: false }));
      }
    },
    [idToken]
  );

  const onHandleGetViolences = useCallback(
    async (target, field) => {
      try {
        const { success, violences, message, title, code } = await getViolences({
          id: target.getAttribute("value"),
          idToken,
        });
        if (success) {
          setParaments((prevState) => ({
            ...prevState,
            [field]: {
              ...paraments[field],
              selected: target.text,
              items: violences,
              loading: false,
            },
          }));
        } else {
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, registerOrUpdate: false, delete: false, token: true }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              className: "text-white bg-danger",
              message,
            }));
          }
        }
      } catch (error) {
        setToast((prevState) => ({
          ...prevState,
          show: true,
          title: "Error en registerFemicide",
          className: "text-white bg-danger",
          message: error.message,
        }));
      } finally {
        hiddenToast();
      }
    },
    [paraments.violenceTypes, idToken]
  );

  const onHandleRegisterFemicide = async () => {
    try {
      const { filters } = state;
      const { tableFilterValue, pagination, limit } = filters;
      const { success, title, message, code } = await registerFemicide({
        paraments,
        idToken,
      });
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title,
        message,
        className: success ? "text-white bg-success" : "text-white bg-danger",
      }));
      if (success) {
        clearParameter();
        toggleModal();
        await onHandleGetAllListFemicides(limit, pagination, tableFilterValue);
      } else {
        if (verifyTokenExpired(code)) {
          setModal((prevState) => ({ ...prevState, registerOrUpdate: false, delete: false, token: true }));
        } else {
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title,
            className: "text-white bg-danger",
            message,
          }));
        }
      }
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title: "Error en registerFemicide",
        className: "text-white bg-danger",
        message: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, spinner: false }));
      hiddenToast();
    }
  };

  const onHandleUpdateFemicide = async () => {
    const { filters } = state;
    const { tableFilterValue, pagination, limit } = filters;
    try {
      const { success, message, title, code } = await updateFemicide({
        idToken,
        paraments,
      });
      setToast((prevState) => ({
        ...prevState,
        show: true,
        message,
        title,
        className: success ? "text-white bg-success" : "bg-danger text-white",
      }));
      if (success) {
        toggleModal();
        onHandleGetAllListFemicides(limit, pagination, tableFilterValue);
      } else {
        if (verifyTokenExpired(code)) {
          setModal((prevState) => ({ ...prevState, registerOrUpdate: false, delete: false, token: true }));
        } else {
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title,
            className: "text-white bg-danger",
            message,
          }));
        }
      }
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title: "Error en registerFemicide",
        className: "text-white bg-danger",
        message: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, spinner: false }));
      hiddenToast();
    }
  };

  const onHandleGetFemicide = async (id) => {
    try {
      const { success, message, title, femicide, code } = await getFemicide({
        idToken,
        id,
      });
      if (success) {
        setParaments((prevState) => ({
          ...prevState,
          ...femicide,
          violenceTypes: {
            ...paraments.violenceTypes,
            itemsSelected: femicide.violenceTypes,
          },
          id,
        }));
      } else {
        if (verifyTokenExpired(code)) {
          setModal((prevState) => ({ ...prevState, registerOrUpdate: false, delete: false, token: true }));
        } else {
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title,
            className: "text-white bg-danger",
            message,
          }));
        }
      }
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title: "Error en getFemicide",
        className: "text-white bg-danger",
        message: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, spinner: false }));
      hiddenToast();
    }
  };

  const deleteItemListGroup = (event, field) => {
    const { target } = event;
    const temp = paraments[field]["items"].filter((item) => {
      if (item.value === target.getAttribute("value")) {
        return false;
      } else {
        return true;
      }
    });
    setParaments((prevState) => ({
      ...prevState,
      [field]: { selected: "Seleccione", items: temp.length > 0 ? temp : [] },
    }));
  };

  const onPaginationChange = (limit) => {
    const { filters } = state;
    const { tableFilterValue } = filters;
    setState((prevState) => ({ ...prevState, spinner: true }), onHandleGetAllListFemicides(limit, 1, tableFilterValue));
  };

  const onActivePageChange = (page) => {
    const { filters } = state;
    const { tableFilterValue, limit } = filters;
    setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));
    onHandleGetAllListFemicides(limit, page, tableFilterValue);
  };

  const onReadySearches = () => {
    const { filters } = state;
    const { tableFilterValue, limit } = filters;
    setState((prevState) => ({ ...prevState, spinner: true }));
    onHandleGetAllListFemicides(limit, 1, tableFilterValue);
  };

  const onHandleDeleteFemicide = async () => {
    setState((prevState) => ({ ...prevState, spinner: true }));
    try {
      const { filters } = state;
      const { pagination, tableFilterValue, limit } = filters;
      const id = femicideToDelete.id;
      const { success, message, title, code } = await deleteFemicide({ id, idToken });
      setToast((prevState) => ({
        ...prevState,
        show: true,
        message,
        title,
        className: success ? "text-white bg-success" : "text-white bg-danger",
      }));
      if (success) {
        setToast(
          (prevState) => ({
            ...prevState,
            show: true,
            title,
            className: "text-white bg-success",
            message,
          }),
          toggleModalDelete()
        );
        onHandleGetAllListFemicides(limit, pagination, tableFilterValue);
      } else {
        if (verifyTokenExpired(code)) {
          setModal((prevState) => ({ ...prevState, registerOrUpdate: false, delete: false, token: true }));
        } else {
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title,
            className: "text-white bg-danger",
            message,
          }));
        }
      }
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        message: error.message,
        title: "ERROR in deleteFemicide",
        className: "text-white bg-danger",
      }));
    }
  };

  const hiddenToast = () => {
    timeOut = setTimeout(() => {
      setToast((prevState) => ({ ...prevState, show: false }));
    }, 10000);
  };

  const clearParameter = () => {
    delete paraments.id;
    setParaments((prevState) => ({
      ...prevState,
      firstname: "",
      secondname: "",
      lastname: "",
      secondlastname: "",
      age: 0,
      occupation: { value: -1, label: "" },
      sexualOrientationId: { value: -1, label: "Seleccione" },
      maritalStatusId: { value: -1, label: "Seleccione" },
      ethnicityId: { value: -1, label: "Seleccione" },
      pregnancy: { value: -1, label: "undefined" },
      // Medio
      medioId: { selected: "Seleccione", items: [] },
      woundsNumber: 0,
      weaponId: { value: -1, label: "Seleccione" },
      causeId: { value: -1, label: "Seleccione" },
      aggressorsNumber: 0,
      aggressorTypeId: [],
      violenceTypes: { selected: "Seleccione", items: [], itemsSelected: [] },
      // Place
      headline: "",
      resumen: "",
      date: "",
      time: "",
      stateId: { value: -1, label: "Seleccione" },
      regionId: { value: -1, label: "Seleccione" },
      place: "",
      zone: { value: -1, label: "undefined" },
    }));
  };

  const toggleModal = (item) => {
    if (clearForm) {
      clearParameter();
      clearForm = false;
    }
    if (item !== undefined) {
      if (item.id) {
        setState((prevState) => ({ ...prevState, spinner: true }), onHandleGetFemicide(item.id));
        clearForm = true;
      }
    }
    setModal((prevState) => ({
      ...prevState,
      registerOrUpdate: !modal.registerOrUpdate,
    }));
  };

  const toggleModalToken = () => {
    setModal((prevState) => ({ ...prevState, token: !modal.token }));
  };

  const toggleModalDelete = (event, index) => {
    if (index > -1) {
      femicideToDelete = state.data[index];
    }
    setModal((prevState) => ({ ...prevState, delete: !modal.delete }));
  };

  const willUnMount = () => {
    clearTimeout(timeOut);
  };

  useEffect(() => {
    if (idToken) {
      onHandleGetAllListFemicides(state.filters.limit, state.filters.pagination, state.filters.tableFilterValue);
    }
    return willUnMount;
  }, [idToken]);

  return (
    <CContainer fluid>
      <StyledCard>
        <CRow className="pt-3 px-3 justify-content-between align-items-center">
          <CCol>
            <CCardTitle className="h2">Femicidios</CCardTitle>
          </CCol>
          <CCol className="text-right">
            <Button
              text="AGREGAR NUEVO"
              //className="mr-2"
              color="success"
              onClick={toggleModal}
            />
          </CCol>
        </CRow>
        <CRow className="px-3 pt-3">
          <CCol>
            <CCardSubtitle className="h5">
              La modalidad de los femicidios es un registro de todos los casos por muertes violentas en contra de las mujeres o niñas, cuya
              información es recopilada de los medios de comunicación registrados en el SAISV.
            </CCardSubtitle>
          </CCol>
        </CRow>
        <CCardBody>
          <TableRegister
            elements={state}
            fields={fieldsVictims(false)}
            toggleModal={toggleModal}
            toggleModalDelete={toggleModalDelete}
            onPaginationChange={onPaginationChange}
            onTableFilterChange={onChangeInputs}
            onActivePageChange={onActivePageChange}
            onReadySearches={onReadySearches}
          />
        </CCardBody>
      </StyledCard>
      <ModalRegister
        show={modal.registerOrUpdate}
        title="AGREGAR O EDITAR FEMICIDIO"
        toggleModal={toggleModal}
        content={
          <FemicideForm
            paraments={paraments}
            states={state}
            onChangeInputs={onChangeInputs}
            deleteItem={deleteItemListGroup}
            onInputSelectChange={onInputSelectChange}
            loadOptionsSelectInput={loadOptionsSelectInput}
          />
        }
        sizeModal="lg"
        classModalHeader="h3"
        textButtonOnConfirm={paraments.id ? "Actualizar" : "Guardar"}
        onConfirm={() => validationFemicidesForm(paraments.id)}
      />
      <ModalDigitizer
        show={modal.delete}
        spinner={state.spinner}
        title="ELIMINAR"
        toggleModal={toggleModalDelete}
        content={
          <div>
            <p className="font-regular h5">¿Esta segura que desea eliminar el caso de femicidio permanentemente?</p>
          </div>
        }
        classModalHeader="h3"
        onConfirm={onHandleDeleteFemicide}
        textBtnLeft="NO"
        textBtnRight="SI"
      />
      <Toast show={toast.show} title={toast.title} textContent={toast.message} className={toast.className} autohide={9000} />
      <ModalResetToken show={modal.token} spinner={modal.spinner} setModal={setModal} toggleModal={toggleModalToken} />
    </CContainer>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default Femicides;
