import React from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import { Imagen, Icon } from "../../atoms";
import { useHistory } from "react-router-dom";
import { urlsAdmin } from "../../../commons/consts/urls";

const CardUserFemale = ({ firstname, lastname, photoURL, id_rol, id_charge, id, evaluation, casesTotal }) => {
  const history = useHistory();

  const getEvaluationStart = (value) => {
    let iconStart = [];
    for (let index = 1; index <= Math.trunc(value); index++) {
      iconStart.push(index);
    }
    return iconStart;
  };

  return (
    <CCard type="button" className="btn btn-primary" onClick={() => history.push(urlsAdmin.evaluations + "/" + id)}>
      <h4 className="text-center border-0 rounded-0 pt-2">
        {firstname}
        <br />
        {lastname}
      </h4>
      <CCardBody className="text-center px-2 m-0">
        <CRow>
          <CCol xs="12">
            <Imagen src={photoURL} className="rounded-circle" fluidGrow />
          </CCol>
          <CCol xs="12" className="text-left mt-3">
            <p>
              <b>Cargo:</b> {id_charge.label}
            </p>
            <p>
              <b>Rol:</b> {id_rol.label}
            </p>
            <p>
              <b>Total de casos:</b> {casesTotal}
            </p>
          </CCol>
        </CRow>
        <CRow className="justify-content-end p-0 m-0">
          <div className="mx-3">
            <CRow className="justify-content-center bg-white rounded-lg pt-2 px-3">
              {getEvaluationStart(evaluation).map((number) => (
                <Icon key={id + number} name="star" color="#5e039b" size="lg" />
              ))}
              {0.0 < evaluation - Math.trunc(evaluation) && evaluation - Math.trunc(evaluation) < 0.99 ? (
                <Icon name="starHalf" color="#5e039b" size="lg" />
              ) : null}
              <small className="h6 font-bold text-dark">{evaluation}/3</small>
            </CRow>
          </div>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default CardUserFemale;
