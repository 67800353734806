import React, { useContext } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { Button } from "..";

const ChervonLeft = () => {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);
  return (
    <div className="row align-items-center">
      <div className="col">
        <Button
          icon="chervonLeft"
          color="info"
          className="rounded h-25"
          size="1x"
          disabled={isFirstItemVisible}
          onClick={() => scrollPrev()}
        />
      </div>
    </div>
  );
};

export default ChervonLeft;
