import React, { useState } from "react";
import { CTabContent, CTabPane, CTabs, CNav, CNavItem, CNavLink } from "@coreui/react";
import { variantItemNotification } from "../../../commons/consts/animations";
import DivTransition from "../../../commons/animations/DivTransition";
import { useSelector } from "react-redux";
import { WidgetProgress } from "..";

const notifications = [
  {
    id: "not-1",
    title: "Lorena Salgado",
    type: "success",
    text: "Finalización",
    date: "Martes 21/ENE/2018 02:00",
    progressValue: 100,
  },
  {
    id: "not-2",
    title: "Martha Carrasco",
    type: "danger",
    text: "Recordatorio de plan terapéutico",
    date: "Viernes 22/ENE/2018 16:00",
    progressValue: 25,
  },
  {
    id: "not-3",
    title: "Esther Solorzano",
    type: "warning",
    text: "Caso abandonado",
    date: "Jueves 18/ENE/2018 08:10",
    progressValue: 75,
  },
  {
    id: "not-4",
    title: "Cristina Aguilera",
    type: "info",
    text: "Caso cerrado",
    date: "Lunes 13/FEB/2018 14:00",
    progressValue: 50,
  },
  {
    id: "not-5",
    title: "Cristina Aguilera",
    type: "info",
    text: "Caso cerrado",
    date: "Lunes 13/FEB/2018 14:00",
    progressValue: 50,
  },
];

const NotificationsTabs = () => {
  const theme = useSelector((state) => state.theme);
  const [tabCurrent, setTabCurrent] = useState("oneMonth");
  const onActiveTabChange = (tabName) => {
    setTabCurrent(tabName);
  };

  return (
    <CTabs activeTab="oneMonth" onActiveTabChange={onActiveTabChange}>
      <CNav variant="tabs" fill={true} className="px-3">
        <CNavItem>
          <CNavLink data-tab="oneMonth" disabled={tabCurrent === "oneMonth" ? true : false} className={`${theme === "dark" && "text-white bg-dark"}`}>
            Hace 1 mes
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink data-tab="before" disabled={tabCurrent === "before" ? true : false} className={`${theme === "dark" && "text-white bg-dark"}`}>
            Anteriores
          </CNavLink>
        </CNavItem>
      </CNav>
      <CTabContent>
        <CTabPane data-tab="oneMonth" className="main-notifications-container-scroll">
          {tabCurrent === "oneMonth" &&
            notifications.map(({ id, text, type, progressValue, date, title }, index) => (
              <DivTransition key={id} variants={variantItemNotification} initial="hidden" animate="visible" whileHover="hover" custom={index}>
                <WidgetProgress
                  className="h6"
                  title={title}
                  type={type}
                  text={text}
                  footer={date}
                  value={progressValue}
                  onClick={() => alert("Hello, this is a notification.")}
                />
              </DivTransition>
            ))}
        </CTabPane>
        <CTabPane data-tab="before" className="main-notifications-container-scroll">
          {tabCurrent === "before" &&
            notifications.map(({ id, text, type, progressValue, date, title }, index) => (
              <DivTransition key={id} variants={variantItemNotification} initial="hidden" animate="visible" whileHover="hover" custom={index}>
                <WidgetProgress
                  className="h6"
                  title={title}
                  type={type}
                  text={text}
                  footer={date}
                  value={progressValue}
                  onClick={() => alert("Hello, this is a notification.")}
                />
              </DivTransition>
            ))}
        </CTabPane>
      </CTabContent>
    </CTabs>
  );
};

export default NotificationsTabs;
