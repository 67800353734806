import React from "react";
import { Signature, Button } from "../../atoms";
import { CRow, CCol } from "@coreui/react";

const ContainerSignature = ({
  signatureState,
  onClickBtnBack,
  onClickBtnClear,
  onClickBtnSave,
  onEnd,
  canvasProps,
}) => {
  return (
    <CCol className="mt-3">
      <p className="text-danger">
        Escriba sobre el lienzo para crear una firma digital, debajo encontrará
        las acciones a realizar por si desea guardar o rectificar su firma.
      </p>
      <Signature
        state={signatureState}
        onEnd={onEnd}
        penColor="black"
        canvasProps={canvasProps}
      />
      <CRow className="justify-content-end">
        <Button
          text="Limpiar"
          color="secondary"
          onClick={onClickBtnClear}
          disabled={signatureState.isEmpty}
          className="mr-1"
        />
        {onClickBtnBack && (
          <Button text="Regresar" color="warning" onClick={onClickBtnBack} />
        )}
        <Button
          text="Guardar"
          color="primary"
          onClick={onClickBtnSave}
          disabled={signatureState.isEmpty}
          className="ml-1"
        />
      </CRow>
    </CCol>
  );
};

export default ContainerSignature;
