import React from "react";
import { CDataTable, CRow, CSpinner, CPagination, CCol } from "@coreui/react";
import { Switch, Button, Imagen } from "../../atoms";
import { DateTimePicker } from "../../molecules";
import { useSelector } from "react-redux";
// Resources
import imgClosed from "../../../assets/closed.svg";
import imgOpen from "../../../assets/open.svg";
import imgAbandoned from "../../../assets/abandoned.svg";

const TableRegister = ({
  elements,
  fields,
  disableDelete,
  onTableFilterChange,
  onChangedEnabledDisabled,
  onPaginationChange,
  onActivePageChange,
  toggleModal,
  toggleModalDelete,
  switchEnable,
  onReadySearches,
}) => {
  const { itemIndex, spinnerEnabled, spinner, data, paginationTotal, filters } = elements;
  const { tableFilterValue, pagination } = filters;

  const stateGlobal = useSelector((state) => ({
    theme: state.theme === "light" ? false : true,
    itemsPerPage: state.filterCases.limit,
  }));

  const getImageStatusCase = (item) => {
    switch (item.statusCase) {
      case 0:
        return (
          <td>
            <Imagen src={imgClosed} width={60} />
          </td>
        );
      case 1:
        return (
          <td>
            <Imagen src={imgOpen} width={60} />
          </td>
        );
      default:
        return (
          <td>
            <Imagen src={imgAbandoned} width={60} />
          </td>
        );
    }
  };

  const onHandleOptions = (item, index) => {
    return (
      <td className="d d-flex flex-row justify-content-center align-items-center">
        <Button icon="pencil" onClick={() => toggleModal(item, index)} className="mr-2" color="info" variant="outline" />
        {switchEnable ? (
          <>
            {spinnerEnabled && itemIndex === index ? (
              <CSpinner />
            ) : (
              <Switch item={item} checked={item.enabled} index={index} onChange={() => onChangedEnabledDisabled(item, index)} />
            )}
          </>
        ) : (
          !disableDelete && <Button icon="trash" onClick={() => toggleModalDelete(item, index)} color="danger" />
        )}
      </td>
    );
  };

  return (
    <div>
      <CDataTable
        items={data}
        fields={fields}
        loading={spinner}
        tableFilterValue={tableFilterValue && tableFilterValue.name}
        tableFilter={
          onReadySearches
            ? {
                label: "Filtros: ",
                placeholder: "TODOS",
                external: true,
                lazy: true,
              }
            : false
        }
        noItemsView={{
          noResults: "Registros no encontrados...",
          noItems: "Data vacia...",
        }}
        hover
        sorter={{ resetable: true }}
        striped
        responsive
        // Por defeto sera 20, para pruebas sera de 10 items.
        itemsPerPage={stateGlobal.itemsPerPage}
        itemsPerPageSelect={
          onPaginationChange
            ? {
                label: "Elementos por página: ",
                values: [10, 30, 60, 100, 150],
              }
            : false
        }
        dark={stateGlobal.theme}
        onPaginationChange={onPaginationChange}
        onTableFilterChange={(value) => {
          if (tableFilterValue) {
            onTableFilterChange(value, "name", "tableFilterValue");
          }
        }}
        overTableSlot={
          onReadySearches && (
            <CRow className="px-3 mb-2">
              <CCol xs="12" md="6">
                <DateTimePicker
                  disableTime
                  disableTime2
                  label="Desde: "
                  valueDate={tableFilterValue.startDate}
                  onChangeDate={(event) => onTableFilterChange(event, "startDate", "tableFilterValue")}
                />
                <DateTimePicker
                  disableTime
                  disableTime2
                  label="Hasta: "
                  className="my-2"
                  valueDate={tableFilterValue.endDate}
                  onChangeDate={(event) => onTableFilterChange(event, "endDate", "tableFilterValue")}
                />
              </CCol>
              <CCol xs="12" md="6">
                <div>
                  <Button
                    icon="brushAlt"
                    className="w-50 mb-2"
                    text="Limpiar filtros"
                    color="dark"
                    onClick={(e) => onTableFilterChange(e, "fields-clear", "tableFilterValue")}
                    disabled={!(tableFilterValue.name || tableFilterValue.startDate || tableFilterValue.endDate)}
                  />
                </div>
                <div>
                  <Button icon="search" className="w-50" text="Buscar" color="info" onClick={() => onReadySearches(tableFilterValue)} />
                </div>
              </CCol>
            </CRow>
          )
        }
        scopedSlots={{
          statusCase: getImageStatusCase,
          index: (item) => {
            return (
              <td>
                <p>{item.index + 1}</p>
              </td>
            );
          },
          username: (item) => {
            return (
              <td>
                <CRow className="align-items-center">
                  <Imagen src={item.photoURL} width={40} height={40} className="rounded-circle mr-2" />
                  {item.username}
                </CRow>
              </td>
            );
          },
          options: onHandleOptions,
        }}
      />
      {paginationTotal > 1 && (
        <CPagination activePage={pagination} pages={paginationTotal} onActivePageChange={onActivePageChange} align="center" size="lg" />
      )}
    </div>
  );
};

export default TableRegister;
