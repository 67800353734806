import React from "react";
import { CRow, CCol, CCard } from "@coreui/react";
import { colorsBadged } from "../../../commons/consts/colors";
import { ChartBarCard, ChartDoughnutCard, ChartPieCard, ChartLineCard } from "../../molecules";
import { footerFontChartsText } from "../../../commons/static/tabs";
import { elementsTabReports } from "../../../commons/static/tabs";
import styled from "styled-components";

const ChartsLayout = ({ tabName, data, spinner }) => {
  const { disability, educationLevel, ethnicities, maritalStatus } = data;
  return (
    <>
      {tabName === elementsTabReports[0].name && (
        <StyledCard className="p-4 border-0">
          <CRow>
            <CCol xs="12">
              <ChartBarCard
                titleLitte="GRÁFICO 1"
                title="ORIGEN ÉTNICO DE LAS MUJERES ATENDIDAS"
                dataPoints={ethnicities && ethnicities.dataPoints}
                label="ÉTNIAS"
                backgroundColor={ethnicities ? colorsBadged(4) : colorsBadged(10)}
                labels={ethnicities && ethnicities.labels}
                nTotal={ethnicities && ethnicities.total}
                footerText={footerFontChartsText}
                loading={spinner}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12" md="6">
              <ChartDoughnutCard
                titleLitte="GRÁFICO 2"
                title="ESTADÍSTICA DE MUJERES CON DISCAPACIDAD"
                dataPoints={disability && disability.dataPoints}
                labels={disability && disability.labels}
                nTotal={disability && disability.total}
                footerText={footerFontChartsText}
                loading={spinner}
              />
            </CCol>
            <CCol xs="12" md="6">
              <ChartPieCard
                titleLitte="GRÁFICO 3"
                title="NIVEL EDUCATIVO DE LAS MUJERES ATENDIDAS"
                dataPoints={educationLevel && educationLevel.dataPoints}
                labels={educationLevel && educationLevel.labels}
                nTotal={educationLevel && educationLevel.total}
                footerText={footerFontChartsText}
                loading={spinner}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <ChartLineCard
                titleLitte="GRÁFICO 4"
                title="ESTADO CIVIL DE LAS MUJERES ATENDIDAS"
                label="ESTADOS CIVILES"
                dataPoints={maritalStatus && maritalStatus.dataPoints}
                labels={maritalStatus && maritalStatus.labels}
                nTotal={maritalStatus && maritalStatus.total}
                footerText={footerFontChartsText}
                loading={spinner}
              />
            </CCol>
          </CRow>
        </StyledCard>
      )}
    </>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.cardBackground};
  transition: all 0.5s ease;
`;

export default ChartsLayout;
