import { URL, buildRequest } from "../../config/config";

const getChartsData = async (data) => {
  let response = { success: false, message: "", data: {} };
  try {
    const res = await fetch(URL + "getChartsData", buildRequest(data));
    response = await res.json();
  } catch (error) {
    response = {
      success: false,
      message: error.message,
      data: {},
      code: error.code,
    };
  } finally {
    return response;
  }
};

const getFemicidesDataDash = async (data) => {
  let response = { success: false, message: "", data: { labels: [], dataPoints: [], total: 0 } };
  try {
    const res = await fetch(URL + "getFemicidesDataDash", buildRequest(data));
    response = await res.json();
  } catch (error) {
    response = {
      success: false,
      message: error.message,
      data: {},
      code: error.code,
    };
  } finally {
    return response;
  }
};

const getRecordsAreaDataDash = async (data) => {
  let response = { success: false, message: "", data: { labels: [], dataPoints: [], total: 0 } };
  try {
    const res = await fetch(URL + "getRecordsAreaDataDash", buildRequest(data));
    response = await res.json();
  } catch (error) {
    response = {
      success: false,
      message: error.message,
      data: {},
      code: error.code,
    };
  } finally {
    return response;
  }
};

const getRecordsDataDash = async (data) => {
  let response = { success: false, message: "", data: { labels: [], dataPoints: [], total: 0 } };
  try {
    const res = await fetch(URL + "getRecordsDataDash", buildRequest(data));
    response = await res.json();
  } catch (error) {
    response = {
      success: false,
      message: error.message,
      data: {},
      code: error.code,
    };
  } finally {
    return response;
  }
};

export { getChartsData, getRecordsDataDash, getFemicidesDataDash, getRecordsAreaDataDash };
