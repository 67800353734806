import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import { ContainerFiles } from "..";
import { Spinner } from "../../atoms";

const MultimediaForm = ({ uid, file, controllers, onChangeInputs, deleteFile, donwloadFile }) => {
  const { spinner, spinnerButtons } = controllers;
  return (
    <CContainer fluid>
      {spinner ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <CRow>
          <CCol xs="12">
            <ContainerFiles
              uid={uid}
              title="Archivos"
              loadingButtons={spinnerButtons}
              file={file}
              placeholder="Subir archivo"
              field="filePath"
              onChange={onChangeInputs}
              deleteFile={deleteFile}
              donwloadFile={donwloadFile}
              accept="image/*, audio/*, video/*, .pdf, .text, .doc, .docx"
              filePath={file.filePath}
              data={file.data}
              dataArg="data"
              storageName="multimedias-AL"
            />
          </CCol>
        </CRow>
      )}
    </CContainer>
  );
};

export default MultimediaForm;
