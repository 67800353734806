import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faStarHalf,
  faStar,
  faEye,
  faEyeSlash,
  faFileExcel,
  faFolder,
  faFolderOpen,
  faFileAlt,
  faImages,
  faChevronLeft,
  faChevronRight,
  faMoon,
  faSun,
  faUser,
  faLock,
  faTachometerAlt,
  faFileCsv,
  faSearch,
  faPencilAlt,
  faChartBar,
  faSync,
  faSignOutAlt,
  faCog,
  faInfo,
  faTimes,
  faNewspaper,
  faCheck,
  faArrowCircleLeft,
  faTrash,
  faHome,
  faBriefcase,
  faFemale,
  faBell,
  faFingerprint,
  faHandPointLeft,
  faHandPointRight,
  faPaperPlane,
  faBrush,
  faExclamationTriangle,
  faCloudDownloadAlt,
  faPlay,
  faPause,
  faPlusSquare,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

const iconsObj = {
  user: faUser,
  locked: faLock,
  speedometer: faTachometerAlt,
  spreadsheet: faFileCsv,
  search: faSearch,
  pencil: faPencilAlt,
  penNib: faPencilAlt,
  graph: faChartBar,
  sync: faSync,
  accountLogout: faSignOutAlt,
  settings: faCog,
  info: faInfo,
  cancel: faTimes,
  newspaper: faNewspaper,
  check: faCheck,
  back: faArrowCircleLeft,
  trash: faTrash,
  home: faHome,
  briefcase: faBriefcase,
  userFemale: faFemale,
  bell: faBell,
  fingerprint: faFingerprint,
  handPointLeft: faHandPointLeft,
  handPointRight: faHandPointRight,
  send: faPaperPlane,
  brushAlt: faBrush,
  noteAdd: faPlusSquare,
  star: faStar,
  moon: faMoon,
  sun: faSun,
  starHalf: faStarHalf,
  eye: faEye,
  eyeSlash: faEyeSlash,
  fileExcel: faFileExcel,
  folder: faFolder,
  folderOpen: faFolderOpen,
  fileAlt: faFileAlt,
  images: faImages,
  chervonRight: faChevronRight,
  chervonLeft: faChevronLeft,
  warning: faExclamationTriangle,
  download: faCloudDownloadAlt,
  pause: faPause,
  play: faPlay,
  calendarTimes: faCalendarAlt,
};

const Icons = ({ name, sideMenu, size, className, color }) => {
  return <FontAwesomeIcon icon={iconsObj[name]} size={size} color={color} className={sideMenu ? "c-sidebar-nav-icon" : className} />;
};

library.add(
  faStar,
  faStarHalf,
  faEye,
  faEyeSlash,
  faFileExcel,
  faFolderOpen,
  faFolder,
  faFileAlt,
  faImages,
  faChevronRight,
  faChevronLeft,
  faSun,
  faMoon,
  faUser,
  faLock,
  faTachometerAlt,
  faFileCsv,
  faSearch,
  faPencilAlt,
  faChartBar,
  faSync,
  faSignOutAlt,
  faCog,
  faInfo,
  faTimes,
  faNewspaper,
  faCheck,
  faArrowCircleLeft,
  faTrash,
  faHome,
  faBriefcase,
  faFemale,
  faBell,
  faFingerprint,
  faHandPointLeft,
  faPaperPlane,
  faBrush,
  faPlusSquare,
  faCloudDownloadAlt,
  faPause,
  faPlay,
  faCalendarAlt
);
export default Icons;
