import Resizer from "react-image-file-resizer";

const decodeFormatBase64 = "data:image/svg;base64,";

const resolutionWebCamProfile = {
  width: 200,
  height: 160,
};

const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      640,
      480,
      "png",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
      640,
      480,
    );
  });
};

const imagesResizer = async (fileImages) => {
  let imageNew = null;
  try {
    imageNew = await resizeFile(fileImages);
  } catch (err) {
    console.log(err);
  } finally {
    return imageNew;
  }
};

export { imagesResizer, resolutionWebCamProfile, decodeFormatBase64 };
export { default as imagenBrand } from "../../assets/logo_brand.svg";
